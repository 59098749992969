import React, { useCallback, useContext, ReactNode } from "react";
import { match, Route, Switch } from 'react-router';

import MainNavigation from '../../components/mainnavigation/MainNavigation';
import { History } from 'history';
import { AlertContext } from "../../contexts/AlertContext";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import AdminDashboardNavigation from "../../components/admindashboard/AdminDashboardNavigation";
import AdminDashboardInvoices from "../../components/admindashboard/AdminDashboardInvoices";
import AdminDashboardDirectPayments from "../../components/admindashboard/AdminDashboardDirectPayments";
import AdminDashboardDirectPaymentsAllowList from "../../components/admindashboard/AdminDashboardDirectPaymentsAllowList";
import AdminDashboardFailedExecutions from "../../components/admindashboard/AdminDashboardFailedExecutions";
import AdminDashboardStuckExecutions from "../../components/admindashboard/AdminDashboardStuckExecutions";
import AdminDashboardSubscriptions from "../../components/admindashboard/AdminDashboardSubscriptions";
import AdminDashboardUserInfo from "../../components/admindashboard/AdminDashboardUserInfo";
import AdminDashboardBot from "../../components/admindashboard/AdminDashboardBot";

import './index.scss';
import '../../core/forms/button.scss';

interface Props {
    match: match;
    history: History;
    enums: {
        currencyPairs: string[];
        exchanges: string[];
        candleTypes: string[];
    }
}

const HomePageProviders = ({ children }: { children: ReactNode }) => (<>
    { children }
</>);

const SnackbarComponent: React.FunctionComponent = () => {
    const { errors, setErrors, success, setSuccess } = useContext(AlertContext)!;

    const onCloseError = useCallback((error: string) => {
        if (errors) setErrors(errors.filter((err) => err !== error));
    }, [errors, setErrors]);

    return (<>
        <Snackbar open={!!errors?.length} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <div className='snackbar-alerts'>
                {errors?.map((error, i) => <Alert key={i} severity='error' onClose={() => onCloseError(error)}>{error}</Alert>)}
            </div>
        </Snackbar>
        <Snackbar open={!errors?.length && !!success} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <div className='snackbar-alerts'>
                <Alert severity='success' onClose={() => setSuccess(undefined)}>{success ?? ''}</Alert>
            </div>
        </Snackbar>
    </>);
};

const HomePage = (props: Props) => (
    <HomePageProviders>
        <InnerHomePage {...props} />
    </HomePageProviders>
);

const InnerHomePage = (props: Props) => {
    const { match, history } = props;
    
    return (
        <>
            <MainNavigation currentPath={match.url} history={history} />
            <Switch>
                <Route path='/invoices'>
                    <AdminDashboardNavigation path='/invoices' />
                    <AdminDashboardInvoices />
                </Route>
                <Route path='/subscriptions'>
                    <AdminDashboardNavigation path='/subscriptions' />
                    <AdminDashboardSubscriptions />
                </Route>
                <Route path='/directpaymentrequests'>
                    <AdminDashboardNavigation path='/directpaymentrequests' />
                    <AdminDashboardDirectPayments />
                </Route>
                <Route path='/directpaymentsallowlist'>
                    <AdminDashboardNavigation path='/directpaymentsallowlist' />
                    <AdminDashboardDirectPaymentsAllowList />
                </Route>
                <Route path='/failedexecutions'>
                    <AdminDashboardNavigation path='/failedexecutions' />
                    <AdminDashboardFailedExecutions />
                </Route>
                <Route path='/stuckexecutions'>
                    <AdminDashboardNavigation path='/stuckexecutions' />
                    <AdminDashboardStuckExecutions />
                </Route>
                <Route path='/user'>
                    <AdminDashboardNavigation path='/user' />
                    <AdminDashboardUserInfo />
                </Route>
                <Route path='/bot'>
                    <AdminDashboardNavigation path='/bot' />
                    <AdminDashboardBot />
                </Route>
            </Switch>
            <SnackbarComponent />
        </>
  );

};

export default HomePage;
