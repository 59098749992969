import React, { useMemo } from "react";
import { DirectPaymentRequest } from "../../graphql/schema/payments";
import { useBoolean } from "../../helpers/hooks";
import { titleCase } from "../../helpers/strings";
import DirectPaymentRequestModal from "./DirectPaymentRequestModal";
import './admindashboard.scss';

interface Props {
    directPaymentRequest: DirectPaymentRequest;
    refetch: any;
}

const DirectPaymentRequestRow = ({ directPaymentRequest, refetch }: Props) => {
    const [isModalOpen, openModal, closeModal] = useBoolean(false);

    const timeReceived = useMemo(() => directPaymentRequest.review?.txInfo?.timeReceived ?
        new Date(directPaymentRequest.review.txInfo.timeReceived).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' }) : '--'
    , [directPaymentRequest]);

    return (<>
        <tr className='invoice-row' onClick={openModal}>
            <td>{directPaymentRequest.id}</td>
            <td>Request {titleCase(directPaymentRequest.status)}</td>
            <td>{directPaymentRequest.requestAmount}</td>
            <td>{titleCase(directPaymentRequest.review?.decision ?? '--')}</td>
            <td>{directPaymentRequest.review?.txInfo.account ?? '--'}</td>
            <td>{titleCase(directPaymentRequest.review?.txInfo.accountType ??'--')}</td>
            <td>{directPaymentRequest.review?.txInfo.txId ?? '--'}</td>
            <td>{directPaymentRequest.review?.txInfo.amountReceived ?? '--'}</td>
            <td>{timeReceived}</td>
            <td>{directPaymentRequest.user.id}</td>
            <td>{directPaymentRequest.user.email}</td>
        </tr>
        <DirectPaymentRequestModal
            directPaymentRequest={directPaymentRequest}
            timeReceived={timeReceived}
            isOpen={isModalOpen}
            close={closeModal}
            refetch={refetch}
        />
    </>);
}

export default DirectPaymentRequestRow;