import React, { Dispatch, ReactNode, SetStateAction, useMemo, useState, useContext, useEffect } from 'react';
import { match } from 'react-router';
import { History } from 'history';
import classnames from 'classnames';

import Hero from '../../core/hero/Hero';
import './CognitoUserManager.scss';
import LoginNavigation from "../../components/loginnavigation/LoginNavigation";
import AnnouncementBanner from '../../core/userstatusbanner/AnnouncementBanner';

import { CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoLoginState } from "../../helpers/cognitoUtils";
import { CognitoLogin } from "./components/CognitoLogin";
import { CognitoNewPassword } from "./components/CognitoNewPassword";
import { CognitoForgotPassword } from "./components/CognitoForgotPassword";
import { CognitoChangePassword } from "./components/CognitoChangePassword";
import { CognitoSetupMFA } from "./components/CognitoSetupMFA";
import { CognitoMFANeeded } from "./components/CognitoMFANeeded";
import { CognitoUserPage } from "./components/CognitoUserPage";
import { CognitoConfirmUser } from "./components/CognitoConfirmUser";
import { MigratedUserStatusPage } from "./components/MigratedUserStatusPage";
import { AmplitudeContext } from '../../contexts/AmplitudeContext';
import { UserContext } from '../../contexts/UserContext';

export interface CognitoComponentProps {
    onSetCognitoLoginResponseCode:
        Dispatch<SetStateAction<CognitoLoginState | undefined>>
}

export interface CognitoUserManagerProps {
    initialState: CognitoLoginState | undefined;
    match: match;
    history: History;
    usersPage?: boolean;
}

const CognitoUserManager: React.FunctionComponent<CognitoUserManagerProps> = ({ initialState, match, history, usersPage = false }) => {
    const { logEvent } = useContext(AmplitudeContext)!;
    const { isLoading, user: loggedInUser, refetch: refetchUser } = useContext(UserContext)!;

    const [user, setUser] = useState<CognitoUser|undefined>(undefined);
    const [loginState, setLoginState] = useState<CognitoLoginState|undefined>(match.path === '/register' ? CognitoLoginState.SIGN_UP : initialState);
    const [email, setEmail] = useState<string|undefined>(undefined);
    const [initialLoginEmail, setInitialLoginEmail] = useState<string|undefined>(undefined);
    const [password, setPassword] = useState<string|undefined>(undefined);
    const [initialLoginStatus, setInitialLoginStatus] = useState<string|undefined>(undefined);

    // don't constaintly reload the page if login was successful but the backend has problems with it
    useEffect(() => {
        if (!isLoading && loginState === CognitoLoginState.SUCCESS) {
            if (usersPage) {
                window.location.reload();
            } else {
                window.location.href = '/';
            }
        }
    }, [isLoading, loginState, usersPage, loggedInUser]);

    const childComponent: ReactNode = useMemo(()=> {
        switch (loginState) {
            case undefined:
            case CognitoLoginState.LOG_IN:
            case CognitoLoginState.GENERAL_FAILURE:
            case CognitoLoginState.INCORRECT_PASSWORD:
            case CognitoLoginState.USER_NOT_FOUND:
                return <CognitoLogin
                    onSetUser={setUser}
                    onSetCognitoLoginResponseCode={setLoginState}
                    onSetEmail={setEmail}
                    onSetPassword={setPassword}
                    initialEmail={initialLoginEmail}
                    initialStatus={initialLoginStatus}
                    history={history}
                    usersPage={usersPage}
                    loginState={loginState}
                />

            case CognitoLoginState.SUCCESS:
                //return <CognitoUserPage
                  //  onSetCognitoLoginResponseCode={setLoginState}/>
                // if (usersPage) {
                //     window.location.reload();
                // } else {
                //     window.location.href = '/';
                // }
                refetchUser();
                break;

            case CognitoLoginState.NEW_PASSWORD_REQUIRED:
                return <CognitoNewPassword
                    user={user}
                    onSetCognitoLoginResponseCode={setLoginState}/>

            case CognitoLoginState.FORGOT_PASSWORD:
                return <CognitoForgotPassword
                    onSetCognitoLoginResponseCode={setLoginState}
                    email={email}
                    logEvent={logEvent}/>

            case CognitoLoginState.MFA_NEEDS_SETUP:
                return <CognitoSetupMFA
                    user={user}
                    onSetCognitoLoginResponseCode={setLoginState}/>

            case CognitoLoginState.TOTP_MFA_NEEDED:
            case CognitoLoginState.SMS_MFA_NEEDED:
                return <CognitoMFANeeded
                    user={user}
                    onSetCognitoLoginResponseCode={setLoginState}
                    loginState={loginState}
                />

            case CognitoLoginState.USER_NOT_CONFIRMED:
                return <CognitoConfirmUser
                    email={email}
                    password={password}
                    onSetCognitoLoginResponseCode={setLoginState}
                    onSetInitialLoginEmail={setInitialLoginEmail}
                    onSetInitialLoginStatus={setInitialLoginStatus}/>

            case CognitoLoginState.USER_PAGE:
                return <CognitoUserPage
                    onSetCognitoLoginResponseCode={setLoginState}/>

            case CognitoLoginState.CHANGE_PASSWORD:
                return <CognitoChangePassword
                    email={email ?? ""}
                    password={password}
                    onSetCognitoLoginResponseCode={setLoginState}/>

            case CognitoLoginState.MIGRATED_USER:
                if(password === undefined) {
                    setLoginState(CognitoLoginState.GENERAL_FAILURE);
                }

                return <MigratedUserStatusPage
                    email={email ?? ""}
                    tempPassword={password ?? ""}
                    onSetCognitoLoginResponseCode={setLoginState}/>

            default:
                window.location.href = '/';
                break;
        }

        return undefined;
    }, [loginState, user, email, password, initialLoginStatus, initialLoginEmail, history, logEvent, usersPage, refetchUser]);

    return (
        <div className='login-page'>
            <AnnouncementBanner />
            {usersPage ? <>
                <div className='login-page__wrapper state-change'>
                    <button
                        className={classnames('login-page--state-button', { 'active': loginState === CognitoLoginState.LOG_IN })}
                        onClick={() => setLoginState(CognitoLoginState.LOG_IN)}
                    >Login with email</button>
                </div>
                <div className='login-page--divider' />
            </> : <>
                <LoginNavigation />
                <Hero />
            </>}
            {childComponent}
        </div>
    );
}

export default React.memo(CognitoUserManager);
