import React, { useState } from 'react';
import classnames from "classnames";
import { Link } from 'react-router-dom';

import TunedLogo from '../../core/tunedlogo/TunedLogo';
import mobileTunedLogoImg from '../../images/tuned-mobile-logo.svg';

import './loginnav.scss';

const LoginNavigation: React.FunctionComponent = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  }

  return (
    <nav className="navbar navbar-expand-sm">
      <div className='navbar-login-brand'>
        <div className='nav__brand'>
          <h1 className='nav__brand--header'>
            <a href='https://www.tuned.com/' className='nav__brand--link'>
              <TunedLogo />
              <img src={mobileTunedLogoImg} alt='Tuned' className='tuned-logo tuned-logo--mobile' />
            </a>
          </h1>
        </div>
      </div>

      <button className={classnames('navbar-toggler', { 'collapsed': collapsed})} onClick={toggleCollapsed} >
          <span className="navbar-toggler-icon"></span>
      </button>

      <div className={classnames('navbar-collapse align-self-sm-stretch', { 'collapse': collapsed })} id="navbar">
          <ul className="navbar-nav ml-auto align-self-sm-stretch pt-sm-0">
              <li className="d-flex flex-column justify-content-center">
                  <Link to='/explore' className='nav-item'>Explore</Link>
              </li>
              <li className="d-flex flex-column justify-content-center">
                  <a className="nav-item" href="https://www.tuned.com/blog/" target="_blank" rel="noopener noreferrer">Blog</a>
              </li>
              <li className="d-flex flex-column justify-content-center">
                  <a className="nav-item" href="http://tuned.com/docs" target="_blank" rel="noopener noreferrer">Documentation</a>
              </li>
              <li className="d-flex flex-column justify-content-center">
                  <a className="nav-item" href="https://discord.gg/RMEqSCy" target="_blank" rel="noopener noreferrer">Support</a>
              </li>
              <li className="d-flex flex-column justify-content-center">
                  <a className="nav-item" href="/login">Log In</a>
              </li>
              <li className="d-flex">
                  <button className="nav-item navbar-btn px-sm-2" onClick={() => window.location.href = '/'}>Get Started</button>
              </li>
          </ul>
      </div>
    </nav>
  );
}


export default LoginNavigation;
