import Decimal from 'decimal.js';

const ONE_HUNDRED = new Decimal('100');

type Numericish = number | string | undefined | null;

export function noValue(val: Numericish): boolean {
    return (val === undefined || val === null || isNaN(Number(val)));
}

export function roundValue(val: Numericish, places: number = 5): string | number {
    if (noValue(val)) { return ''; }

    return new Decimal(val!)
        .toDecimalPlaces(places)
        .toString();
}

export function formatMoney(val: Numericish): string {
    if (noValue(val)) return '';
    return Number(val).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export function percentageValue(val: Numericish, decimals: number = 2): string | undefined {
    if (noValue(val)) { return undefined; }

    return Number(new Decimal(val!)
        .times(ONE_HUNDRED))
        .toLocaleString('en-US', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }) + '%';
}

export function trimToTwoDecimals(val: Numericish, noMin: boolean = false): string | undefined {
    if (noValue(val)) { return undefined; }

    return Number(new Decimal(val!))
        .toLocaleString('en-US', {
            ...(!noMin && { minimumFractionDigits: 2 }),
            maximumFractionDigits: 2,
        });
}

export function normalizedPercentageValue(val: Numericish): string | undefined {
    if (noValue(val)) { return undefined; }

    return percentageValue(Number(val) - 1);
}

export function trimZeroes(val: Numericish): string | undefined {
    if (noValue(val)) { return undefined; }
    return val!.toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');
}

export function trimToFiveDecimalPlaces(val: Numericish): string | undefined {
    if (noValue(val)) { return undefined; }
    return val!.toString().replace(/(\.\d{5})\d+$/,'$1');
}

export function absoluteValue(val: Numericish): string | undefined {
    if (noValue(val)) { return undefined; }
    const stringValue = val!.toString();
    return stringValue.length > 0 && stringValue.charAt(0) === '-' ? stringValue.substr(1) : stringValue;
}

export function isNegative(val: Numericish) {
    if (noValue(val)) { return false; }
    const stringValue = val!.toString();
    return stringValue.length > 0 && stringValue.charAt(0) === '-';
}

export function isNoValueOrZero(val: Numericish) {
    return noValue(val) || trimZeroes(val) === "0";
}

export function nearestDollarValue(val: Numericish) {
    return noValue(val) ? '' : Math.round(Number(val)).toLocaleString('en-US');
}

export function addNumbers(val1: Numericish, val2: Numericish, plus: boolean = true): number | undefined {
    if (noValue(val1) || noValue(val2)) { return undefined; }
    return plus
        ? Number(new Decimal(val1!).plus(val2!))
        : Number(new Decimal(val1!).minus(val2!));
}

export function exponentialToNumber(val: Numericish): string | undefined {
    if (noValue(val)) { return undefined; }

    return trimZeroes(new Decimal(val!).toFixed(20));
}

export function formatWithCommas(val: Numericish): string | undefined {
    const strVal = trimZeroes(val);
    if (noValue(strVal)) { return undefined; }
    
    const decimalPlace = strVal!.indexOf('.');
    return decimalPlace < 0
        ? Number(strVal!).toLocaleString('en-US')
        : Number(strVal!.substring(0, decimalPlace)).toLocaleString('en-US') + strVal!.substring(decimalPlace);
}

export function isNotPositive(value: string): boolean {
    return isNaN(Number(value)) || Number(value) < 0;
}