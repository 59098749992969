import React, { createContext, useState, useCallback, useContext } from "react";
import { AmplitudeContext } from "./AmplitudeContext";

export type AddErrorFn = (error: string) => void;

interface Context {
    errors: string[] | undefined;
    setErrors: (errors: string[] | undefined) => void;
    addError: AddErrorFn;
    success: string | undefined;
    setSuccess: (success: string | undefined) => void;
}

export const AlertContext = createContext<Context | undefined>(undefined);

export const AlertContextProvider = ({ children }: {children: React.ReactNode}) => {
    const [errors, setErrors] = useState<string[] | undefined>(undefined);
    const [success, setSuccess] = useState<string | undefined>(undefined);
    const { logEvent } = useContext(AmplitudeContext)!;

    const addError = useCallback((error: string) => {
        logEvent("Error", { error });
        const updatedErrors = errors?.concat(error) ?? [error];
        setErrors(updatedErrors);
    }, [errors, setErrors, logEvent]);

    return (
        <AlertContext.Provider
            value={{
                errors, setErrors, addError,
                success, setSuccess
            }}
        >
             {children}
        </AlertContext.Provider>
    );
};
