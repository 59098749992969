import React from "react";
import Lock from "../../../core/lock/Lock";
import { Formik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import { CognitoLoginState, confirmSignUp, resendSignup, signIn } from "../../../helpers/cognitoUtils";
import { CognitoComponentProps } from "../CognitoUserManager";
import Spinner from "../../../core/spinner/Spinner";
import Form from "../../../core/forms/Form";
import LoginFormField from "../../../core/forms/fields/LoginFormField";

export interface CognitoConfirmUserProps extends CognitoComponentProps {
    email: string | undefined,
    password: string | undefined,
    onSetInitialLoginEmail: (initialLoginEmail: string | undefined) => void
    onSetInitialLoginStatus: (initialLoginStatus: string | undefined) => void
}

export const CognitoConfirmUser: React.FunctionComponent<CognitoConfirmUserProps> = (
    {email, password, onSetInitialLoginStatus,
        onSetInitialLoginEmail, onSetCognitoLoginResponseCode}
) => {

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    code: ''
                }}
                validationSchema={yup.object().shape({
                    code: yup.string().required('Code is required.')
                })}
                onSubmit={({code}, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({ code: true });

                    if (email === undefined || password === undefined)
                        throw new Error('Invalid login, please contact support');

                    confirmSignUp(email, code.trim())
                        .then(() => {

                                // User is confirmed, login
                                signIn(email, password)
                                    .then(signInResponse => {

                                        if(signInResponse.responseCode === CognitoLoginState.SUCCESS) {
                                            onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS);
                                            //return;
                                        }
                                        else {
                                            onSetInitialLoginEmail(email);
                                            onSetInitialLoginStatus("Invalid login");
                                            onSetCognitoLoginResponseCode(CognitoLoginState.LOG_IN);
                                            //return;
                                        }

                                    }).catch(() => {
                                        onSetInitialLoginEmail(email);
                                        onSetInitialLoginStatus("Please try again!");
                                        onSetCognitoLoginResponseCode(CognitoLoginState.LOG_IN);
                                    }
                                );

                            }
                        ).catch((err) => {

                        switch (err.code) {
                            case 'CodeMismatchException':
                            case 'ExpiredCodeException':
                                actions.setFieldError('code', err.message);
                                break;

                            case 'NotAuthorizedException':
                            case 'UserNotFoundException':
                                actions.setStatus({
                                    signupConfirmation: err.message
                                });
                                break;

                            case 'TooManyRequestsException':
                                actions.setStatus({
                                    signupConfirmation: "It seems we are having an issue right now. Please try again after a while."
                                });
                                break;

                            default:
                                /*
                                CodeDeliveryFailureException
                                InternalErrorException
                                InvalidParameterException
                                NotAuthorizedException
                                ResourceNotFoundException
                                 */
                                actions.setStatus({
                                    signupConfirmation: "Something went wrong, please try again. If the error persists, please contact us."
                                });
                                break;

                        }

                        actions.setSubmitting(false);

                        }
                    );
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner/>
                    );

                    return (
                        <div>
                            <Form onSubmit={submitForm}>
                                <div className='login-page__wrapper__sub'>
                                    <div className="login-page__wrapper__title">Confirm Email</div>
                                    <Lock/>
                                </div>
                                {status && status.signupConfirmation &&
                                <span className='error'>{status.signupConfirmation}</span>}
                                <LoginFormField label='Please enter the code sent to your email' name='code'
                                                value={values.code} error={touched?.code ? errors?.code : undefined} className='only-field' />
                                <button className={classnames('button button--primary', { 'button--far-gap': errors?.code })}>Confirm Email</button>
                            </Form>
                            <div className='user-access'>
                                     <span
                                         onClick={() => resendSignup(email?? '')}
                                         className='access-button'>Didn't receive a code? <span>Click to resend</span></span>
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
}