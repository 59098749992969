import * as React from 'react';

import imgLock from '../../images/lock.svg';
import './lock.scss';

interface LockProps {};

const Lock: React.FunctionComponent<LockProps> = () => (
  <div className='lock'>
    <img className='lock__img' src={imgLock} alt='Lock' />
    <span className='lock__site'>https://www.tuned.com</span>
  </div>
);

export default Lock;