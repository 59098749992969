import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import * as yup from "yup";
import { SetValidationFn } from "./forms";


/**
 * Triggers a given callback on an interval, and clears it on unmount.
 * Adapted from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback the function to trigger
 * @param delay the amount of time between ticks
 */
export function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);

}

export function useBoolean(defaultValue: boolean): [boolean, () => void, () => void] {
    const [value, setValue] = useState(defaultValue);
    const setTrue = useCallback(() => setValue(true), []);
    const setFalse = useCallback(() => setValue(false), []);
    return [
      value,
      setTrue,
      setFalse
    ]
}

export function useTitle(key: string) {
    useEffect(() => {
        if (key) document.title = key + ' | Tuned';
        return () => {
            document.title = 'Tuned';
        }
    }, [key]);
}

/**
 * Light wrapper around `useState` when you want to store a plain function, so that
 * you don't need to worry about `setState`'s
 * @param defaultValue The initial state value
 */
export function useStateForCB<T>(defaultValue: T): [T, (cb: T) => void] {
    const [cb, setCbInner] = useState<T>(defaultValue);
    // Normally calling `setX` with a function treats it as an update function compared with the previous state.
    // But by wrapping the cb we want to set in another function, we bypass this.
    const setCb = useCallback((newCb: T) => setCbInner(() => newCb), [setCbInner]);
    return [cb, setCb];
}

export function useGroupedValidation(): [yup.Schema<any>, SetValidationFn] {
    const [validationGroups, setValidationGroups] = useState({});
    const groupRef = useRef(validationGroups);

    const setValidation = useMemo(() => (group: string, rules: Record<string, yup.Schema<any>>) => {
        // Using this ref so that we can memoize this function and minimize renders
        const next = { ...groupRef.current, [group]: rules };
        groupRef.current = next;
        setValidationGroups(next);
    }, []);

    const schemaGroups = Object.values(validationGroups);
    const combinedSchema = Object.assign({}, ...schemaGroups);
    const yupSchema = yup.object(combinedSchema);

    return [yupSchema, setValidation];
}

/**
 * A helper hook to return the previously set value for any
 * variable.
 * @param value The current value of the variable
 */
export function usePrevious<T = any>(value: T): T {
    const ref = useRef<T>(value);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

/**
 * A helper tool to log when a certain value changes. Good for debugging but shouldn't
 * leave in once you're done with it!
 *
 * @param label The label of the value to watch
 * @param value The current value
 */
export function useChangeDetection<T = any>(label: string, value: T, verbose: boolean = false) {
    const prev = usePrevious<T>(value); // The previously set value


    useEffect(() => {
        console.log(verbose ? `${label} initializated to ${JSON.stringify(value)}` : `${label} initialized`);
        return () => {
            console.log(`${label} uninitialized`);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (prev !== value) {
            console.log(verbose ? `${label} changed from ${JSON.stringify(prev)} to ${JSON.stringify(value)}` : `${label} changed`);
        }
    }, [label, prev, value, verbose]);
}

/**
 * Quick debug helper to log lifecycle events (Mount/Unmount/Render) for a component.
 * Should not be left in after use!
 * @param label The label to identify the logs with
 * @param verbose Whether or not to log verbose events (at this point it means render events).
 */
export function useLifecycleDebug(label: string, verbose: boolean = false) {
    // Flag to check if the init event has been logged yet
    const initRef = useRef<boolean>(false);

    useEffect(() => {
        if (initRef.current === true && verbose) {
            console.log(`${label} Rendered.`);
        }
    });

    useEffect(() => {
        if (initRef.current === false) {
            console.log(`${label} Mounted!`);
            initRef.current = true;
        };

        return () => {
            console.log(`${label} Unmounted!`);
            initRef.current = false;
        };
    }, [label]);
}
