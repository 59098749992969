import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

interface ModalProps {
    children: React.ReactNode;
    className?: string;
    isOpen: boolean;
    label?: string;
    onRequestClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
    manualClose?: boolean;
}

const Modal: React.FunctionComponent<ModalProps> = ({ children, className, isOpen, label, onRequestClose, manualClose = true }) => (
    <ReactModal
        className={classnames('modal', className)}
        overlayClassName='modal-overlay'
        isOpen={isOpen}
        contentLabel={label}
        shouldCloseOnEsc={manualClose}
        shouldCloseOnOverlayClick={manualClose}
        onRequestClose={onRequestClose}
    >
        { children }
    </ReactModal>
);

export default Modal;
