import React, { useCallback, useContext, useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UserTier } from "../../graphql/schema";
import { ADMIN_GET_USER } from "../../graphql/queries";
import { ADMIN_CHANGE_TIER } from "../../graphql/mutations";
import Panel from "../../core/panels/Panel";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import LoadingRow from "../../core/table/LoadingRow";
import { AlertContext } from "../../contexts/AlertContext";
import { parseError } from "../../helpers/errorUtils";
import { titleCase } from "../../helpers/strings";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import './admindashboard.scss';

const USER_TIER_LIST = ['COMMUNITY', 'COMMUNITY_UNLIMITED', 'FREE', 'PARTNER', 'PERFORMANCE', 'PRIVILEGED', 'TESTER', 'SUBSCRIBER', 'SUSPENDED'];

const AdminDashboardUserInfo = () => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [userId, setUserId] = useState<number | undefined>(undefined);
    const [userTier, setUserTier] = useState<UserTier | undefined>(undefined);

    const { data, loading } = useQuery(ADMIN_GET_USER, {
        variables: {
            userId
        },
        skip: !userId
    });

    const [adminChangeTier] = useMutation(ADMIN_CHANGE_TIER);

    const confirm = useConfirmationModal();

    const onAdminChangeTier = useCallback(async () => {
        if (!userTier || !userId) {
            return;
        }

        try {
            const adminChangeTierData = await adminChangeTier({ variables: {
                userId,
                newTier: userTier
            }});

            if (adminChangeTierData?.data?.adminChangeTier?.id) {
                setSuccess(`Successfully changed user id ${userId}'s tier to ${userTier}`);
            } else {
                addError(`Failed to change user id ${userId}'s tier to ${userTier}.`);
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? `Failed to change user id ${userId}'s tier to ${userTier}.`);
        }
    }, [adminChangeTier, userId, userTier, setSuccess, addError]);

    const onAdminChangeTierClick = useCallback(() => {
        if (!userTier || !userId) {
            return;
        }

        confirm(
            () => onAdminChangeTier(),
            `Do you want to change user id ${userId}'s tier to ${userTier}?`,
            `Change tier to ${userTier}`
        )
    }, [confirm, onAdminChangeTier, userId, userTier]);

    const onSubmitUserId = useCallback((e) => {
        e.preventDefault();
        setUserId(e.target.userId.value);
    }, []);

    const user = useMemo(() => data?.adminGetUser, [data]);

    return (
        <div className='admin-dashboard__invoices'>
            <div className='admin-dashboard__invoices__header'>
                    <form onSubmit={onSubmitUserId}>
                        <TextField
                            id='userId'
                            variant='outlined'
                            className='admin-dashboard__invoices__header--input'
                            placeholder='Enter user id'
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            className='admin-dashboard__invoices__header--submit-button'
                        >Get User</Button>
                    </form>
                </div>
            {userId &&
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>User Id</th>
                                <th>User Email</th>
                                <th>User Tier</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                        {user &&
                        <tr className='invoice-row'>
                            <td>{user.id}</td>
                            <td>{user.email}</td>
                            <td>{user.tier}</td>
                            <td>
                                <div className='invoice-row--dropdown-and-button'>
                                    <Autocomplete
                                        disableClearable={true}
                                        filterSelectedOptions
                                        size='small'
                                        options={USER_TIER_LIST}
                                        value={userTier}
                                        onChange={(_: any, value: string | null) => value && setUserTier(value)}
                                        getOptionLabel={(tier) => titleCase(tier)}
                                        className='admin-dashboard__invoices__header--input multi-input'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant='outlined'
                                                label='User Tier'
                                            />
                                        )}
                                    />
                                    <Button
                                        variant='contained'
                                        className='admin-dashboard__invoices__header--submit-button'
                                        onClick={onAdminChangeTierClick}
                                    >Update Tier</Button>
                                </div>
                            </td>
                            
                        </tr>
                        }
                        {!user && !loading && <tr><td>Unable to find user {userId}</td></tr>}
                        {loading && <LoadingRow columnCount={3} />}
                        </tbody>
                    </table>
                    <div className='admin-dashboard__invoices--table--footer'>
                        <div className='footer-list-item'>
                            <div>
                                COMMUNITY:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 1</div>
                                <div>Batch Test Max Size For Speed: 50,000</div>
                                <div>Batch Test Max Size For Accuracy: 5,000</div>
                                <div>Batch Test Max Candles Amount: 10,000</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                COMMUNITY_UNLIMITED:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 1</div>
                                <div>Batch Test Max Size For Speed: 50,000</div>
                                <div>Batch Test Max Size For Accuracy: 5,000</div>
                                <div>Batch Test Max Candles Amount: Unlimited</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                FREE:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 1</div>
                                <div>Batch Test Max Size For Speed: 100</div>
                                <div>Batch Test Max Size For Accuracy: 10</div>
                                <div>Batch Test Max Candles Amount: 10,000</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                PARTNER:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 1</div>
                                <div>Batch Test Max Size For Speed: 50,000</div>
                                <div>Batch Test Max Size For Accuracy: 50,000</div>
                                <div>Batch Test Max Candles Amount: Unlimited</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                PERFORMANCE:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 3</div>
                                <div>Batch Test Max Size For Speed: 250,000</div>
                                <div>Batch Test Max Size For Accuracy: 25,000</div>
                                <div>Batch Test Max Candles Amount: Unlimited</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                PRIVILEGED:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 100</div>
                                <div>Batch Test Max Size For Speed: 1,000,000</div>
                                <div>Batch Test Max Size For Accuracy: 1,000,000</div>
                                <div>Batch Test Max Candles Amount: Unlimited</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                TESTER:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 100</div>
                                <div>Batch Test Max Size For Speed: 1,000,000</div>
                                <div>Batch Test Max Size For Accuracy: 1,000,000</div>
                                <div>Batch Test Max Candles Amount: Unlimited</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                SUBSCRIBER:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 1</div>
                                <div>Batch Test Max Size For Speed: 100</div>
                                <div>Batch Test Max Size For Accuracy: 10</div>
                                <div>Batch Test Max Candles Amount: 10,000</div>
                            </div>
                        </div>
                        <div className='footer-list-item'>
                            <div>
                                SUSPENDED:
                            </div>
                            <div>
                                <div>Cocurrent Batch Tests: 0</div>
                                <div>Batch Test Max Size For Speed: 0</div>
                                <div>Batch Test Max Size For Accuracy: 0</div>
                                <div>Batch Test Max Candles Amount: 0</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
            }
        </div>
    );
}

export default AdminDashboardUserInfo;
