import { Currency } from "../graphql/schema";
import { SubscriptionUserStatus, UserSubscriptionStatus } from "../graphql/schema/payments";

export const formatExchange = (exchange?: string) => {
    switch(exchange) {
        case('BINANCE'): return 'Binance';
        case('BINANCE_FUTURES'): return 'Binance Futures';
        case('BYBIT'): return 'Bybit';
        case('BITMEX'): return 'BitMEX';
        case('FTX_FUTURES'): return 'FTX Futures';
        case('COINBASE'): return 'Coinbase';
        case('NASH'): return 'Nash';
        case('KRAKEN'): return 'Kraken';
        case('HUOBI_COIN_SWAPS'): return 'Huobi Coin-M Swaps';
        default: return exchange ?? '';
    }
}

export const isSettleBTC = (currencyPair?: Currency) => {
    return currencyPair === 'BTC' || currencyPair === 'XBT';
}

export const isActiveUserSubscription = (userStatus: SubscriptionUserStatus | undefined) =>
    userStatus === 'SUBSCRIBED' || userStatus === 'NO_RENEWAL';

export const isActiveSubscription = (subscriptionStatus: UserSubscriptionStatus | undefined) =>
    subscriptionStatus === 'ACTIVE' || subscriptionStatus === 'NEXT_CYCLE_CANCELLATION_PENDING' ||
    subscriptionStatus === 'REBILL_PENDING';

export const isPendingSubscription = (subscriptionStatus: UserSubscriptionStatus | undefined) =>
    subscriptionStatus === 'INITIAL_PAYMENT_PENDING';

export const isFailedSubscription = (subscriptionStatus: UserSubscriptionStatus | undefined) =>
    subscriptionStatus === 'INITIAL_FULFILLMENT_FAILURE' || subscriptionStatus === 'INITIAL_PAYMENT_DENIED' ||
    subscriptionStatus === 'INITIAL_PAYMENT_FAILED';