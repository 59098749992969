import React, { useCallback, useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_SUCCESS_FEE_PEAK_VALUE_OVERRIDE } from "../../graphql/mutations/payments";
import { ID } from "../../graphql/schema";
import { AlertContext } from "../../contexts/AlertContext";
import Modal from "../../core/modal/Modal";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { parseError } from "../../helpers/errorUtils";
import { Button, TextField } from "@material-ui/core";
import './settingsinvoice.scss';


interface Props {
    isOpen: boolean;
    close: () => void;
}

const OverrideSuccessFeeModal = ({ isOpen, close }: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [addSuccessFeePeakValueOverride] = useMutation(ADD_SUCCESS_FEE_PEAK_VALUE_OVERRIDE);

    const confirm = useConfirmationModal();
    
    const [executionId, setExecutionId] = useState<ID | undefined>(undefined);
    const [peakValue, setPeakValue] = useState<string | undefined>(undefined);

    const clearInputs = useCallback(() => {
        setExecutionId(undefined);
        setPeakValue(undefined);
    }, []);

    const onOverrideSuccessFee = useCallback(async () => {
        try {
            const addData = await addSuccessFeePeakValueOverride({ variables: {
                executionId,
                value: Number(peakValue)
            }});

            if (addData?.data?.addSuccessFeePeakValueOverride) {
                setSuccess('Overridden success fee peak value.');
                clearInputs();
            } else {
                addError('Unable to override success fee peak value. Try again.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to override success fee peak value. Try again.');
        }
    }, [addSuccessFeePeakValueOverride, executionId, peakValue, clearInputs, setSuccess, addError]);

    const onOverrideSuccessFeeClick = useCallback(() => {
        if (!executionId || !peakValue || isNaN(Number(peakValue))) {
            return;
        }
        confirm(
            onOverrideSuccessFee,
            'Override success fee peak value?',
            'Override peak value'
        )
    }, [executionId, peakValue, confirm, onOverrideSuccessFee]);

    return (
        <Modal isOpen={isOpen} onRequestClose={close}>
            <div className='settings__invoice--container'>
                <div className='settings__invoice'>
                <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Override Success Fee Peak Value</div>
                            </div>
                        </div>
                    </div>
                    <div className='settings__invoice__fees'>
                        <div className='settings__invoice--inputs'>
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Execution Id'
                                value={executionId}
                                onChange={(e) => setExecutionId(e.target.value)}
                            />
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Peak Value'
                                value={peakValue}
                                onChange={(e) => setPeakValue(e.target.value)}
                            />
                        </div>
                        <div className='settings__general--buttons'>
                            <Button
                                onClick={onOverrideSuccessFeeClick}
                                variant='contained'
                                className='submit-button'
                                disabled={!executionId || !peakValue || isNaN(Number(peakValue))}
                            >Override Success Fee Peak Value</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default OverrideSuccessFeeModal;