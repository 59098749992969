import gql from "graphql-tag";
import {
    EXECUTION_LIST_DATA,
    CREDENTIAL_DATA,
    SCRIPTREVISION_FULL_DATA,
    SCRIPT_MAIN_DATA,
    SCRIPT_LIST_DATA,
    CredentialDataFields,
    ExecutionListDataFields,
    ScriptRevisionFullData,
    ScriptMainData,
    MULTIVARIANT_LIST_DATA, MultivariantListDataFields, USER_DATA,
    ASSET_DATA
} from "../fragments";
import {
    CredentialCreation,
    Credential,
    ExecutionCreation,
    Execution,
    Script,
    MultivariantCreation,
    Multivariant,
    ScriptLanguage,
    UserTier,
    User,
    PackCreation,
    SyndicationSubscriptionCreation,
    SyndicationCreation
} from "../schema";

// Script Mutations

export const CREATE_SCRIPT = gql`
mutation CreateScript($language: ScriptLanguage!, $name: String!, $body: String!) {
    createScript(in: {
        language: $language,
        name: $name,
        body: $body
    }) {
        ...ScriptMainData
        latest {
            ...ScriptRevisionFullData
        }
    }
}
${SCRIPT_MAIN_DATA}
${SCRIPTREVISION_FULL_DATA}
`;

export type CreateScriptData = {
    createScript: ScriptMainData & { latest: ScriptRevisionFullData };
};

export type CreateScriptVars = {
    language: ScriptLanguage;
    name: string;
    body: string;
};

export const UPDATE_SCRIPT = gql`
mutation UpdateScript($scriptId: ID!, $body: String!) {
    updateScript(in: {
        scriptId: $scriptId,
        body: $body
    }) {
        ...ScriptMainData
        latest {
            ...ScriptRevisionFullData
        }
        revisions {
            ...ScriptRevisionFullData
        }
    }
}
${SCRIPT_MAIN_DATA}
${SCRIPTREVISION_FULL_DATA}
`;

export const UPDATE_SCRIPT_FAVORITE = gql`
mutation UpdateScriptFavorite($scriptId: ID!, $favorite: Boolean!) {
    updateScriptFavorite(id: $scriptId, favorite: $favorite) {
        ...ScriptListData
    }
}
${SCRIPT_LIST_DATA}
`;

export type UpdateScriptData = {
    updateScript: ScriptMainData & { latest: ScriptRevisionFullData };
}

export type UpdateScriptVars = {
    scriptId: string;
    body: string;
};

export const RENAME_SCRIPT = gql`
mutation RenameScript($scriptId: ID!, $name: String!) {
    renameScript(in: {
        scriptId: $scriptId,
        name: $name
    }) {
        id
        name
    }
}`;

export type RenameScriptData = {
    renameScript: Pick<Script, 'id' | 'name'>;
};
export type RenameScriptVars = {
    scriptId: string;
    name: string;
};

// Execution Mutations

export const CREATE_MULTIVARIANT = gql`
mutation CreateMultivariant($in: MultivariantCreation!) {
    createMultivariant(in: $in) {
      ...MultivariantListData
    }
  }
${MULTIVARIANT_LIST_DATA}
`;

export const VALIDATE_MULTIVARIANT = gql`
mutation ValidateMultivariant($in: MultivariantCreation!) {
    validateMultivariant(in: $in) {
      totalCombinationsCount
    }
  }
`;

export const STOP_MULTIVARIANT = gql`
mutation StopMultivariant($multivariantId: ID!) {
    stopMultivariant(id: $multivariantId) {
      ...MultivariantListData
    }
  }
${MULTIVARIANT_LIST_DATA}
`;

export const RESUME_MULTIVARIANT = gql`
mutation ResumeMultivariant($multivariantId: ID!) {
    resumeMultivariant(id: $multivariantId) {
      ...MultivariantListData
    }
  }
${MULTIVARIANT_LIST_DATA}
`;

export const UPDATE_MULTIVARIANT_FAVORITE = gql`
mutation UpdateMultivariantFavorite($id: ID!, $favorite: Boolean) {
    updateMultivariantFavorite(id: $id, favorite: $favorite) {
      ...MultivariantListData
    }
  }
${MULTIVARIANT_LIST_DATA}
`;

export const DELETE_MULTIVARIANT = gql`
mutation DeleteMultivariant($multivariantId: ID!) {
    deleteMultivariant(id: $multivariantId) {
      ...MultivariantListData
    }
}
${MULTIVARIANT_LIST_DATA}
`;

export const DELETE_MULTIVARIANTS = gql`
mutation DeleteMultivariants($multivariantIds: [ID!]!) {
    deleteMultivariants(ids: $multivariantIds) {
      ...MultivariantListData
    }
}
${MULTIVARIANT_LIST_DATA}
`;

export type PartialMultivariant = Pick<Multivariant, MultivariantListDataFields>;

export type CreateMultivariantData = {
    createMultivariant: PartialMultivariant;
}

export const CREATE_EXECUTION = gql`
mutation CreateExecution($in: ExecutionCreation!) {
    createExecution(in: $in) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export const UPDATE_EXECUTION_FAVORITE = gql`
mutation UpdateExecutionFavorite($id: ID!, $favorite: Boolean) {
    updateExecutionFavorite(id: $id, favorite: $favorite) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export const UPDATE_EXECUTION_SHARING = gql`
mutation UpdateExecutionSharing($id: ID!, $share: Boolean) {
    updateExecutionSharing(id: $id, share: $share) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export type PartialExecution = Pick<Execution, ExecutionListDataFields>;

export type CreateExecutionData = {
    createExecution: PartialExecution;
};

export type CreateExecutionVars = {
    in: ExecutionCreation;
};

export type CreateMultivariantVars = {
    in: MultivariantCreation;
}

export type CreatePackVars = {
    in: PackCreation;
};

export type CreateSyndicationSubscriptionVars = {
    in: SyndicationSubscriptionCreation;
};

export type CreateSyndicationVars = {
    in: SyndicationCreation;
};

export const CREATE_CREDENTIAL = gql`
mutation CreateCredential($in: CredentialCreation!) {
    createCredential(in: $in) {
      ...CredentialData
    }
  }
${CREDENTIAL_DATA}
`;

export const DELETE_CREDENTIAL = gql`
mutation DeleteCredential($id: ID!) {
    deleteCredential(id: $id) {
      ...CredentialData
    }
  }
${CREDENTIAL_DATA}
`;

export type CreateCredentialData = {
    createCredential: Pick<Credential, CredentialDataFields>;
};

export type CreateCredentialVars = {
    in: CredentialCreation;
};


export const STOP_EXECUTION = gql`
mutation StopExecution($executionId: ID!, $stopStatus: ExecutionStatus) {
    stopExecution(id: $executionId, stopStatus: $stopStatus) {
      ...ExecutionListData
    }
}
${EXECUTION_LIST_DATA}
`;

export const DELETE_SCRIPT = gql`
mutation DeleteScript($scriptId: ID!) {
    deleteScript(id: $scriptId)
}
`;

export type DeleteScriptData = {
    deleteScript: boolean;
};
export type DeleteScriptVars = {
    scriptId: string;
};

export const RESTART_EXECUTION = gql`
mutation RestartExecution($executionId: ID!) {
    restartExecution(id: $executionId) {
      ...ExecutionListData
    }
}
${EXECUTION_LIST_DATA}
`;

// Only simulations can be deleted
export const DELETE_SIMULATION = gql`
mutation DeleteSimulation($executionId: ID!) {
    deleteSimulation(id: $executionId)
}
`;

export const DELETE_SIMULATIONS = gql`
mutation DeleteSimulations($executionIds: [ID!]!) {
    deleteSimulations(ids: $executionIds)
}
`;

export const CREATE_FILTER_PRESET = gql`
mutation CreateFilterPreset($name: String!, $filters: String!) {
    createFilterPreset(name: $name, filters: $filters) {
        id
        name
        filters
    }
}
`;

export const DELETE_FILTER_PRESET = gql`
mutation DeleteFilterPreset($id: ID!) {
    deleteFilterPreset(id: $id)
}
`;

export const RENAME_FILTER_PRESET = gql`
mutation RenameFilterPreset($id: ID!, $name: String!) {
    renameFilterPreset(id: $id, name: $name) {
        id
        name
        filters
    }
}
`;

export const UPDATE_FILTER_PRESET = gql`
mutation UpdateFilterPreset($id: ID!, $filters: String!) {
    updateFilterPreset(id: $id, filters: $filters) {
        id
        name
        filters
    }
}
`;

export const CREATE_TAG = gql`
mutation CreateTag($name: String!, $color: String!) {
    createTag(name: $name, color: $color) {
        id
        name
        color
    }
}
`;

export const DELETE_TAG = gql`
mutation DeleteTag($id: ID!) {
    deleteTag(id: $id)
}
`;

export const UPDATE_TAG = gql`
mutation UpdateTag($id: ID!, $name: String!, $color: String!) {
    updateTag(id: $id, name: $name, color: $color) {
        id
        name
        color
    }
}
`;

export const CREATE_EXECUTION_TAG = gql`
mutation CreateExecutionTag($executionId: ID!, $tagId: ID!) {
    createExecutionTag(executionId: $executionId, tagId: $tagId) {
        ...ExecutionListData
    }
}
${EXECUTION_LIST_DATA}
`;

export const DELETE_EXECUTION_TAG = gql`
mutation DeleteExecutionTag($executionId: ID!, $tagId: ID!) {
    deleteExecutionTag(executionId: $executionId, tagId: $tagId) {
        ...ExecutionListData
    }
}
${EXECUTION_LIST_DATA}
`;

export const CREATE_MULTIVARIANT_TAG = gql`
mutation CreateMultivariantTag($multivariantId: ID!, $tagId: ID!) {
    createMultivariantTag(multivariantId: $multivariantId, tagId: $tagId) {
        ...MultivariantListData
    }
}
${MULTIVARIANT_LIST_DATA}
`;

export const DELETE_MULTIVARIANT_TAG = gql`
mutation DeleteMultivariantTag($multivariantId: ID!, $tagId: ID!) {
    deleteMultivariantTag(multivariantId: $multivariantId, tagId: $tagId) {
        ...MultivariantListData
    }
}
${MULTIVARIANT_LIST_DATA}
`;

export const CREATE_SCRIPT_TAG = gql`
mutation CreateScriptTag($scriptId: ID!, $tagId: ID!) {
    createScriptTag(scriptId: $scriptId, tagId: $tagId) {
        ...ScriptListData
    }
}
${SCRIPT_LIST_DATA}
`;

export const DELETE_SCRIPT_TAG = gql`
mutation DeleteScriptTag($scriptId: ID!, $tagId: ID!) {
    deleteScriptTag(scriptId: $scriptId, tagId: $tagId) {
        ...ScriptListData
    }
}
${SCRIPT_LIST_DATA}
`;

export const UPDATE_SCRIPT_NOTES = gql`
mutation UpdateScriptNotes($scriptId: ID!, $notes: String!) {
    updateScriptNotes(id: $scriptId, notes: $notes) {
        ...ScriptListData
    }
}
${SCRIPT_LIST_DATA}
`;

// Temporarily used until we implement full subscriptions
// We fetch the updated user from this mutation so that the Apollo cache will update
export const TEMP_CHANGE_TIER = gql`
mutation TempChangeTier($newTier: UserTier!) {
    tempChangeTier(newTier: $newTier) {
        ...UserData
    }
}
${USER_DATA}
`;
export type TempChangeTierVars = { newTier: UserTier };
export type TempChangeTierData = { tempChangeTier: User };

export const UPDATE_USER_WELCOMED = gql`
mutation UpdateUserWelcomed {
    updateUserWelcomed {
        ...UserData
    }
}
${USER_DATA}
`;

export const CREATE_PACK = gql`
mutation CreatePack($in: PackCreation!) {
    createPack(in: $in) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export type CreatePackData = {
    createPack: PartialExecution;
};

export const UPDATE_USER_GENERAL_INFO = gql`
mutation UpdateUserGeneralInfo($in: UserGeneralInfo) {
    updateUserGeneralInfo(in: $in) {
        ...UserData
    }
}
${USER_DATA}
`;

export const UPDATE_SCRIPT_PUBLIC_STATUS = gql`
mutation UpdateScriptPublicStatus($id: ID!, $isPublic: Boolean) {
    updateScriptPublicStatus(id: $id, isPublic: $isPublic) {
        ...ScriptListData
    }
}
${SCRIPT_LIST_DATA}
`;

export const INCREMENT_SCRIPT_TIMES_COPIED = gql`
mutation IncrementScriptTimesCopied($id: ID!) {
    incrementScriptTimesCopied(id: $id) {
        id
        timesCopied
    }
}
`;

export const REFRESH_CREDENTIAL_ASSETS = gql`
mutation RefreshCredentialAssets($id: ID!) {
    refreshCredentialAssets(id: $id) {
        ...CredentialData
        assets {
            total {
                ...AssetData
                usdValue
                btcValue
            }
            free {
                ...AssetData
            }
            updatedAt
        }
    }
}
${CREDENTIAL_DATA}
${ASSET_DATA}
`;

export const UPDATE_EXECUTION_STAGE = gql`
mutation UpdateExecutionStage($id: ID!, $stage: ExecutionStage) {
    updateExecutionStage(id: $id, stage: $stage) {
      ...ExecutionListData
    }
}
${EXECUTION_LIST_DATA}
`;

export const DELETE_EXECUTION = gql`
mutation DeleteExecution($executionId: ID!) {
    deleteExecution(id: $executionId)
}
`;

export const DELETE_EXECUTIONS = gql`
mutation DeleteExecutions($executionIds: [ID!]!) {
    deleteExecutions(ids: $executionIds)
}
`;

export const CREATE_SYNDICATION_SUBSCRIPTION = gql`
mutation CreateSyndicationSubscription($in: SyndicationSubscriptionCreation!) {
    createSyndicationSubscription(in: $in) {
        id
    }
}
`;

export type CreateSyndicationSubscriptionData = {
    createSyndicationSubscription: PartialExecution;
};

export const COPY_EXECUTION_FROM_MULTIVARIANT = gql`
mutation CopyExecutionFromMultivariant($id: ID!) {
    copyExecutionFromMultivariant(id: $id){
      ...ExecutionListData
    }
}
${EXECUTION_LIST_DATA}
`;

export const CONVERT_TO_SYNDICATION = gql`
mutation ConvertToSyndication($in: SyndicationCreation) {
    convertToSyndication(in: $in) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export const CONVERT_TO_PAPERTRADE = gql`
mutation ConvertToPapertrade($syndicationId: ID!) {
    convertToPapertrade(syndicationId: $syndicationId) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export const CLOSE_EXECUTION_POSITION = gql`
mutation CloseExecutionPosition($id: ID!) {
    closeExecutionPosition(id: $id) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export type CreateSyndicationData = {
    convertToSyndication: PartialExecution;
};

export const UPDATE_SYNDICATION_SETTINGS = gql`
mutation UpdateSyndicationSettings($in: SyndicationCreation) {
    updateSyndicationSettings(in: $in) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export const UPDATE_SYNDICATION_SETTINGS_VISIBILITY = gql`
mutation UpdateSyndicationSettingsVisibility($id: ID!, $isVisible: Boolean!) {
    updateSyndicationSettingsVisibility(id: $id, isVisible: $isVisible) {
      ...ExecutionListData
    }
  }
${EXECUTION_LIST_DATA}
`;

export type UpdateSyndicationData = {
    updateSyndicationSettings: PartialExecution;
};

export const UNARCHIVE_EXECUTION = gql`
mutation UnarchiveExecution($executionId: ID!) {
    unarchiveExecution(id: $executionId)
}
`;

export const UNARCHIVE_EXECUTIONS = gql`
mutation UnarchiveExecutions($executionIds: [ID!]!) {
    unarchiveExecutions(ids: $executionIds)
}
`;

export const CREATE_COLUMNS_PRESET = gql`
mutation CreateColumnsPreset($name: String!, $columns: String!) {
    createColumnsPreset(name: $name, columns: $columns) {
        id
        name
        columns
    }
}
`;

export const DELETE_COLUMNS_PRESET = gql`
mutation DeleteColumnsPreset($id: ID!) {
    deleteColumnsPreset(id: $id)
}
`;

export const RENAME_COLUMNS_PRESET = gql`
mutation RenameColumnsPreset($id: ID!, $name: String!) {
    renameColumnsPreset(id: $id, name: $name) {
        id
        name
        columns
    }
}
`;

export const UPDATE_COLUMNS_PRESET = gql`
mutation UpdateColumnsPreset($id: ID!, $columns: String!) {
    updateColumnsPreset(id: $id, columns: $columns) {
        id
        name
        columns
    }
}
`;

export const CREATE_ANNOUNCEMENT = gql`
mutation CreateAnnouncement($in: AnnouncementCreation!) {
    createAnnouncement(in: $in)
}
`;

export const DEACTIVATE_ANNOUNCEMENT = gql`
mutation DeactivateAnnouncement {
    deactivateAnnouncement
}
`;

export const UPDATE_EXECUTION_NOTES = gql`
mutation UpdateExecutionNotes($executionId: ID!, $notes: String!) {
    updateExecutionNotes(id: $executionId, notes: $notes) {
        id
        notes
    }
}
`;

export const ADMIN_CHANGE_TIER = gql`
mutation AdminChangeTier($newTier: UserTier!, $userId: ID!) {
    adminChangeTier(newTier: $newTier, userId: $userId) {
        ...UserData
    }
}
${USER_DATA}
`;

export const ADMIN_UPDATE_BOT_IS_BASKET = gql`
mutation AdminUpdateBotIsBasket($in: AdminUpdateBotIsBasketInput!) {
    adminUpdateBotIsBasket(in: $in) {
        bot {
            id
            isBasket
        }
    }
}
`;

export const ADMIN_UPDATE_BOT_VISIBILITY = gql`
mutation AdminUpdateBotVisibility($in: AdminUpdateBotVisibilityInput!) {
    adminUpdateBotVisibility(in: $in) {
        bot {
            id
            syndicationSettings {
                id
                visibility
            }
        }
    }
}
`;

export const ADMIN_STOP_EXECUTIONS = gql`
  mutation AdminStopExecutions($in: AdminStopExecutionsInput!) {
    adminStopExecutions(in: $in) {
      executions {
        id
        status
      }
    }
  }
`;
