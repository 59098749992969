import React from 'react';
import classnames from 'classnames';
import PanelScroll from '../PanelScroll';

import imgMinimize from '../../images/minimize.svg';
import TertiaryNavigation, { ITertiaryNavigationProps } from '../navigation/TertiaryNavigation';
import LinkButton from '../forms/LinkButton';

export interface ActionButtonDef {
    label: String;
    href: string;
};

interface PanelProps {
    children: React.ReactNode;
    position: 'left' | 'center' | 'right';
    className?: string;
    long?: boolean;
    noscroll?: boolean;
    editor?: boolean;
    onScroll?: React.UIEventHandler<any>;
    onFetchMore?: (scrollWrapper: any) => void;
    onMinimize?: () => void;
    subnavItems?: ITertiaryNavigationProps['items'];
}

const Panel: React.FunctionComponent<PanelProps> =
({ children, position, onMinimize, subnavItems, long = false, noscroll = false, editor = false, onScroll, onFetchMore, className }) => (
    <div className={classnames('panel', { [`panel--${position}`]: !long, [`panel--${position}-long`]: long }, className)}>
        { onMinimize && (
            <LinkButton className='minimize' onClick={onMinimize}><img src={imgMinimize} alt='Minimize' /></LinkButton>
        )}
        { noscroll &&
            <div className='panel__inner'>
                { subnavItems && <TertiaryNavigation items={subnavItems} /> }
                { children }
            </div>
        }
        { !noscroll &&
            <PanelScroll style={{ width: '100%', height: '100%' }} onScroll={onScroll} onFetchMore={onFetchMore}>
                <div className='panel__inner panel__scrollable'>
                    { subnavItems && <TertiaryNavigation items={subnavItems} /> }
                    { children }
                </div>
            </PanelScroll>
        }
    </div>
);

export default Panel;
