import React, { createContext, useContext, useMemo, useState } from "react";
import { ITertiaryNavigationProps } from "../core/navigation/TertiaryNavigation";
import { ID } from '../graphql/schema';
import Live from "../core/live/Live";
import { useBoolean, useStateForCB } from "../helpers/hooks";
import { MultiSelectContext } from "./MultiSelectContext";
import newCreateImg from '../images/new-script.svg';
import imgPencil from '../images/pencil.svg';

const getNavigationItems = function(selectedScriptId: string | undefined, candidateNewRuns: number, prereleaseNewRuns: number, syndicationNewRuns: number) {
    return [{
        title: <img src={imgPencil} alt='Editor' className='editor--img'/>,
        path: selectedScriptId ? `/editor/${selectedScriptId}` : '/editor',
        id: 'editor'
    }, {
        title: <div>Batch<span className='stage--desktop'> Testing</span></div>,
        path: '/multivariants',
        id: 'multivariants'
    }, {
        title: <div className='stage stage--experiments'><span className='stage--desktop'>Experiments</span><span className='stage--mobile experiments'>3</span></div>,
        path: '/simulations',
        stage: 'stage stage--experiments',
        id: 'experiments'
    }, {
        title: <div className='stage stage--candidates'><span className='stage--desktop'>Candidates</span><span className='stage--mobile'>2</span></div>,
        path: '/candidates',
        stage: 'stage stage--candidates',
        newTests: candidateNewRuns === 0 ? null : candidateNewRuns,
        id: 'candidates'
    }, {
        title: <div className='stage stage--release-candidates'><span className='stage--desktop'>Release Candidates</span><span className='stage--mobile'>1</span></div>,
        path: '/prerelease',
        stage: 'stage stage--release-candidates',
        newTests: prereleaseNewRuns === 0 ? null : prereleaseNewRuns,
        id: 'release-candidates'
    }, {
        title: <div className='stage stage--live'><Live/><span className='stage--non-small-mobile'>Live</span></div>,
        path: '/strategies',
        stage: 'stage stage--live',
        id: 'strategies'
    }, {
        title: <div className='stage stage--syndication'><span className='stage--desktop'>Monetize</span><span className='stage--mobile'>$$$</span></div>,
        path: '/syndications',
        stage: 'stage stage--syndication',
        newTests: syndicationNewRuns === 0 ? null : syndicationNewRuns,
        id: 'syndications'
    }];
};

export const useNavButtons = () => {
    const { isCreateDropdownOpen, openDropdown, closeDropdown } = useContext(MultiSelectContext)!;

    return useMemo<ITertiaryNavigationProps['buttons']>(() => [
        {
            title: 'Go back',
            path: '/simulations/create',
            to: '/simulations'
        },
        {
            title: <div><img className='create-button' src={newCreateImg} alt='Create' /></div>,
            path: '/simulations',
            action: isCreateDropdownOpen ? closeDropdown : openDropdown,
            tooltip: 'Create',
            dataFor: 'nav-item-create-button'
        },
        {
            title: 'Go back',
            path: '/multivariants/create',
            to: '/multivariants'
        },
        {
            title: 'Go back',
            path: '/multivariants/:id',
            to: '/multivariants'
        },
        {
            title: <div><img className='create-button' src={newCreateImg} alt='Create' /></div>,
            path: '/multivariants',
            action: isCreateDropdownOpen ? closeDropdown : openDropdown,
            tooltip: 'Create',
            dataFor: 'nav-item-create-button'
        },
        {
            title: 'Go back',
            path: '/strategies/create',
            to: '/strategies'
        },
        {
            title: 'Go back',
            path: '/syndications/subscriptions/:id',
            to: '/syndications'
        },
        {
            title: <div><img className='create-button' src={newCreateImg} alt='Create' /></div>,
            path: '/strategies',
            // to: '/strategies/create',
            action: isCreateDropdownOpen ? closeDropdown : openDropdown,
            tooltip: 'Create',
            dataFor: 'nav-item-create-button'
        },
        {
            title: <div><img className='create-button' src={newCreateImg} alt='Create' /></div>,
            path: '/candidates',
            // to: '/strategies/create',
            action: isCreateDropdownOpen ? closeDropdown : openDropdown,
            tooltip: 'Create',
            dataFor: 'nav-item-create-button'
        },
        {
            title: <div><img className='create-button' src={newCreateImg} alt='Create' /></div>,
            path: '/prerelease',
            // to: '/strategies/create',
            action: isCreateDropdownOpen ? closeDropdown : openDropdown,
            tooltip: 'Create',
            dataFor: 'nav-item-create-button'
        },
        {
            title: <div><img className='create-button' src={newCreateImg} alt='Create' /></div>,
            path: '/syndications',
            // to: '/strategies/create',
            action: isCreateDropdownOpen ? closeDropdown : openDropdown,
            tooltip: 'Create',
            dataFor: 'nav-item-create-button'
        }
    ], [isCreateDropdownOpen, closeDropdown, openDropdown]);
};

type NavItemDefs = ITertiaryNavigationProps['items'];
type IconItemBase = {
    alt?: string;
    classNames?: string[];
    iconSrc: string;
    title?: string;
}
type ActionIconItem = IconItemBase & {
    type: 'action';
    action: () => void;
    path?: undefined;
};
type LinkIconItem = IconItemBase & {
    type: 'link';
    action?: undefined;
    path: string;
}

type IconItem = ActionIconItem | LinkIconItem;

type SubscriptionItem = {
    id: ID;
    name: string;
}

interface Context {
    navItems: NavItemDefs;
    // NOTE: Secondary is not used yet, but would be nice to refactor the current display to a unified state with this.
    secondaryNavItems?: NavItemDefs;
    setSecondaryNavItems: (items?: NavItemDefs) => void;
    sideNavItems?: NavItemDefs;
    sideNavIcons?: IconItem[];
    sideNavClasses?: string[];
    mainNavClasses?: string[];
    sideNavMinimizeCB?: () => void;
    setSideNavItems: (items?: NavItemDefs) => void;
    setSideNavIcons: (icons?: IconItem[]) => void;
    setMainNavClasses: (classes?: string[]) => void;
    setSideNavClasses: (classes?: string[]) => void;
    setSideNavMinimizeCB: (cb?: () => void) => void;
    selectedScriptId: string | undefined;
    setSelectedScriptId: (scriptId: string | undefined) => void;
    candidateNewRuns: ID[];
    setCandidateNewRuns: (newRuns: ID[]) => void;
    prereleaseNewRuns: ID[];
    setPrereleaseNewRuns: (newRuns: ID[]) => void;
    syndicationNewRuns: ID[];
    setSyndicationNewRuns: (newRuns: ID[]) => void;
    secondaryNavIcons?: IconItem[];
    setSecondaryNavIcons: (icons?: IconItem[]) => void;
    newSubscription: SubscriptionItem | undefined;
    setNewSubscription: (newId: SubscriptionItem | undefined) => void;
    newSubscriptionStep: number;
    setNewSubscriptionStep: (step: number) => void;
    isGettingStartedVisible: boolean;
    showGettingStarted: () => void;
    hideGettingStarted: () => void;
}

export const NavigationContext = createContext<Context | undefined>(undefined);

export const NavigationContextProvider = ({ children }: {children: React.ReactNode}) => {
    const [selectedScriptId, setSelectedScriptId] = useState<string | undefined>();
    const [secondaryNavItems, setSecondaryNavItems] = useState<NavItemDefs | undefined>();
    const [sideNavItems, setSideNavItems] = useState<NavItemDefs | undefined>();
    const [sideNavIcons, setSideNavIcons] = useState<IconItem[] | undefined>();
    const [sideNavClasses, setSideNavClasses] = useState<string[] | undefined>();
    const [mainNavClasses, setMainNavClasses] = useState<string[] | undefined>();
    const [sideNavMinimizeCB, setSideNavMinimizeCB] = useStateForCB<(() => void) | undefined>(undefined);
    const [candidateNewRuns, setCandidateNewRuns] = useState<ID[]>(JSON.parse(localStorage.getItem('candidateNewRuns') ?? '[]'));
    const [prereleaseNewRuns, setPrereleaseNewRuns] = useState<ID[]>(JSON.parse(localStorage.getItem('prereleaseNewRuns') ?? '[]'));
    const [syndicationNewRuns, setSyndicationNewRuns] = useState<ID[]>(JSON.parse(localStorage.getItem('syndicationNewRuns') ?? '[]'));
    const [secondaryNavIcons, setSecondaryNavIcons] = useState<IconItem[] | undefined>();
    const [isGettingStartedVisible, showGettingStarted, hideGettingStarted] = useBoolean(false);

    // to display the congratulations modal after subscribing to a syndication
    const [newSubscription, setNewSubscription] = useState<SubscriptionItem | undefined>();
    const [newSubscriptionStep, setNewSubscriptionStep] = useState<number>(0);

    const navItems = useMemo(() =>
        getNavigationItems(selectedScriptId, candidateNewRuns.length, prereleaseNewRuns.length, syndicationNewRuns.length)
    , [selectedScriptId, candidateNewRuns, prereleaseNewRuns, syndicationNewRuns]);

    const contextObj = {
        navItems, secondaryNavItems, setSecondaryNavItems,
        sideNavItems, setSideNavItems,
        sideNavIcons, setSideNavIcons,
        sideNavClasses, setSideNavClasses,
        sideNavMinimizeCB, setSideNavMinimizeCB,
        selectedScriptId, setSelectedScriptId,
        mainNavClasses, setMainNavClasses,
        candidateNewRuns, setCandidateNewRuns,
        prereleaseNewRuns, setPrereleaseNewRuns,
        syndicationNewRuns, setSyndicationNewRuns,
        secondaryNavIcons, setSecondaryNavIcons,
        newSubscription, setNewSubscription,
        newSubscriptionStep, setNewSubscriptionStep,
        isGettingStartedVisible, showGettingStarted, hideGettingStarted
    };

    return (
        <NavigationContext.Provider value={contextObj}>
            {children}
        </NavigationContext.Provider>
    );
};
