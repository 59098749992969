import React, { useCallback, useEffect, useMemo } from 'react';
import TunedLogo from '../core/tunedlogo/TunedLogo';
import ChangePassword from '../components/ChangePassword';
import { History } from 'history';
import queryString from 'query-string';

import Hero from '../core/hero/Hero';
import { login } from "../helpers/session";

interface ResetPasswordPageProps {
  history: History;
}

const ResetPasswordPage = ({ history }: ResetPasswordPageProps) => {
  const onSuccess = useCallback((username: string, password: string) => {
    login(username, password).then((res) => {
      if (res.ok) {
        window.location.href = '/';
      } else {
        history.push('/login');
      }
    });
  }, [history]);

  const { username, password } = useMemo(() => {
    const {username, password } = queryString.parse(window.location.search);
    if (!username) {
      history.push('/404');
    }
    return { username, password };
  }, [history]);

  // We check if the password is incorrect, we redirect to login
  useEffect(() => {
    login(username as string, password as string).then((res) => {
      if (!res.ok) {
        history.push('/login');
      }
    });
  }, [username, password, history]);

  if (window.innerWidth < 768) {
    history.replace('/mobileblocker');
    return null;
  }

  return (
    <div className='login-page'>
      <Hero />
      <div className='login-page__wrapper'>
        <TunedLogo />
        <ChangePassword username={username as string} currentPassword={password as string} onSuccess={onSuccess}/>
        <div className='user-access'>
          <a href='/login' className='access-button' >Login instead?</a>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;