function searchParams(body: Record<string, string>): URLSearchParams {
    const params = new URLSearchParams()
    for (const key in body) params.append(key, body[key]);
    return params;
}

function apiCall(path: string, body: BodyInit | undefined = undefined, {...input} = {}): Promise<Response> {
    return fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: 'POST',
        credentials: "include",
        body,
        ...input
    });
}

export function login(username: string, password: string): Promise<Response> {
    return apiCall('/login', searchParams({ username, password }));
}

export function logout(): Promise<Response> {
    return apiCall('/logout');
}

export function changePassword(email: string, origPassword: string, newPassword: string): Promise<Response> {
    return apiCall(
        `/changePassword`,
        JSON.stringify({ email, origPassword, newPassword }),
        { headers: { 'Content-Type': 'application/json' } }
    );
}

export function acceptTerms(): Promise<Response> {
    return apiCall(
        `/acceptTerms`,
        JSON.stringify({}),
        { headers: { 'Content-Type': 'application/json' } }
    );
}

interface InviteUserProps {
    email: string,
    password: string,
    firstName: string,
    lastName: string
}

export function inviteUser(values: InviteUserProps): Promise<Response> {
    return apiCall(
        `/register`,
        JSON.stringify({ ...values, sendEmail: 'true' }),
        { headers: { 'Content-Type': 'application/json' } }
    );
}

export function onboardUser(email: string, password: string): Promise<Response> {
    return apiCall(
        '/onboardUser',
        JSON.stringify({ email, password }),
        { headers: { 'Content-Type': 'application/json' } });
}

export interface UserOnboardingResponse {
    tempPassword: string
}

export function getUserMFAStatus(): Promise<Response> {
    return apiCall(
        '/getUserMFAStatus',
        JSON.stringify({ }),
        { headers: { 'Content-Type': 'application/json' } });
}

export function setUserMFAStatus(mfaStatus: string): Promise<Response> {
    return apiCall(
        '/setUserMFAStatus',
        JSON.stringify({ mfaStatus }),
        { headers: { 'Content-Type': 'application/json' } });
}

export interface GetUserMFAStatusResponse {
    mfaStatus: string
}

export function getEnvironmentVariables(): Promise<Response> {
    return fetch(`${process.env.REACT_APP_API_URL}/envSettings`, {
        method: 'GET'
    });
}