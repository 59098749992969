import classnames from 'classnames';
import * as React from 'react';

import Item from './elements/NavigationItem';

import './tertiary.scss';
import './stages.scss';
import { Link, Route, Switch } from "react-router-dom";
import LinkButton from "../forms/LinkButton";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ComposerStateContext from "../../contexts/ComposerStateContext";
import { MultiSelectContext } from "../../contexts/MultiSelectContext";
import { AmplitudeContext } from '../../contexts/AmplitudeContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export interface NavigationItem {
  active?: boolean;
  exact?: boolean;
  action?: () => void;
  path?: string;
  title: React.ReactNode;
  disabled?: boolean;
  stage?: string;
  newTests?: number | null
  id?: string;
};

export interface ITertiaryNavigationProps {
  className?: string;
  items: NavigationItem[];
  buttons?: {
    exact?: boolean;
    path: string;
    title: React.ReactNode;
    to?: string;
    action?: () => void;
    dataFor?: string;
    tooltip?: string;
  }[];
  hidePanels?: () => void;
  children?: any;
  home?: boolean;
}

const TertiaryNavigation: React.FunctionComponent<ITertiaryNavigationProps> = ({ className, items, buttons, hidePanels, children, home = false }) => {
    const { openLiveCreation, openBacktestCreation, openSimulationPacksCreation, openSyndicationCreation } = React.useContext(ComposerStateContext)!;
    const { isCreateDropdownOpen, closeDropdown, enableCheckedList, setCheckedType, exeCheckedType, disableCheckedList } = React.useContext(MultiSelectContext)!;
    const { logEvent } = React.useContext(AmplitudeContext)!;

    const matches = useMediaQuery('(max-width: 767.98px)');

    const [mobileWarning, setWarning] = React.useState(false);

    const onCreateClick = React.useCallback((path: string) => {
        if (exeCheckedType === 'CREATE_PACK') {
            disableCheckedList();
        }

        if (path === 'live') {
            openLiveCreation();
            closeDropdown();
        } else {
            openBacktestCreation();
            closeDropdown();
        }
    }, [openBacktestCreation, openLiveCreation, closeDropdown, exeCheckedType, disableCheckedList]);

    const onCreateStrategyPackClick =  React.useCallback(() => {
        if (matches) {
            logEvent('CopyStrategyPackMobileAtempt');
            setWarning(true);
        } else {
            logEvent('OpenStrategyPackComposer');
            enableCheckedList();
            closeDropdown();
            setCheckedType('CREATE_PACK');
            openSimulationPacksCreation();
        }
    }, [matches, setWarning, enableCheckedList, setCheckedType, closeDropdown, openSimulationPacksCreation, logEvent]);

    const onCreateSyndicationClick =  React.useCallback(() => {
        if (matches) {
            logEvent('CreateSyndicationMobileAtempt');
            setWarning(true);
        } else {
            logEvent('CreateSyndicationComposer');
            enableCheckedList();
            closeDropdown();
            setCheckedType('SYNDICATION');
            openSyndicationCreation();
        }
    }, [matches, setWarning, enableCheckedList, setCheckedType, closeDropdown, openSyndicationCreation, logEvent]);

    const onClickAway = React.useCallback(() => {
        closeDropdown();
        setWarning(false);
    }, [closeDropdown, setWarning]);

    return (
        <div className={classnames('nav', 'nav--tertiary', className)}>
          <ul className='nav__container'>
            { items.map((({ disabled, active, exact, title, action, path = '', stage = '', newTests, id }, i) => (
              <Item key={i} active={active} disabled={disabled} exact={exact} path={path} action={action} title={title} stage={stage} newTests={newTests} id={id} />
            ))) }
            <li className='nav__item--divider'/>
            {home && children &&
            <>
                {children[0]}
                {children[1]}
            </>
            }
            <li className='nav__item--right'>
              <Switch>
              {buttons && buttons.map(({exact, path, title, action, to, dataFor, tooltip}, i) => (
                <Route key={i} exact={exact} path={path}>
                  {to !== undefined
                      ? <Link className='nav__item--button' to={to}>{title}</Link>
                      : <LinkButton title={tooltip} id={dataFor} className='nav__item--button' onClick={action}>{title}</LinkButton>
                  }
                  {home && isCreateDropdownOpen &&
                  <>
                      <ClickAwayListener onClickAway={onClickAway}>
                          <div className='nav__dropdown'>
                              <div className='nav__dropdown--item' id='open-backtest-composer' onClick={() => onCreateClick('simulation')}>Simulation</div>
                              <div className='nav__dropdown--item' id='open-live-composer' onClick={() => onCreateClick('live')}>Live</div>
                              <div className='nav__dropdown--item' id='open-strategy-pack-composer' onClick={onCreateStrategyPackClick}>Strategy Pack</div>
                              <div className='nav__dropdown--item' onClick={onCreateSyndicationClick}>Syndication (Beta)</div>
                          </div>
                      </ClickAwayListener>
                      <Snackbar open={mobileWarning} autoHideDuration={5000} onClose={() => setWarning(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                          <Alert
                              severity='info'
                              onClose={() => setWarning(false)}
                          >Strategy Pack creation not available on mobile.</Alert>
                      </Snackbar>
                  </>
                  }
                </Route>
              ))}
              </Switch>
            </li>
            {home && children &&
              children[2]
            }
          </ul>
          {home && children ?
            children[3]
            :
            children
          }
        </div>
    );
}
export default React.memo(TertiaryNavigation);
