import classnames from 'classnames';
import * as React from 'react';
import { Link, Route } from 'react-router-dom';
import { AmplitudeContext } from '../../../contexts/AmplitudeContext';

import './item.scss';
import LinkButton from "../../../core/forms/LinkButton";

interface INavigationItemProps {
  active?: boolean;
  exact?: boolean;
  bordered?: boolean;
  className?: string;
  dropdown?: boolean;
  path?: string;
  externalPath?: string;
  action?: () => void;
  title: React.ReactNode;
  disabled?: boolean;
  stage?: string;
  newTests?: number | null;
  id?: string;
}

const NavigationItem: React.FunctionComponent<INavigationItemProps> = ({
  disabled, active, exact, bordered, className, dropdown, path, externalPath, action, title, stage = '', newTests, id
}) => {
  const { logEvent } = React.useContext(AmplitudeContext)!;

  const itemClasses = classnames('nav__item', className, stage,
    { 'nav__item--bordered': bordered },
    { 'nav__item--active': active },
    { 'disabled': disabled }
  );

  const linkClasses = (active?: any) => classnames(
    'nav__link',
    {
      'nav__link--active': active,
      'nav__link--dropdown': dropdown,
    }
  );

  const onLinkClick = React.useCallback(() => {
      logEvent('ChangeTab', { path });
  }, [logEvent, path]);

  return (
    <li className={itemClasses}>
      { path && (
        <Route exact={exact} path={path} children={({ match }) => (
          <Link onClick={onLinkClick} to={path} className={linkClasses(active === true || match)} id={id}>{ title }</Link>
        )} />
       ) }
     { externalPath && (
         <a href={externalPath} target="_blank" rel="noopener noreferrer" className={linkClasses(active === true)}>{ title }</a>
      ) }
      { action && <LinkButton className={linkClasses(active)} onClick={disabled ? undefined : action}>
        { title }
      </LinkButton> }
      { newTests && <div className='new-tests'>{newTests}</div>}
    </li>
  );
};

export default NavigationItem;
