import { Formik } from 'formik';
import * as yup from 'yup';
import Spinner from '../core/spinner/Spinner';
import React, { useEffect } from 'react';
import TextFormField from '../core/forms/fields/TextFormField';
import Form from "../core/forms/Form";
import { useMutation } from '@apollo/client';
import { CREATE_ANNOUNCEMENT } from '../graphql/mutations';
import SimpleSelectFormField from '../core/forms/fields/SimpleSelectFormField';

interface Props {
    isSubmitting: boolean;
    submitForm: () => void;
    status: any;
    values: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const AnnouncementFormContainer = ({ isSubmitting, submitForm, status, values, setFieldValue }: Props) => {
    useEffect(() => {
        switch(values.type) {
            case('MAINTENANCE'): {
                setFieldValue('message', 'System maintenance begins ____ between ____ - ____ UTC. Live Trading, Backtesting and Payments will all be temporarily unavailable.');
                break;
            }
            case('OUTAGE'): {
                setFieldValue('message', 'Sorry, our service is experiencing a problem right now. We are working on it and hope to restore functionality shortly.');
                break;
            }
        }
    }, [values.type, setFieldValue]);

    if (isSubmitting) return (
        <Spinner />
    );

    return (
        <Form onSubmit={submitForm} className='create-announcement'>
            {status && status.message && <span className='error'>{status.message}</span>}
            <SimpleSelectFormField label='Announcement Type' name='type' options={['NONE', 'MAINTENANCE', 'OUTAGE']} />
            <TextFormField label='Announcement Description' name='message' as='textarea' />
            <TextFormField label='Duration (optional, minutes starting from now)' name='durationMinutes' />
            <button type='submit' className='button button--primary'>Publish</button>
        </Form>
    );
}

const CreateAnnouncement = ({onSuccess}: {onSuccess: () => void}) => {
    const [createAnnouncement] = useMutation(CREATE_ANNOUNCEMENT);

    return (
        <section>
            <h1>Create Announcement</h1>
            <Formik
                initialValues={{
                    message: '',
                    type: 'NONE',
                    durationMinutes: undefined
                }}
                validationSchema={yup.object().shape({
                    message: yup.string().required('Enter an announcement').max(500),
                    type: yup.string().required('Select announcement type').oneOf(['NONE', 'MAINTENANCE', 'OUTAGE']),
                    durationMinutes: yup.number().integer().positive()
                })}
                onSubmit={(values, actions) => {
                    actions.setStatus(undefined);

                    createAnnouncement({ variables: {
                        in: {
                            message: values.message,
                            type: values.type,
                            durationMinutes: values.durationMinutes
                        }
                    }}).then((res) => {
                        if (res.data.createAnnouncement) {
                            onSuccess();
                        } else {
                            actions.setSubmitting(false);
                            actions.setStatus({ message: 'Failed to create announcement' });
                        }
                    })
                }}
            >
                {({ submitForm, values, setFieldValue, status, isSubmitting }) => (
                    <AnnouncementFormContainer
                        submitForm={submitForm}
                        status={status}
                        isSubmitting={isSubmitting}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                )}
            </Formik>
        </section>
    );
}


export default CreateAnnouncement;