import React, { useCallback, useMemo, useState } from "react";
import classnames from 'classnames';
import { useQuery } from "@apollo/client";
import { GET_ALL_INVOICES } from "../../graphql/queries/payments";
import { Invoice, InvoiceStatus } from "../../graphql/schema/payments";
import { ID } from "../../graphql/schema";
import Panel from "../../core/panels/Panel";
import LoadingRow from "../../core/table/LoadingRow";
import { titleCase } from "../../helpers/strings";
import InvoiceRow from "./InvoiceRow";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import './admindashboard.scss';

type InvoiceUserType = 'USER_ID' | 'EMAIL';

const INVOICE_STATUSES: InvoiceStatus[] = ['OPEN', 'PAID', 'PENDING_MANUAL_APPROVAL', 'REJECTED', 'CANCELLED', 'EXPIRED', 'OVERDUE', 'FORGIVEN'];

const AdminDashboardInvoices = () => {
    const [userId, setUserId] = useState<ID | undefined>(undefined);
    const [type, setType] = useState<InvoiceUserType>('USER_ID');
    const [selectedIncludeStatus, setSelectedIncludeStatus] = useState<InvoiceStatus[]>([]);
    const [includeStatus, setIncludeStatus] = useState<InvoiceStatus[]>([]);
    const [periodStart, setPeriodStart] = useState<string | undefined>(undefined);
    const [periodEnd, setPeriodEnd] = useState<string | undefined>(undefined);

    const [query, setQuery] = useState<Record<string, any>>({});

    const { data, loading, refetch } = useQuery(GET_ALL_INVOICES, {
        variables: {
            ...(includeStatus.length && { includeStatus }),
            query
        },
        skip: !(query.userId || query.email),
        fetchPolicy: 'no-cache'
    });
    const allInvoices = useMemo(() => data?.allInvoices, [data]);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        setIncludeStatus(selectedIncludeStatus);
        setQuery({
            ...(userId && type === 'USER_ID' && { userId }),
            ...(userId && type === 'EMAIL' && { email: userId }),
            ...(periodStart?.length && { periodStart: new Date(periodStart).toISOString() }),
            ...(periodEnd?.length && { periodEnd: new Date(periodEnd).toISOString() })
        })
    }, [selectedIncludeStatus, userId, type, periodStart, periodEnd]);

    return (
        <div className='admin-dashboard__invoices'>
            <div className='admin-dashboard__invoices__header'>
                <form onSubmit={onSubmit}>
                    <TextField
                        id='userId'
                        variant='outlined'
                        className='admin-dashboard__invoices__header--input'
                        placeholder='Enter user id or e-mail'
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    />
                    <div className='admin-dashboard__invoices__header--type'>
                        <div
                            className={classnames('tab', { 'active': type === 'USER_ID' })}
                            onClick={() => setType('USER_ID')}
                        >User Id</div>
                        <div
                            className={classnames('tab', { 'active': type === 'EMAIL' })}
                            onClick={() => setType('EMAIL')}
                        >E-mail</div>
                    </div>
                    <Autocomplete
                        multiple
                        disableClearable={true}
                        filterSelectedOptions
                        size='small'
                        options={INVOICE_STATUSES}
                        value={selectedIncludeStatus}
                        onChange={(_, value) => setSelectedIncludeStatus(value)}
                        getOptionLabel={(status) => status === 'OPEN' ? 'Outstanding' : titleCase(status)}
                        className='admin-dashboard__invoices__header--input multi-input'
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label='Invoice statuses'
                            />
                        )}
                    />
                    <TextField
                        id='periodStart'
                        variant='outlined'
                        className='admin-dashboard__invoices__header--input date-input'
                        type='date'
                        label='Invoice Start Period'
                        InputLabelProps={{ shrink: true }}
                        value={periodStart}
                        onChange={(e) => setPeriodStart(e.target.value)}
                    />
                    <TextField
                        id='periodEnd'
                        variant='outlined'
                        className='admin-dashboard__invoices__header--input date-input'
                        type='date'
                        label='Invoice End Period'
                        InputLabelProps={{ shrink: true }}
                        value={periodEnd}
                        onChange={(e) => setPeriodEnd(e.target.value)}
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        className='admin-dashboard__invoices__header--submit-button'
                    >Submit</Button>
                </form>
            </div>
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Invoice Id</th>
                                <th>Invoice Status</th>
                                <th>Invoice Date</th>
                                <th>Subscription Id</th>
                                <th>Payment Id</th>
                                <th>Payment Status</th>
                                <td>Payment Status Reason</td>
                                <td>Payment Provider</td>
                                <th>Syndication Name</th>
                                <th>Billed From</th>
                                <th>Billed To</th>
                                <th>Billing Period</th>
                                <th>Total Amount</th>
                                <th>Pending Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allInvoices?.map((invoice: Invoice, i: number) => <InvoiceRow key={i} invoice={invoice} refetch={refetch} />)}
                        {(query.userId || query.email) && !loading && !data?.allInvoices?.length && <tr><td>No results from the selected user id or e-mail.</td></tr>}
                        {!(query.userId || query.email) && <tr><td>Enter a user Id or e-mail to get a list of invoices</td></tr>}
                        {loading && <LoadingRow columnCount={11} />}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
}

export default AdminDashboardInvoices;
