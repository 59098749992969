import * as React from 'react';

import imgLogo from '../../images/tuned-logo.svg';
import './logo.scss';

interface TunedLogoProps {
  fillColor?: string;
}

const TunedLogo: React.FunctionComponent<TunedLogoProps> = ({ fillColor = '#EBEFF3' }) => (
  <img className='tuned-logo' src={imgLogo} alt='Tuned' />
);

export default TunedLogo;
