import React from 'react';
import ReactTooltip from "react-tooltip";

type ClickWithEvent = (e: React.MouseEvent) => void;
type ClickWithoutEvent = () => void;

export interface LinkButtonProps {
    title?: string;
    id?: string;
    preventDefault?: boolean;
    stopPropagation?: boolean;
    children?: React.ReactNode;
    onClick?: ClickWithEvent | ClickWithoutEvent;
    className?: string;
    style?: React.CSSProperties;
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = ({ title, id, onClick, children, preventDefault=true, stopPropagation=false, ...aProps }) => (<>
    <button title={title && !id ? title : undefined} data-for={id} data-tip='' {...aProps} style={{...aProps.style}} onClick={e => {
        if (preventDefault === true) { e.preventDefault(); }
        if (stopPropagation === true) { e.stopPropagation(); }
        if (onClick) { onClick(e); }
    }} >{children}</button>
    {title && id && <ReactTooltip id={id} effect='solid' className='reactTooltip'>{title}</ReactTooltip>}
</>);

export default LinkButton;
