import React, { useCallback, useContext, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { REMOVE_USER_FROM_PAYMENT_ALLOW_LIST } from "../../graphql/mutations/payments";
import { DirectPaymentAllowList } from "../../graphql/schema/payments";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { AlertContext } from "../../contexts/AlertContext";
import { titleCase } from "../../helpers/strings";
import './admindashboard.scss';
import { parseError } from "../../helpers/errorUtils";
import { Button } from "@material-ui/core";

interface Props {
    directPaymentAllowList: DirectPaymentAllowList;
    refetch: any;
}

const DirectPaymentAllowListRow = ({ directPaymentAllowList, refetch }: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [removeUserFromPaymentProviderAllowList] = useMutation(REMOVE_USER_FROM_PAYMENT_ALLOW_LIST);

    const confirm = useConfirmationModal();

    const onRemoveUserFromPaymentProviderAllowList = useCallback(async () => {
        try {
            const removeData = await removeUserFromPaymentProviderAllowList({ variables: { userId: directPaymentAllowList.userId, paymentProvider: directPaymentAllowList.paymentProvider }});
            if (removeData?.data?.removeUserFromPaymentProviderAllowList) {
                refetch();
                setSuccess(`Successfully removed user ${directPaymentAllowList.userId} from the direct payments allow list.`);
            } else {
                addError(`Failed to remove user ${directPaymentAllowList.userId} from the direct payments allow list.`);
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? `Failed to remove user ${directPaymentAllowList.userId} from the direct payments allow list.`);
        }
    }, [removeUserFromPaymentProviderAllowList, directPaymentAllowList, refetch, setSuccess, addError]);

    const onRemoveUserFromPaymentProviderAllowListClick = useCallback(() => {
        confirm(
            onRemoveUserFromPaymentProviderAllowList,
            `Do you want to remove user ${directPaymentAllowList.userId} from the direct payments allow list?`,
            'Remove from allow list'
        );
    }, [confirm, onRemoveUserFromPaymentProviderAllowList, directPaymentAllowList]);

    const createdAt = useMemo(() => directPaymentAllowList.createdAt ?
        new Date(directPaymentAllowList.createdAt).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' }) : '--'
    , [directPaymentAllowList]);

    const updatedAt = useMemo(() => directPaymentAllowList.updatedAt ?
        new Date(directPaymentAllowList.updatedAt).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' }) : '--'
    , [directPaymentAllowList]);

    return (
        <tr className='invoice-row'>
            <td>{directPaymentAllowList.userId}</td>
            <td>{directPaymentAllowList.user.email}</td>
            <td>{titleCase(directPaymentAllowList.paymentProvider)}</td>
            <td>{createdAt}</td>
            <td>{updatedAt}</td>
            <td>
                <Button
                    variant='contained'
                    className='admin-dashboard__invoices__header--submit-button'
                    onClick={onRemoveUserFromPaymentProviderAllowListClick}
                >Remove</Button>
            </td>
        </tr>
    );
}

export default DirectPaymentAllowListRow;