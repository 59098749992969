import React, { useCallback, useContext, useState } from "react";
import classnames from "classnames";
import { useMutation } from "@apollo/client";
import { ADD_INVOICE_LINE_ITEM } from "../../graphql/mutations/payments";
import { InvoiceLineItemAssociationTypeInput, InvoiceLineItemType } from "../../graphql/schema/payments";
import { AlertContext } from "../../contexts/AlertContext";
import Modal from "../../core/modal/Modal";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { parseError } from "../../helpers/errorUtils";
import { titleCase } from "../../helpers/strings";
import { INVOICE_LINE_ITEM_ASSOCIATION_TYPES } from "./CreateInvoiceModal";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import './settingsinvoice.scss';

interface Props {
    isOpen: boolean;
    close: () => void;
}

const INVOICE_LINE_ITEM_TYPES: InvoiceLineItemType[] = ['FEE', 'REFUND', 'ADJUSTMENT_CREDIT', 'ADJUSTMENT_DEBIT'];

const CreateInvoiceItemModal = ({ isOpen, close }: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [addInvoiceLineItem] = useMutation(ADD_INVOICE_LINE_ITEM);

    const confirm = useConfirmationModal();
    
    const [invoiceId, setInvoiceId] = useState<string | undefined>(undefined);
    const [feeDescription, setFeeDescription] = useState<string>('');
    const [feeFixedAmount, setFeeFixedAmount] = useState<string>('');
    const [itemType, setItemType] = useState<InvoiceLineItemType>('FEE');
    const [associationType, setAssociationType] = useState<InvoiceLineItemAssociationTypeInput>('SUBSCRIPTION');
    const [associationId, setAssociationId] = useState<string>('');

    const clearInputs = useCallback(() => {
        setInvoiceId(undefined);
        setFeeDescription('');
        setFeeFixedAmount('');
        setItemType('FEE')
    }, []);

    const canCreateInvoiceItem = !!invoiceId?.length && !!associationId?.length;

    const onCreateInvoiceItem = useCallback(async () => {
        try {
            const createData = await addInvoiceLineItem({ variables: {
                invoiceId,
                item: {
                    type: itemType,
                    description: feeDescription,
                    amount: {
                        fixed: {
                            amount: feeFixedAmount
                        }
                    },
                    associations: [{
                        type: associationType,
                        id: associationId
                    }]
                }
            }});

            if (createData?.data?.addInvoiceLineItem) {
                setSuccess('Generated an invoice item.');
                clearInputs();
            } else {
                addError('Unable to generated an invoice item. Try again.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to generated an invoice item. Try again.');
        }
    }, [addInvoiceLineItem, invoiceId, itemType, feeFixedAmount, feeDescription, associationType, associationId, clearInputs, setSuccess, addError]);

    const onCreateInvoiceItemClick = () => {
        if (canCreateInvoiceItem) {
            confirm(
                onCreateInvoiceItem,
                'Generate this new invoice item?',
                'Generate Invoice Item'
            );
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={close}>
            <div className='settings__invoice--container'>
                <div className='settings__invoice'>
                <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Create Invoice Item</div>
                                <div className='settings__invoice__details__row--body'>Invoice must be Pending Manual Approval, Outstanding, or Overdue</div>
                            </div>
                        </div>
                    </div>
                    <div className='settings__invoice__fees'>
                        <div className='settings__invoice--inputs'>
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Invoice Id'
                                value={invoiceId}
                                onChange={(e) => setInvoiceId(e.target.value)}
                            />
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Fee Fixed Amount'
                                value={feeFixedAmount}
                                onChange={(e) => setFeeFixedAmount(e.target.value)}
                            />
                            <Autocomplete
                                disableClearable={true}
                                size='small'
                                options={INVOICE_LINE_ITEM_TYPES}
                                value={itemType}
                                onChange={(_: any, value: InvoiceLineItemType | null) => value && setItemType(value)}
                                getOptionLabel={(type) => titleCase(type)}
                                className='admin-dashboard__invoices__header--input multi-input'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label='Item Type'
                                    />
                                )}
                            />
                            <Autocomplete
                                disableClearable={true}
                                size='small'
                                options={INVOICE_LINE_ITEM_ASSOCIATION_TYPES}
                                value={associationType}
                                onChange={(_: any, value: InvoiceLineItemAssociationTypeInput | null) => value && setAssociationType(value)}
                                getOptionLabel={(type) => titleCase(type)}
                                className='admin-dashboard__invoices__header--input multi-input'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label='Association Type'
                                    />
                                )}
                            />
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Association Id'
                                value={associationId}
                                onChange={(e) => setAssociationId(e.target.value)}
                            />
                        </div>
                        <TextField
                            variant='outlined'
                            multiline
                            rows={4}
                            className='settings__invoice--textarea'
                            placeholder='Set a description on the fee of this invoice'
                            value={feeDescription}
                            onChange={(e) => setFeeDescription(e.target.value)}
                        />
                        <div className='settings__general--buttons'>
                            <Button
                                onClick={onCreateInvoiceItemClick}
                                variant='contained'
                                className={classnames('submit-button', { 'disabled': !canCreateInvoiceItem })}
                            >Create Invoice Item</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default CreateInvoiceItemModal;