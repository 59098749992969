import * as React from 'react';
import { Field, ErrorMessage } from 'formik';

interface ITextFormFieldProps {
  readOnly?: boolean;
  label?: string | false;
  autoFocus?: boolean;
  name: string;
  type?: 'text' | 'password';
  as?: 'input' | 'textarea';
  showError?: boolean;
  title?: string;
}

const TextFormField: React.FunctionComponent<ITextFormFieldProps> = ({ label, name, title, autoFocus = false, type = 'text', as = 'input', readOnly = false, showError = true }) => (
    <div className='form__field form__field--text' title={title}>
      { label && <label>{ label }</label> }
      <Field
        as={as}
        autoFocus={autoFocus}
        type={type}
        name={name}
        readOnly={readOnly}
      />
      {showError && <ErrorMessage name={name} component='span' className='form__field__error' /> }
    </div>
);

export default TextFormField;
