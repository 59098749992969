import React, { createContext, useState, useCallback, useEffect } from "react";
import { useBoolean } from "../helpers/hooks";
import { Exchange, CurrencyPair, ID } from "../graphql/schema";

type CheckedObject = Record<string, boolean>;

export type CheckedType = 'DELETE' | 'CREATE_PACK' | 'SYNDICATION';

export type ExchangeAndPair = {
    exchange: Exchange;
    currencyPair: CurrencyPair;
}

export const EMPTY_OBJ: CheckedObject = {};

type Callback = () => void;

interface Context {
    checkedList: CheckedObject;
    toggleChecked: (id: string, isChecked: boolean) => void;
    updateCheckedList: (ids: CheckedObject) => void;
    isCheckedList: boolean;
    enableCheckedList: Callback;
    disableCheckedList: Callback;
    isCheckedMVTs: boolean;
    enableCheckedMVTs: Callback;
    disableCheckedMVTs: Callback;
    executionsCount: number;
    setExecutionsCount: (ex: number) => void;
    checkedMVTs: CheckedObject;
    toggleCheckedMVTs: (id: string, isChecked: boolean) => void;
    updateCheckedMVTs: (ids: CheckedObject) => void;
    multivariantsCount: number;
    setMultivariantsCount: (mvt: number) => void;
    exeCheckedType: CheckedType;
    setCheckedType: (type: CheckedType) => void;
    exchangeAndPair: ExchangeAndPair | undefined;
    setExchangeAndPair: (exAndPair: ExchangeAndPair | undefined) => void;
    checkedForPack: CheckedObject;
    updateCheckedForPack: (ids: CheckedObject) => void;
    checkedForSyndication: ID | undefined;
    updateCheckedForSyndication: (id: ID | undefined) => void;
    isCreateDropdownOpen: boolean;
    openDropdown: () => void;
    closeDropdown: () => void;
    enableCheckedDeleteList: () => void;
    removeCheckedInComposer: (id: string) => void;
    isDeleting: boolean;
    setDeleting: () => void;
    setNotDeleting: () => void;
}

export const MultiSelectContext = createContext<Context | undefined>(undefined);

export const MultiSelectContextProvider = ({ children }: {children: React.ReactNode}) => {
    const [checkedList, updateChecked] = useState(EMPTY_OBJ);
    const [checkedMVTs, updateCheckedMultivariants] = useState(EMPTY_OBJ);
    const [isCheckedList, enableCheckedList, disableCheckedList] = useBoolean(false);
    const [isCheckedMVTs, enableCheckedMVTs, disableCheckedMVTs] = useBoolean(false);
    const [executionsCount, setExecutionsCount] = useState<number>(0);
    const [multivariantsCount, setMultivariantsCount] = useState<number>(0);
    const [exeCheckedType, setCheckedType] = useState<CheckedType>('DELETE');
    const [exchangeAndPair, setExchangeAndPair] = useState<ExchangeAndPair | undefined>(undefined);
    const [checkedForPack, updateCheckedForPack] = useState(EMPTY_OBJ);
    const [checkedForSyndication, updateCheckedForSyndication] = useState<ID | undefined>(undefined);
    const [isCreateDropdownOpen, openDropdown, closeDropdown] = useBoolean(false);
    const [isDeleting, setDeleting, setNotDeleting] = useBoolean(false);

    useEffect(() => {
        if (!checkedForPack['all']) setExchangeAndPair(undefined);
    }, [checkedForPack]);

    const toggle = useCallback((newCheckedList: CheckedObject, id: string, isChecked: boolean) => {
        newCheckedList[id] = isChecked;
        if (isChecked) {
          newCheckedList['all'] = isChecked;
        } else {
          let allUnchecked = true;
          for (const id in newCheckedList) {
            if (id !== 'all' && newCheckedList[id]) {
              allUnchecked = false;
            }
          }
          newCheckedList['all'] = !allUnchecked;
        }
        return newCheckedList;
    }, []);

    const toggleChecked = useCallback((id: string, isChecked: boolean) => {
      // don't let the user create a pack with more than 50 tests or check more than 1 strategy pack
      if (isChecked && (Object.keys(checkedList).length >= 51 && exeCheckedType === 'CREATE_PACK')) return;

      if (exeCheckedType === 'CREATE_PACK') {
          updateCheckedForPack(toggle({...checkedForPack}, id, isChecked));
      } else if (exeCheckedType === 'SYNDICATION') {
          if (checkedForSyndication === id || !checkedForSyndication) {
              updateCheckedForSyndication(isChecked ? id : undefined);
              if (!isChecked) setExchangeAndPair(undefined);
          }
      } else {
          updateChecked(toggle({...checkedList}, id, isChecked));
      }
    }, [checkedList, toggle, exeCheckedType, checkedForPack, checkedForSyndication]);

    const updateCheckedList = useCallback((ids: CheckedObject) => {
      updateChecked(ids);
    }, []);

    const toggleCheckedMVTs = useCallback((id: string, isChecked: boolean) => {
      updateCheckedMultivariants(toggle({...checkedMVTs}, id, isChecked));
    }, [checkedMVTs, toggle]);

    const updateCheckedMVTs = useCallback((ids: CheckedObject) => {
      updateCheckedMultivariants(ids);
    }, []);

    const enableCheckedDeleteList = useCallback(() => {
        enableCheckedList();
        setCheckedType('DELETE')
    }, [enableCheckedList]);

    const removeCheckedInComposer = useCallback((id: string) => {
        if (exeCheckedType === 'CREATE_PACK') {
            updateCheckedForPack(toggle({...checkedForPack}, id, false));
        } else if (exeCheckedType === 'SYNDICATION') {
            updateCheckedForSyndication(undefined);
            setExchangeAndPair(undefined);
        }
    }, [exeCheckedType, toggle, checkedForPack]);

    return (
        <MultiSelectContext.Provider
            value={{
                checkedList, toggleChecked, updateCheckedList,
                isCheckedList, enableCheckedList, disableCheckedList,
                isCheckedMVTs, enableCheckedMVTs, disableCheckedMVTs,
                executionsCount, setExecutionsCount,
                multivariantsCount, setMultivariantsCount,
                checkedMVTs, toggleCheckedMVTs, updateCheckedMVTs,
                exeCheckedType, setCheckedType,
                exchangeAndPair, setExchangeAndPair,
                checkedForPack, updateCheckedForPack,
                checkedForSyndication, updateCheckedForSyndication,
                isCreateDropdownOpen, openDropdown, closeDropdown,
                enableCheckedDeleteList, removeCheckedInComposer,
                isDeleting, setDeleting, setNotDeleting
            }}
        >
             {children}
        </MultiSelectContext.Provider>
    );
};
