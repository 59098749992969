import * as React from 'react';

import imgLive from '../../images/live.svg';

interface LiveProps {};

const Live: React.FunctionComponent<LiveProps> = () => (
  <div className='live'>
    <img className='live__img' src={imgLive} alt='Live' />
  </div>
);

export default Live;