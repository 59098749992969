import React, { useCallback, useContext } from "react";
import classnames from 'classnames';
import ReactTooltip from "react-tooltip";
import { useMutation } from "@apollo/client";
import { ADMIN_CHANGE_SUBSCRIPTION_PAYMENT_PROVIDER, ADMIN_DITCH_SUBSCRIPTION } from "../../graphql/mutations/payments";
import { BillingSubscription, ProductEntitlement } from "../../graphql/schema/payments";
import { AlertContext } from "../../contexts/AlertContext";
import Modal from "../../core/modal/Modal";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { parseError } from "../../helpers/errorUtils";
import { titleCase } from "../../helpers/strings";
import { formatMoney } from "../../helpers/numbers";
import { Button } from "@material-ui/core";
import './settingsinvoice.scss';


interface Props {
    subscription: BillingSubscription;
    nextBillingTime: string;
    entitlement?: ProductEntitlement;
    syndicationOwner?: string;
    currentAllocation: number;
    isOpen: boolean;
    close: () => void;
    refetch: any;
}

const SubscriptionModal = ({ subscription, nextBillingTime, entitlement, syndicationOwner, currentAllocation, isOpen, close, refetch }: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [adminChangeSubscriptionPaymentProvider] = useMutation(ADMIN_CHANGE_SUBSCRIPTION_PAYMENT_PROVIDER);
    const [adminDitchSubscription] = useMutation(ADMIN_DITCH_SUBSCRIPTION);

    const confirm = useConfirmationModal();

    const onChangeSubscriptionPaymentProvider = useCallback(async (paymentProvider: string) => {
        try {
            const payData = await adminChangeSubscriptionPaymentProvider({ variables: {
                userId: subscription.user.id,
                subscriptionId: subscription.id,
                paymentProvider
            }});

            if (payData.data?.adminChangeSubscriptionPaymentProvider) {
                setSuccess('Successfully changed payment provider.');
                refetch();
            } else {
                addError('Unable to manually change payment provider.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to manually change payment provider.');
        }
    }, [adminChangeSubscriptionPaymentProvider, subscription, refetch, setSuccess, addError]);

    const onChangeSubscriptionPaymentProviderClick = useCallback((paymentProvider: string) => {
        confirm(
            () => onChangeSubscriptionPaymentProvider(paymentProvider),
            `Change this user's subscription payment provider to ${titleCase(paymentProvider)}?`,
            `Change payment provider to ${titleCase(paymentProvider)}`
        );
    }, [confirm, onChangeSubscriptionPaymentProvider]);

    const onDitchSubscription = useCallback(async () => {
        try {
            const payData = await adminDitchSubscription({ variables: {
                userId: subscription.user.id,
                subscriptionId: subscription.id,
            }});

            if (payData.data?.adminDitchSubscription) {
                setSuccess('Successfully ditched subscription.');
                refetch();
            } else {
                addError('Unable to ditch subscription.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to ditch subscription.');
        }
    }, [adminDitchSubscription, subscription, setSuccess, refetch, addError]);

    const onDitchSubscriptionClick = useCallback(() => {
        confirm(onDitchSubscription, 'Ditch this user\'s subscription?', 'Ditch Subscription');
    }, [confirm, onDitchSubscription]);

    return (
        <Modal isOpen={isOpen} onRequestClose={close}>
            <div className='settings__invoice--container'>
                <div className='settings__invoice'>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Subscription Status</div>
                                <div className={`settings__invoice__details__row--body status__${subscription.status.toLowerCase()}`}>{titleCase(subscription.status)}</div>
                                <div className='settings__invoice__details__row--footer'></div>
                            </div>
                            <div className='settings__invoice__details__row--right'>
                                <div className='settings__invoice__details__row--header'>Next Billing Time</div>
                                <div className='settings__invoice__details__row--body'>{nextBillingTime}</div>
                            </div>
                        </div>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Syndication Name</div>
                                <div className='settings__invoice__details__row--body'>{entitlement?.syndication?.name ?? '--'}</div>
                            </div>
                            <div className='settings__invoice__details__row--right'>
                                <div className='settings__invoice__details__row--header'>Syndication Creator</div>
                                <div className='settings__invoice__details__row--body'>{syndicationOwner ?? '--'}</div>
                            </div>
                        </div>
                    </div>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Subscription Number:</div>
                            <div className='settings__invoice__details__row--body'>{subscription?.id ?? '--'}</div>
                        </div>
                    </div>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Product Number:</div>
                            <div className='settings__invoice__details__row--body'>{subscription?.product?.id ?? '--'}</div>
                        </div>
                    </div>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Product Entitlement Number:</div>
                            <div className='settings__invoice__details__row--body'>{entitlement?.id ?? '--'}</div>
                        </div>
                    </div>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Syndication Number:</div>
                            <div className='settings__invoice__details__row--body'>{entitlement?.syndication?.id ?? '--'}</div>
                        </div>
                    </div>
                    <div className='settings__invoice__fees'>
                        <div className='settings__invoice__fees__item total'>
                            <div className='settings__invoice__fees__item--title'>Current Allocation:</div>
                            <div className='settings__invoice__fees__item--total'>${formatMoney(currentAllocation)}</div>
                        </div>
                        <div className='settings__general--buttons'>
                            <div className='admin-dashboard__invoices__header--type'>
                                <div
                                    className={classnames('tab', { 'active': subscription.paymentProvider === 'CHECKOUT_COM' })}
                                    data-for={`invoice-${subscription.id}-checkout`} data-tip=''
                                    onClick={() => onChangeSubscriptionPaymentProviderClick('CHECKOUT_COM')}
                                >Checkout</div>
                                <ReactTooltip id={`invoice-${subscription.id}-checkout`} effect='solid' className='reactTooltip white'>
                                    Change payment provider to Checkout
                                </ReactTooltip>
                                <ReactTooltip id={`invoice-${subscription.id}-nashlink`} effect='solid' className='reactTooltip white'>
                                    Change payment provider to Nashlink
                                </ReactTooltip>
                                <div
                                    className={classnames('tab', { 'active': subscription.paymentProvider === 'NASHLINK' })}
                                    data-for={`invoice-${subscription.id}-nashlink`} data-tip=''
                                    onClick={() => onChangeSubscriptionPaymentProviderClick('NASHLINK')}
                                >Nashlink</div>
                                <ReactTooltip id={`invoice-${subscription.id}-directpayment`} effect='solid' className='reactTooltip white'>
                                    Change payment provider to Direct Payment
                                </ReactTooltip>
                                <div
                                    className={classnames('tab', { 'active': subscription.paymentProvider === 'DIRECT_PAYMENT' })}
                                    data-for={`invoice-${subscription.id}-directpayment`} data-tip=''
                                    onClick={() => onChangeSubscriptionPaymentProviderClick('DIRECT_PAYMENT')}
                                >Direct Payment</div>
                            </div>
                            <Button
                                onClick={onDitchSubscriptionClick}
                                variant='contained'
                                className='submit-button warning'
                            >Ditch Subscription</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SubscriptionModal;