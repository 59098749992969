import React, { useCallback, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ID } from "../../graphql/schema";
import { BILLING_PAYMENT_PROVIDER_ALLOW_LIST } from "../../graphql/queries/payments";
import { ADD_USER_TO_PAYMENT_ALLOW_LIST } from "../../graphql/mutations/payments";
import { Button, TextField } from "@material-ui/core";
import Panel from "../../core/panels/Panel";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { AlertContext } from "../../contexts/AlertContext";
import { parseError } from "../../helpers/errorUtils";
import './admindashboard.scss';
import LoadingRow from "../../core/table/LoadingRow";
import { DirectPaymentAllowListConnectionEdge } from "../../graphql/schema/payments";
import DirectPaymentAllowListRow from "./DirectPaymentAllowListRow";

const AdminDashboardDirectPaymentsAllowList = () => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const { data, loading, refetch } = useQuery(BILLING_PAYMENT_PROVIDER_ALLOW_LIST, {
        variables: {
            paymentProvider: 'DIRECT_PAYMENT'
        }
    });

    useEffect(() => {
        refetch();
    }, [refetch]);

    const [addUserToPaymentProviderAllowList] = useMutation(ADD_USER_TO_PAYMENT_ALLOW_LIST);

    const confirm = useConfirmationModal();

    const onAddUserToDirectPaymentAllowList = useCallback(async (userId: ID) => {
        try {
            const addUserToDirectPaymentAllowListData = await addUserToPaymentProviderAllowList({ variables: {
                userId,
                paymentProvider: 'DIRECT_PAYMENT'
            }});

            if (addUserToDirectPaymentAllowListData?.data?.addUserToPaymentProviderAllowList) {
                setSuccess(`Successfully added user id ${userId} to the direct payment allow list`);
            } else {
                addError(`Failed to add user id ${userId} to the direct payment allow list`);
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? `Failed to add user id ${userId} to the direct payment allow list`);
        }
    }, [addUserToPaymentProviderAllowList, setSuccess, addError]);

    const onSubmitUserId = useCallback((e) => {
        e.preventDefault();
        const userId = e.target.userId.value;
        confirm(
            () => onAddUserToDirectPaymentAllowList(userId),
            `Do you want to add user id ${userId} to the direct payment allow list?`,
            'Add User To Direct Payment Allow List'
        );
    }, [confirm, onAddUserToDirectPaymentAllowList]);

    return (
        <div className='admin-dashboard__invoices'>
            <div className='admin-dashboard__invoices__header'>
                    <form onSubmit={onSubmitUserId}>
                        <TextField
                            id='userId'
                            variant='outlined'
                            className='admin-dashboard__invoices__header--input'
                            placeholder='Enter user id'
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            className='admin-dashboard__invoices__header--submit-button'
                        >Add User To Direct Payments Allow List</Button>
                    </form>
                </div>
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>User Id</th>
                                <td>User Email</td>
                                <td>Payment Provider</td>
                                <td>Added to Allow List Date</td>
                                <td>Updated At</td>
                                <td />
                            </tr>
                        </thead>
                        <tbody>
                        {data?.billingPaymentProviderAllowList?.edges?.length ? data.billingPaymentProviderAllowList.edges.map((request: DirectPaymentAllowListConnectionEdge, i: number) =>
                            <DirectPaymentAllowListRow key={i} directPaymentAllowList={request.node} refetch={refetch} />
                        ) : <tr><td>Unable to find any direct payment requests</td></tr>}
                        {loading && <LoadingRow columnCount={5} />}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
}

export default AdminDashboardDirectPaymentsAllowList;
