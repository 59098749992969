import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './primary.scss';
import TunedLogo from "../../core/tunedlogo/TunedLogo";
import Dropdown from "../../core/navigation/elements/Dropdown";
import { isProduction, ENV_SIGIL, ENV_NAME, ENV_TYPE } from '../../helpers/environment';
import mobileTunedLogoImg from '../../images/tuned-mobile-logo.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { UserContext } from '../../contexts/UserContext';

const RELEASE_VERSION = 'v' + process.env.REACT_APP_VERSION || '0.0.0';

interface IPrimaryNavigationProps {
  firstName: string;
  settings: {
    path?: string;
    action?: () => void;
    title: React.ReactNode;
  }[];
}

const PrimaryNavigation: React.FunctionComponent<IPrimaryNavigationProps> = ({ firstName, settings }) => {
    const { isGlobalAdmin } = React.useContext(UserContext)!;

    const matches = useMediaQuery('(max-width: 767.98px)');

    return (
      <div className='nav nav--primary'>
        <div className='nav__brand' title={RELEASE_VERSION}>
          <h1 className='nav__brand--header'>
            <Link to='/' className='nav__brand--link'>
              {!matches ? <TunedLogo /> : <img src={mobileTunedLogoImg} alt='Tuned' className='tuned-logo' />}
            </Link>
          </h1>
        </div>
        { !isProduction && (
          <div className='nav__version nav__env' title={`Env Type: ${ENV_TYPE}, Name: ${ENV_NAME}`}>
            {ENV_SIGIL}
          </div>
        )}
        <ul className='nav__container nav__container--portfolio' />
        <ul className='nav__container nav__container--right'>
          <Dropdown showArrow title={firstName} items={settings} className={classnames('settings-button', { 'admin-settings': isGlobalAdmin })} />
        </ul>
      </div>
    );
}
export default React.memo(PrimaryNavigation);
