import * as React from 'react';
import { Field } from 'formik';
import classnames from "classnames";

interface ITextFormFieldProps {
  readOnly?: boolean;
  label?: string | false;
  autoFocus?: boolean;
  name: string;
  type?: 'text' | 'password';
  error?: string;
  title?: string;
  value?: string;
  description?: string;
  autoComplete?: string;
  className?: string;
}

const LoginFormField: React.FunctionComponent<ITextFormFieldProps> =
({ value, label, autoComplete, name, title, autoFocus = false, type = 'text', readOnly = false, error, description, className = '' }) => {
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    if (value) {
      setFocused(true);
    }
  }, [value]);

  return (
    <div className={classnames(`form__field form__field--text ${className}`, { 'has-error': !!error })} title={title}>
      <Field
        onFocus={() => {setFocused(true)}}
        onBlur={() => {if (!value) setFocused(false)}}
        autoFocus={autoFocus}
        type={type}
        name={name}
        readOnly={readOnly}
        className={classnames({ 'input-focus': focused, 'password-field': name === 'password' })}
        autoComplete={autoComplete}
      />
      { label && <label>{ label }</label> }
      {error && <span className='form__field__error'>{error}</span>}
      {description && !error && <span className='form__field__desc'>{description}</span>}
    </div>
  );
}

export default LoginFormField;