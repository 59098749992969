import React, { useMemo } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import Select from 'react-select';

import './select.scss';

interface ISimpleSelectFormFieldProps<ValueType> {
    readOnly?: boolean;
    label?: string | false;
    name: string;
    options: ValueType[];
    showError?: boolean;
    title?: string;
}

type SimpleSelectFormFieldType<ValueType = any> = React.FunctionComponent<ISimpleSelectFormFieldProps<ValueType>>;

export interface SelectItem<ValueType> {
    label: string;
    value: ValueType;
}

function toSelectItem<ValueType extends Object>(value: ValueType): SelectItem<ValueType> | undefined {
    if (!value) return undefined;
    return { label: value.toString().replace("_", " / "), value };
}

// Used when the value is equivalent to the label, in order to abstract away some of the data transformations
const SimpleSelectFormField: SimpleSelectFormFieldType = ({ label, name, options, title, readOnly = false, showError = true }) => {

    const optionLookup = useMemo(() => options.reduce((acc, opt) => ({ ...acc, [opt]: toSelectItem(opt) }), {}), [options]);
    const formattedOptions = useMemo(() => options.map(opt => toSelectItem(opt)), [options]);

    return (
        <div className='form__field form__field--select' title={title}>
            { label && <label>{ label }</label> }

            <Field name={name}>
                {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
                    <Select
                        className='select-field'
                        classNamePrefix='select-field'
                        options={formattedOptions}
                        name={name}
                        isClearable={false}
                        isSearchable={false}
                        value={optionLookup[value]}
                        onChange={(opt: any) => { if (readOnly) return; setFieldValue(name, opt.value) }}
                        onBlur={() => setFieldTouched(name) }
                        placeholder='Select...'
                        isDisabled={readOnly}
                    />
                )}
            </Field>

            { showError && <ErrorMessage name={name} component='span' className='form__field__error' /> }
        </div>
    );
};

export default SimpleSelectFormField;
