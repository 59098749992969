import React, { useState } from 'react';
import * as yup from 'yup';
import { match } from 'react-router';
import { Formik } from 'formik';
import LoginFormField from '../../core/forms/fields/LoginFormField';
import { History } from 'history';
import { login } from '../../helpers/session';

import Spinner from '../../core/spinner/Spinner';
import Hero from '../../core/hero/Hero';
import Lock from '../../core/lock/Lock';
import Modal from '../../core/modal/Modal';
import './login.scss';
import Form from "../../core/forms/Form";
import LoginNavigation from "../../components/loginnavigation/LoginNavigation";

interface LoginPageProps {
    match: match;
    history: History;
}

const LoginPage: React.FunctionComponent<LoginPageProps> = ({ match, history }) => {
    const [isRequestOpen, setRequestOpen] = useState(false);

    const openRequestModal = () => {
      setRequestOpen(true);
    }

    const closeRequestModal = () => {
      setRequestOpen(false);
    }

    return (
        <div className='login-page'>
            <LoginNavigation />
            <Modal isOpen={isRequestOpen} className="login-page login-modal" onRequestClose={closeRequestModal}>
                <Formik
                  initialValues={{
                    fullname: '',
                    username: ''
                  }}
                  validationSchema={yup.object().shape({
                    fullname: yup.string().required('Full name is a required field.'),
                    username: yup.string().email('Email must be valid.').required('Email address is a required field.')
                  })}
                  onSubmit={({ fullname, username }, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);

                    fetch(`https://zaedfbl8gi.execute-api.us-east-1.amazonaws.com/prod/access-request?name=${fullname}&email=${username}`, {
                      method: 'POST'
                    })
                      .then(async () => {
                          window.location.href = '/';
                      }, () => {
                          actions.setSubmitting(false);
                          actions.setStatus({
                              login: 'Invalid login'
                          });
                      });
                  }}
                  render={({ submitForm, values, status, isSubmitting }) => {
                      if (isSubmitting) return (
                          <Spinner />
                      );

                    return (
                      <Form onSubmit={submitForm}>
                        <LoginFormField autoFocus label='ENTER NAME' name='fullname' value={values.fullname} />
                        <LoginFormField label='ENTER EMAIL' name='username' value={values.username} />
                        <button className='button button--primary'>Request Access</button>
                      </Form>
                    );
                  }}
                />
            </Modal>
            <Hero />
            <div className='login-page__wrapper'>


                <Formik
                  initialValues={{
                    username: '',
                    password: ''
                  }}
                  validationSchema={yup.object().shape({
                    username: yup.string().email('Email must be valid.').required('Email address is a required field.'),
                    password: yup.string().required('Password is a required field.')
                  })}
                  onSubmit={({ username, password }, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);

                    login(username, password)
                        .then(async (res) => {
                            if (res.ok) {
                              window.location.href = '/';
                            } else {
                              actions.setSubmitting(false);
                              actions.setStatus({
                                  login: 'Invalid login'
                              });
                            }
                        });
                  }}
                  render={({ submitForm, values, status, isSubmitting }) => {
                      if (isSubmitting) return (
                          <Spinner />
                      );

                    return (
                      <>
                        <div className='login-page__wrapper__sub'>
                          <div className="login-page__wrapper__title">Log in</div>
                          <Lock/>
                        </div>
                        <Form onSubmit={submitForm}>
                          {status && status.login && <span className='error'>{status.login}</span>}
                          <LoginFormField autoFocus label='ENTER EMAIL' name='username' value={values.username} />
                          <LoginFormField label='PASSWORD' name='password' type='password' value={values.password} />
                          <button className='button button--primary'>Log In</button>
                          <div className='user-access'>
                            <span onClick={openRequestModal} className='access-button'>Not on Tuned yet? <span>Request access</span></span>
                            <a href='/forgotpassword' className='access-button hidden'>Forgot password?</a>
                          </div>
                          <div className='recaptcha-warning hidden'>
                            This page is protected by reCAPTCHA, and subject to the
                            Google Privacy Policy and Terms of Service.
                          </div>
                        </Form>
                      </>
                    );
                  }}
                />
            </div>
         </div>
    );
}

export default LoginPage;
