import classnames from 'classnames';
import * as React from 'react';

import './dropdown.scss';

import Item from './NavigationItem';
import Arrow from "../../../core/arrow/Arrow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

interface IDropdownProps {
  NestedItem?: React.FC<{ closeDropdown: any }> | React.ComponentClass<{ closeDropdown: any }>;
  items?: {
    path?: string;
    action?: () => void;
    title: React.ReactNode;
  }[];
  isHoverable?: boolean;
  showArrow?: boolean;
  title?: React.ReactNode;
  className?: string;
}

interface IDropdownState {
  isVisible: boolean;
}

class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  public state = {
    isVisible: false,
  }

  public toggleDropdown = () => {
    this.setState(({ isVisible }) => ({ isVisible: !isVisible }));
  }

  public closeDropdown = () => {
    this.setState({ isVisible: false });
  }

  public render() {
    const { NestedItem, items, isHoverable, showArrow, title, className } = this.props;
    const { isVisible } = this.state;

    const classes = classnames(
      'nav__item',
      'nav__item--dropdown',
      'nav__item--toggler',
      className ?? '',
      {
        'nav__item--hoverable': isHoverable,
        'nav__item--focused': isVisible,
      },
    );

    return (
      <ClickAwayListener onClickAway={this.closeDropdown}>
        <li className={classes}>
          <button onClick={this.toggleDropdown}>
            <div>
              <span className='nav__link nav__link--parent'>
                <span>{title}</span>
                <span className='triangle triangle--down' />
                {showArrow &&
                <Arrow direction='down' />
                }
              </span>
            </div>
          </button>
          { (isVisible || isHoverable) &&
              <ul className='nav__container nav__container--dropdown'>
                { !NestedItem && items && items.map(({ path, title, action }, i) => (
                  <Item dropdown key={i} path={path} action={() => {this.closeDropdown(); action && action();}} title={title} />
                ))}
                { NestedItem &&
                  <NestedItem closeDropdown={this.closeDropdown} />
                }
              </ul>
          }
        </li>
      </ClickAwayListener>
    );
  }
}

export default Dropdown;
