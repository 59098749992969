import React from "react";
import { Link } from "react-router-dom";
import classnames from 'classnames';
import NavigationCreateButton from "./NavigationCreateButton";
import './admindashboardnavigation.scss';

interface Props {
    path: string;
}

interface NavigationTab {
    url: string;
    name: string;
}

const NAVIGATION_TABS: NavigationTab[] = [{
    url: 'invoices',
    name: 'Invoices'
}, {
    url: 'subscriptions',
    name: 'Subscriptions'
}, {
    url: 'directpaymentrequests',
    name: 'Direct Payment Requests'
}, {
    url: 'directpaymentsallowlist',
    name: 'Direct Payments Allow List'
}, {
    url: 'failedexecutions',
    name: 'Failed Executions'
}, {
    url: 'stuckexecutions',
    name: 'Stuck Executions'
}, {
    url: 'user',
    name: 'User Info'
}, {
    url: 'bot',
    name: 'Bot'
}];

const AdminDashboardNavigation = ({ path }: Props) => (
    <div className='admin-dashboard__navigation'>
        {NAVIGATION_TABS.map(({ url, name }, i) =>
        <Link
            key={i}
            to={`/${url}`}
            className={classnames('admin-dashboard__navigation--tab', { 'active': path === `/${url}` })}
        >{name}</Link>
        )}
        <NavigationCreateButton />
    </div>
);

export default AdminDashboardNavigation;
