import React, { useCallback, useMemo, useState } from "react";
import classnames from 'classnames';
import { useQuery } from "@apollo/client";
import { GET_ALL_SUBSCRIPTIONS } from "../../graphql/queries/payments";
import { ID } from "../../graphql/schema";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Panel from "../../core/panels/Panel";
import { BillingSubscriptionConnectionEdge, UserSubscriptionStatus } from "../../graphql/schema/payments";
import LoadingRow from "../../core/table/LoadingRow";
import { titleCase } from "../../helpers/strings";
import SubscriptionRow from "./SubscriptionRow";
import './admindashboard.scss';

type InvoiceUserType = 'USER_ID' | 'EMAIL';

const SUBSCRIPTION_STATUSES: UserSubscriptionStatus[] = [
    'INTERNAL_FAILURE', 'INITIAL_REQUESTED', 'INITIAL_PAYMENT_PENDING', 'INITIAL_PAYMENT_CANCELLED', 'INITIAL_PAYMENT_FAILED',
    'INITIAL_PAYMENT_DENIED', 'INITIAL_PAYMENT_EXPIRED', 'INITIAL_FULFILLMENT_FAILURE',
    'REBILL_INTERNAL_FAILURE', 'REBILL_TERMINAL_INTERNAL_FAILURE', 'REBILL_FAILED_INVOICE_EXPIRED', 'REBILL_FAILED_INVOICE_REJECTED',
    'USER_CANCELLED', 'EXPIRED', 'ACTIVE', 'DITCHED', 'REBILL_PENDING', 'NEXT_CYCLE_CANCELLATION_PENDING'
];

const AdminDashboardSubscriptions = () => {
    const [userId, setUserId] = useState<ID | undefined>(undefined);
    const [type, setType] = useState<InvoiceUserType>('USER_ID');
    const [includeStatus, setIncludeStatus] = useState<UserSubscriptionStatus[]>([]);

    const [query, setQuery] = useState<Record<string, any>>({});

    const { data, loading, refetch } = useQuery(GET_ALL_SUBSCRIPTIONS, {
        variables: {
            query
        },
        skip: !(query.userId || query.email),
        fetchPolicy: 'no-cache'
    });
    const allSubscriptions = useMemo(() => data?.allSubscriptions, [data]);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        setQuery({
            ...(userId && type === 'USER_ID' && { userId }),
            ...(userId && type === 'EMAIL' && { email: userId }),
            ...(includeStatus.length && { includeStatus })
        })
    }, [includeStatus, userId, type]);

    return (
        <div className='admin-dashboard__invoices'>
            <div className='admin-dashboard__invoices__header'>
                    <form onSubmit={onSubmit}>
                        <TextField
                            id='userId'
                            variant='outlined'
                            className='admin-dashboard__invoices__header--input'
                            placeholder='Enter user id or e-mail'
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                        <div className='admin-dashboard__invoices__header--type'>
                            <div
                                className={classnames('tab', { 'active': type === 'USER_ID' })}
                                onClick={() => setType('USER_ID')}
                            >User Id</div>
                            <div
                                className={classnames('tab', { 'active': type === 'EMAIL' })}
                                onClick={() => setType('EMAIL')}
                            >E-mail</div>
                        </div>
                        <Autocomplete
                            multiple
                            disableClearable={true}
                            filterSelectedOptions
                            size='small'
                            options={SUBSCRIPTION_STATUSES}
                            value={includeStatus}
                            onChange={(_, value) => setIncludeStatus(value)}
                            getOptionLabel={(status) => titleCase(status)}
                            className='admin-dashboard__invoices__header--input multi-input'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Subscription statuses'
                                />
                            )}
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            className='admin-dashboard__invoices__header--submit-button'
                        >Submit</Button>
                    </form>
                </div>
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Syndication Name</th>
                                <th>Syndication Creator</th>
                                <th>Subscription Status</th>
                                <th>Payment Provider</th>
                                <th>Current Allocation</th>
                                <th>Next Billing Time</th>
                                <th>Syndication Id</th>
                                <th>Product Id</th>
                                <th>Subscription Id</th>
                            </tr>
                        </thead>
                        <tbody>
                        {allSubscriptions?.edges?.map((subscription: BillingSubscriptionConnectionEdge, i: number) => <SubscriptionRow key={i} subscription={subscription.node} refetch={refetch} />)}
                        {query.userId && !loading && !allSubscriptions?.edges?.length && <tr><td>No results from the selected user id or e-mail.</td></tr>}
                        {!query.userId && <tr><td>Enter a user Id or e-mail to get a list of invoices</td></tr>}
                        {loading && <LoadingRow columnCount={6} />}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
}

export default AdminDashboardSubscriptions;
