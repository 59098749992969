import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useSubscription } from "@apollo/client";
import Spinner from "../../core/spinner/Spinner";

// Putting all interfaces and GraphQL here since this is the only place that will access them
interface Queue {
    name: string;
    consumersCount: number;
    taskCount: number;
    inProgressTaskCount: number;
    tasksCompletedPerSecond: number;
}

interface AdminStats {
    queues: Queue[];
}


const ADMIN_STATS_SUBSCRIPTION = gql`
  subscription AdminStats {
    adminStats {
        queues {
            ...AdminQueueStats
        }
    }
  }
  fragment AdminQueueStats on AdminQueueStats {
    name
    consumersCount
    taskCount
    inProgressTaskCount
    tasksCompletedPerSecond
  }
`;

const AdminStats = () => {
    const { data, loading, error } = useSubscription<{adminStats: AdminStats}>(ADMIN_STATS_SUBSCRIPTION);

    const [lastUpdate, setLastUpdate] = useState(0);

    useEffect(() => {
        const now = Date.now();
        setLastUpdate(0);
        const interval = window.setInterval(() => {
            setLastUpdate(Math.round((Date.now() - now) / 1000));
        }, 1000);
        return () => {
            window.clearInterval(interval);
        }
    }, [data]);

    if (loading) return <Spinner/>;
    if (error) return <p>{error.message}</p>;

    return (
        <section>
            <h1>Queues Statuses</h1>
            <span>Last Updated: {lastUpdate} seconds ago</span>
        <table className='table table--compact'>
            <thead>
                <tr>
                    <td>Queue</td>
                    <td>Concurrent Handlers</td>
                    <td>Total Tasks</td>
                    <td>Tasks In Progress</td>
                    <td>Completion per Second</td>
                </tr>
            </thead>
            <tbody>
                {data!.adminStats.queues.map(({name, consumersCount, taskCount, inProgressTaskCount, tasksCompletedPerSecond}) => (
                    <tr key={name}>
                        <td>{name}</td>
                        <td>{consumersCount}</td>
                        <td>{taskCount}</td>
                        <td>{inProgressTaskCount}</td>
                        <td>{tasksCompletedPerSecond}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </section>
    );
}

export default AdminStats;

