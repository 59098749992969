import React, { createContext, useCallback, useMemo } from "react";
import { Currency, CurrencyPair, Exchange, ExchangeDetails } from "../graphql/schema";
import { GET_EXCHANGE_DETAILS } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client";

interface Context {
    exchangeDetails?: ExchangeDetails[];
    exchanges?: Exchange[];
    loading: boolean;
    error?: ApolloError;
    isMargin: (exchange: Exchange) => boolean;
    getPair: (currencyPair: CurrencyPair, exchange: Exchange) => PairDetails;
}

export interface PairDetails {
    settleCurrency: Currency;
    positionCurrency: Currency;
    priceCurrency: Currency;
}

export const ExchangeContext = createContext<Context | undefined>(undefined);

export const ExchangeContextProvider = ({ children }: {children: React.ReactNode}) => {

    const { data, loading, error } = useQuery<{exchanges: ExchangeDetails[]}>(GET_EXCHANGE_DETAILS);

    const exchangeDetails = useMemo(() => data?.exchanges, [data]);

    const exchanges = useMemo(() => exchangeDetails?.map(i => i.id), [exchangeDetails]);

    const isMargin = useCallback((exchange: Exchange) => {
        return exchangeDetails?.find(i => i.id === exchange)?.isMargin ?? false;
    }, [exchangeDetails]);

    const getPair = useCallback((currencyPair: CurrencyPair, exchange: Exchange) => {
        const exchangeDetail = exchangeDetails?.find(i => i.id === exchange);
        const currencyDetail = exchangeDetail?.currencyPairs?.find(i => i.pair === currencyPair);

        if (!currencyDetail) return {
            settleCurrency: '',
            positionCurrency: '',
            priceCurrency: ''
        };

        return {
            settleCurrency: currencyDetail.settleCurrency,
            positionCurrency: currencyDetail.positionCurrency,
            priceCurrency: currencyDetail.quote
        }
    }, [exchangeDetails])

    return (
        <ExchangeContext.Provider value={{ exchangeDetails, loading, error, exchanges, getPair, isMargin }}>
             {children}
        </ExchangeContext.Provider>
    );
};
