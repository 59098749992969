import * as React from 'react';

import imgHero from '../../images/hero.svg';
import './hero.scss';

interface HeroProps {};

const Hero: React.FunctionComponent<HeroProps> = () => (
  <div className='hero'>
    <div className='hero__graphic__container'>
      <div className='hero__graphic__load-animation'>
        <img className='hero__graphic__rotate-animation' src={imgHero} alt='Hero' />
      </div>
    </div>
  </div>
);

export default Hero;
