import gql from "graphql-tag";
import { PAYMENT_SOURCE_DATA, BILLING_SUBSCRIPTION_DATA, INVOICE_PAYMENT_DATA } from "../fragments/payments";
import { PaymentSource, SubscriptionOrder } from "../schema/payments";
import { USER_DATA } from "../fragments";
import { User } from "../schema";

export const TOKENIZE_CHECKOUT_TOKEN = gql`
mutation TokenizeCheckoutToken($token: String!) {
    source: tokenizeCheckoutToken(token: $token) {
        ...PaymentSourceData
    }
}
${PAYMENT_SOURCE_DATA}
`;

export type TokenizeCheckoutTokenVars = { token: string };
export type TokenizeCheckoutTokenData = { source: PaymentSource };

export const ORDER_SUBSCRIPTION = gql`
mutation OrderSubscription($order: SubscriptionOrder!) {
    updatedUser: orderSubscription(order: $order) {
        ...UserData
    }
}
${USER_DATA}
`;
export type OrderSubscriptionVars = { order: SubscriptionOrder };
export type OrderSubscriptionData = { updatedUser: User };

export const SUBSCRIBE_TO_SYNDICATION = gql`
mutation SubscribeToSyndication($id: ID!, $paymentProvider: PaymentProvider!) {
    subscribeToSyndication(id: $id, paymentProvider: $paymentProvider) {
        id
        invoice {
            id
            payments {
                id
                paymentProvider
                status
                statusReason
                amount
                externalId
                metadata {
                    ... on NashLinkPaymentMetadata {
                        paymentUrl
                    }

                    ... on CheckoutPaymentMetadata {
                        redirectLink3DS2
                    }
                }
            }
        }
    }
}
`;

export const UNSUBSCRIBE_FROM_SYNDICATION = gql`
mutation UnsubscribeFromSyndication($id: ID!) {
    unsubscribeFromSyndication(id: $id) {
      ...BillingSubscriptionData
    }
}
${BILLING_SUBSCRIPTION_DATA}
`;

export const ABANDON_SUBSCRIPTION_CANCELLATION = gql`
mutation AbandonSubscriptionCancellation($subscriptionId: ID!) {
    abandonSubscriptionCancellation(subscriptionId: $subscriptionId)
}
`;

export const PAY_REMAINING_INVOICE_BALANCE = gql`
mutation PayRemainingInvoiceBalance($invoiceId: ID!, $paymentProvider: PaymentProvider!) {
    payRemainingInvoiceBalance(invoiceId: $invoiceId, paymentProvider: $paymentProvider) {
        payment {
            ...InvoicePayment
        }
    }
}
${INVOICE_PAYMENT_DATA}
`;

export const MANUALLY_COMPLETE_PAYMENT = gql`
mutation ManuallyCompletePayment($invoiceId: ID!, $paymentId: ID!, $reason: String!) {
    manuallyCompletePayment(invoiceId: $invoiceId, paymentId: $paymentId, reason: $reason)
}
`;

export const MANUALLY_VOID_PAYMENT = gql`
mutation ManuallyVoidPayment($invoiceId: ID!, $paymentId: ID!, $reason: String!) {
    manuallyVoidPayment(invoiceId: $invoiceId, paymentId: $paymentId, reason: $reason)
}
`;

export const FORGIVE_INVOICE = gql`
mutation ForgiveInvoice($invoiceId: ID!, $reason: String!) {
    forgiveInvoice(invoiceId: $invoiceId, reason: $reason)
}
`;

export const ADMIN_CHANGE_SUBSCRIPTION_PAYMENT_PROVIDER = gql`
mutation AdminChangeSubscriptionPaymentProvider($userId: ID!, $subscriptionId: ID!, $paymentProvider: PaymentProvider!) {
    adminChangeSubscriptionPaymentProvider(userId: $userId, subscriptionId: $subscriptionId, paymentProvider: $paymentProvider)
}
`;

export const ADMIN_DITCH_SUBSCRIPTION = gql`
mutation AdminDitchSubscription($userId: ID!, $subscriptionId: ID!) {
    adminDitchSubscription(userId: $userId, subscriptionId: $subscriptionId)
}
`;

export const APPROVE_INVOICE = gql`
mutation ApproveInvoice($invoiceId: ID!) {
    approveInvoice(invoiceId: $invoiceId)
}
`;

export const ADD_USER_TO_PAYMENT_ALLOW_LIST = gql`
mutation AddUserToPaymentProviderAllowList($userId: ID!, $paymentProvider: PaymentProvider!) {
    addUserToPaymentProviderAllowList(userId: $userId, paymentProvider: $paymentProvider)
}
`;

export const REMOVE_USER_FROM_PAYMENT_ALLOW_LIST = gql`
mutation RemoveUserFromPaymentProviderAllowList($userId: ID!, $paymentProvider: PaymentProvider!) {
    removeUserFromPaymentProviderAllowList(userId: $userId, paymentProvider: $paymentProvider)
}
`;

export const REVIEW_DIRECT_PAYMENT = gql`
mutation ReviewDirectPayment($paymentId: ID!, $review: DirectPaymentReviewInput!) {
    reviewDirectPayment(paymentId: $paymentId, review: $review)
}
`;

export const CREATE_INVOICE = gql`
mutation CreateInvoice($in: InvoiceCreation!) {
    createInvoice(in: $in)
}
`;

export const REFUND_PAYMENT = gql`
mutation RefundPayment($invoiceId: ID!, $paymentId: ID!, $reason: String!, $amount: String) {
    refundPayment(invoiceId: $invoiceId, paymentId: $paymentId, reason: $reason, amount: $amount)
}
`;

export const MANUALLY_EXPIRE_PAYMENT = gql`
mutation ManuallyExpirePayment($invoiceId: ID!, $paymentId: ID!) {
    manuallyExpirePayment(invoiceId: $invoiceId, paymentId: $paymentId)
}
`;

export const REMOVE_INVOICE_LINE_ITEM = gql`
mutation RemoveInvoiceLineItem($invoiceId: ID!, $itemId: ID!) {
    removeInvoiceLineItem(invoiceId: $invoiceId, itemId: $itemId)
}
`;

export const ADD_INVOICE_LINE_ITEM = gql`
mutation AddInvoiceLineItem($invoiceId: ID!, $item: InvoiceItemInput!) {
    addInvoiceLineItem(invoiceId: $invoiceId, item: $item)
}
`;

export const SET_INVOICE_EXPIRATION_TIME = gql`
mutation SetInvoiceExpirationTime($invoiceId: ID!, $expirationTime: DateTime) {
    setInvoiceExpirationTime(invoiceId: $invoiceId, expirationTime: $expirationTime)
}
`;

export const ADMIN_CREATE_PAYMENT = gql`
mutation AdminCreatePayment($invoiceId: ID!, $paymentProvider: PaymentProvider!) {
    adminCreatePayment(invoiceId: $invoiceId, paymentProvider: $paymentProvider) {
        ...InvoicePayment
    }
}
${INVOICE_PAYMENT_DATA}
`;

export const ADD_SUCCESS_FEE_PEAK_VALUE_OVERRIDE = gql`
mutation AddSuccessFeePeakValueOverride($executionId: ID!, $value: Decimal!) {
    addSuccessFeePeakValueOverride(executionId: $executionId, value: $value)
}
`;