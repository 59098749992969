import React, { useContext, useEffect, useMemo } from "react";
import {  useQuery } from "@apollo/client";
import { GET_STUCK_EXECUTIONS } from "../../graphql/queries";
import { StuckExecution } from "../../graphql/schema";
import { AlertContext } from "../../contexts/AlertContext";
import Panel from "../../core/panels/Panel";
import LoadingRow from "../../core/table/LoadingRow";
import { titleCase } from "../../helpers/strings";
import { formatExchange } from "../../helpers/formLabelUtils";
import { Button } from "@material-ui/core";
import './admindashboard.scss';

const AdminDashboardStuckExecutions = () => {
    const { addError, setSuccess } = useContext(AlertContext)!;
    
    const { data, loading, refetch } = useQuery(GET_STUCK_EXECUTIONS);

    const date = useMemo(() => data?.stuckExecutions?.length ?
        new Date(data.stuckExecutions[0].createdAt).toLocaleString() : '--'
    , [data]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    const restartStuckExecutions = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/restartStuckExecutions`, {
            method: "POST",
            cache: "no-cache",
            credentials: "include",
            mode: "cors",
        });
        const restarted = await res.json();
        if (restarted === "OK") {
            setSuccess("Restarting stuck executions...");
        } else {
            addError("Failed to restart stuck executions.");
        }
    }

    return (
        <div className='admin-dashboard__invoices'>
            <div className='admin-dashboard__invoices__header'>
                <Button
                    variant='contained'
                    className='admin-dashboard__invoices__header--submit-button'
                    onClick={() => refetch()}
                >Refresh</Button>
                <Button
                    variant='contained'
                    className='admin-dashboard__invoices__header--submit-button'
                    onClick={restartStuckExecutions}
                    style={{ marginLeft: "10px" }}
                >Restart</Button>
            </div>
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Stuck Executions Count</th>
                                <th>Exchange</th>
                                <th>Type</th>
                                <th>Currency Pair</th>
                                <th>Average Seconds Without Update</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data?.stuckExecutions?.length ? data.stuckExecutions.map((stuck: StuckExecution, i: number) =>
                            <tr className='invoice-row' key={i} >
                                <td>{stuck.count}</td>
                                <td>{formatExchange(stuck.exchange)}</td>
                                <td>{titleCase(stuck.type)}</td>
                                <td>{stuck.currencyPair}</td>
                                <td>{stuck.avgSecondsWithoutUpdate}</td>
                                <td>{date}</td>
                            </tr>
                        ) : <tr><td>Unable to find any stuck executions.</td></tr>}
                        {loading && <LoadingRow columnCount={5} />}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
}

export default AdminDashboardStuckExecutions;