import React, { useMemo } from "react";
import { BillingSubscription } from "../../graphql/schema/payments";
import { useBoolean } from "../../helpers/hooks";
import { formatMoney } from "../../helpers/numbers";
import { titleCase } from "../../helpers/strings";
import SubscriptionModal from "./SubscriptionModal";
import './admindashboard.scss';

interface Props {
    subscription: BillingSubscription;
    refetch: any;
}

const SubscriptionRow = ({ subscription, refetch }: Props) => {
    const [isModalOpen, openModal, closeModal] = useBoolean(false);

    const nextBillingTime = useMemo(() => subscription?.nextBillingTime ?
        new Date(subscription?.nextBillingTime).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' }) : '--'
    , [subscription]);

    const entitlement = useMemo(() => subscription?.product?.entitlements?.length ? subscription.product.entitlements[0] : undefined, [subscription]);

    const syndicationOwner = useMemo(() => {
        const owner = entitlement?.syndication?.owner;

        return owner?.companyName ?? (owner?.firstName || owner?.lastName)
            ? `${owner?.firstName ?? ''} ${owner?.lastName ?? ''}`
            : owner?.nickname ?? '--';
    },[entitlement]);

    const currentAllocation = useMemo(() => {
        let totalAllocation = 0;

        for (let entitlement of subscription.entitlements) {
            if (entitlement.currentAllocation) {
                totalAllocation += Number(entitlement.currentAllocation)
            }
        }

        return totalAllocation;
    }, [subscription]);

    return (<>
        <tr className='invoice-row' onClick={openModal}>
            <td>{entitlement?.syndication?.name}</td>
            <td>{syndicationOwner}</td>
            <td>{titleCase(subscription.status)}</td>
            <td>{subscription.paymentProvider}</td>
            <td>{formatMoney(currentAllocation)} {entitlement?.syndication?.currencyPairDetails?.settleCurrency ?? ''}</td>
            <td>{nextBillingTime}</td>
            <td>{entitlement?.syndication?.id}</td>
            <td>{subscription.product.id}</td>
            <td>{subscription.id}</td>
        </tr>
        <SubscriptionModal
            subscription={subscription}
            nextBillingTime={nextBillingTime}
            entitlement={entitlement}
            syndicationOwner={syndicationOwner}
            currentAllocation={currentAllocation}
            isOpen={isModalOpen}
            close={closeModal}
            refetch={refetch}
        />
    </>);
}

export default SubscriptionRow;