import { CognitoUser } from "amazon-cognito-identity-js";
import { CognitoLoginState, confirmSignIn } from "../../../helpers/cognitoUtils";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import Spinner from "../../../core/spinner/Spinner";
import Lock from "../../../core/lock/Lock";
import Form from "../../../core/forms/Form";
import LoginFormField from "../../../core/forms/fields/LoginFormField";
import { CognitoComponentProps } from "../CognitoUserManager";

export interface CognitoMFANeededProps extends CognitoComponentProps {
    user: CognitoUser | undefined,
    loginState: CognitoLoginState
}

export const CognitoMFANeeded: React.FunctionComponent<CognitoMFANeededProps> = (
    { user, onSetCognitoLoginResponseCode, loginState}
) => {

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    code: ''
                }}
                validationSchema={yup.object().shape({
                    code: yup.string().required('Code is required.')
                })}
                onSubmit={({ code }, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({ code: true });

                    confirmSignIn(user, code.trim(),
                        loginState === CognitoLoginState.SMS_MFA_NEEDED ? 'SMS_MFA' :
                            loginState === CognitoLoginState.TOTP_MFA_NEEDED ? 'SOFTWARE_TOKEN_MFA' :
                                null)
                        .then((session) => {
                                onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS)
                            }
                        ).catch((err) => {
                            actions.setSubmitting(false);
                            actions.setStatus({
                                login: 'Invalid code'
                            });
                        }
                    );
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner />
                    );

                    return (
                            <Form onSubmit={submitForm}>
                                <div className='login-page__wrapper__sub'>
                                    <div className="login-page__wrapper__title">Enter the six digit two-factor authentication code sent to your device</div>
                                    <Lock/>
                                </div>
                                {status && status.login && <span className='error'>{status.login}</span>}
                                <LoginFormField label='Authorization Code' name='code' value={values.code}
                                                error={touched?.code ? errors?.code : undefined} className='only-field' />
                                <button className={classnames('button button--primary', { 'button--far-gap': errors?.code })}>Log In</button>
                            </Form>
                    );
                }}
            />
        </div>
    );
}
