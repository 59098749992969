import React, { createContext, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState, useContext } from "react";
import { ID, ExecutionType } from "../graphql/schema";
import { useBoolean } from "../helpers/hooks";
import { useRouteMatch } from "react-router-dom";
import { PartialExecution } from "../graphql/mutations";
import { MultiSelectContext, EMPTY_OBJ } from './MultiSelectContext';
import { AmplitudeContext } from './AmplitudeContext';

interface SelectedRun {
    type: 'execution' | 'mvt' | 'strategyPack' | 'subscription' | 'syndication';
    id: ID;
    mvtId?: ID;
    isPackExecution?: boolean;
    toLiveCopy?: boolean;
    strategySettingsField?: string; // to go to the selected strategy settings field
}

interface Context {
    isDisplayed: boolean;
    openComposer: () => void;
    hide: () => void;
    displayForExecution: (executionID: ID, isPackExecution?: boolean, toLiveCopy?: boolean) => void;
    displayForMVT: (mvtID: ID, strategySettingsField?: string) => void;
    displayForPreview: () => void;
    displayForPack: (executionID: ID, toLiveCopy?: boolean) => void;
    displayForSubscription: (id: ID) => void;
    displayForSyndication: (id: ID) => void;
    selected?: SelectedRun;
    creationType?: ExecutionType;
    setCreationType: (type: ExecutionType | undefined) => void;
    clearSelected: () => void;
    openLiveCreation: () => void;
    openBacktestCreation: () => void;
    lastCreatedExecution: MutableRefObject<PartialExecution | undefined>; // Currently a hack to display the newly created promotion
    openSimulationPacksCreation: (id?: ID) => void;
    openSyndicationCreation: () => void;
    isCopied: boolean;
    setCopied: (isCopied: boolean) => void;
    updatedSyndication: ID | undefined;
    setUpdatedSyndication: (syndicationId: ID | undefined) => void;
}

const ComposerStateContext = createContext<undefined | Context>(undefined);

interface Props {
    children: ReactNode;
}

export const ComposerStateContextProvider = ({ children }: Props) => {
    const { updateCheckedForPack } = useContext(MultiSelectContext)!;
    const { logEvent } = useContext(AmplitudeContext)!;

    const [isDisplayed, show, hide] = useBoolean(false);
    const [selected, setSelected] = useState<SelectedRun | undefined>(undefined);
    const [creationType, setCreationType] = useState<ExecutionType | undefined>(undefined);
    const [isCopied, setCopied] = useState(false);

    // to refresh an individual syndication after it has been updated
    const [updatedSyndication, setUpdatedSyndication] = useState<ID | undefined>(undefined);

    const execution = useRouteMatch<{executionId?: ID}>({
        path: '/(strategies|simulations)/:executionId?'
    });

    const mvtExecution = useRouteMatch<{executionId?: ID}>({
        path: '/multivariants/([0-9]+)/:executionId([0-9]+)?'
    });

    const mvt = useRouteMatch<{mvtId?: ID}>({
        path: '/multivariants/:mvtId([0-9]+)?'
    });

    const selectedExecutionId = mvtExecution?.params?.executionId ?? execution?.params?.executionId;
    const selectedMvtID = mvt?.params?.mvtId;

    // if the composer is open, hide it then open it so that newly added scripts are included
    const openComposer = useCallback(async () => {
        if (isDisplayed) {
            await hide();
            await show();
        } else {
            show();
        }
    }, [isDisplayed, hide, show]);

    const displayForExecution = useCallback((id: ID, isPackExecution?: boolean, toLiveCopy?: boolean) => {
        openComposer();
        setSelected({ id, type: 'execution', mvtId: selectedMvtID, isPackExecution, toLiveCopy });
    }, [openComposer, selectedMvtID]);

    useEffect(() => {
        if (selectedExecutionId) {
            setSelected({ id: selectedExecutionId, type: 'execution', mvtId: selectedMvtID });
        }
    }, [selectedExecutionId, selectedMvtID]);

    useEffect(() => {
        if (selectedMvtID) setSelected({ id: selectedMvtID, type: 'mvt' });
    }, [selectedMvtID])

    const displayForMVT = useCallback((id: ID, strategySettingsField?: string) => {
        openComposer();
        setSelected({ id, type: 'mvt', strategySettingsField });
    }, [openComposer]);

    const displayForPreview = useCallback(() => {
        setSelected(undefined);
        setCreationType('PREVIEW');
        openComposer();
        logEvent('ClickedEditorPreviewSettingsIcon');
    }, [openComposer, logEvent]);

    const displayForPack = useCallback(async (id: ID, toLiveCopy?: boolean) => {
        openComposer();
        setSelected({ id, type: 'strategyPack', toLiveCopy });
    }, [openComposer]);

    const displayForSubscription = useCallback(async (id: ID) => {
        openComposer();
        setSelected({ id, type: 'subscription' });
    }, [openComposer]);

    const displayForSyndication = useCallback(async (id: ID) => {
        openComposer();
        setSelected({ id, type: 'syndication' });
    }, [openComposer])

    const openSyndicationCreation = useCallback(() => {
        setSelected(undefined);
        setCreationType('SYNDICATION');
        openComposer();
    }, [openComposer]);

    const openBacktestCreation = useCallback(async () => {
        setSelected(undefined);
        setCreationType('BACKTEST');
        openComposer();
    }, [openComposer]);

    const openLiveCreation = useCallback(() => {
        setSelected(undefined);
        setCreationType('LIVE');
        openComposer();
    }, [openComposer]);

    const openSimulationPacksCreation = useCallback((id?: ID) => {
        updateCheckedForPack(id ? { [id]: true, all: true } : EMPTY_OBJ);
        setSelected(undefined);
        setCreationType('STRATEGY_PACKS');
        openComposer();
    }, [updateCheckedForPack, openComposer]);

    const lastCreatedExecution = useRef<PartialExecution>();

    const clearSelected = useCallback(() => setSelected(undefined), []);

    return (
        <ComposerStateContext.Provider
            value={{
                openComposer,
                openBacktestCreation,
                openLiveCreation,
                creationType,
                setCreationType,
                isDisplayed,
                lastCreatedExecution,
                clearSelected,
                selected,
                hide,
                displayForExecution,
                displayForMVT,
                displayForPreview,
                displayForPack,
                displayForSubscription,
                displayForSyndication,
                openSyndicationCreation,
                openSimulationPacksCreation,
                isCopied,
                setCopied,
                updatedSyndication,
                setUpdatedSyndication
            }}
        >
            {children}
        </ComposerStateContext.Provider>
    );
};

export default ComposerStateContext;
