import React, { useCallback, useContext, useState } from "react";
import classnames from "classnames";
import { useMutation, useQuery } from "@apollo/client";
import { ADMIN_GET_BOT } from "../../graphql/queries";
import { ADMIN_UPDATE_BOT_IS_BASKET, ADMIN_UPDATE_BOT_VISIBILITY } from "../../graphql/mutations";
import { AlertContext } from "../../contexts/AlertContext";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import Panel from "../../core/panels/Panel";
import LoadingRow from "../../core/table/LoadingRow";
import { parseError } from "../../helpers/errorUtils";
import { percentageValue, trimToTwoDecimals, trimZeroes } from "../../helpers/numbers";
import { Button, TextField } from "@material-ui/core";
import './admindashboard.scss';
import ReactTooltip from "react-tooltip";

function AdminDashboardBot() {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [id, setId] = useState<string>("");
    const [botId, setBotId] = useState<string>();
    const [visibility, setVisibility] = useState<"VISIBLE" | "HIDDEN" | "VISIBLE_BY_ADMIN">();

    const { data, loading } = useQuery(ADMIN_GET_BOT, {
        variables: {
            id: botId
        },
        skip: !botId
    });
    const bot = data?.adminGetBot;

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        setBotId(id);
    }, [id]);

    const [adminUpdateBotIsBasket] = useMutation(ADMIN_UPDATE_BOT_IS_BASKET, {
        onCompleted: (data) => {
            setSuccess(`Successfully updated bot id ${data?.adminUpdateBotIsBasket?.bot?.id} to${bot?.isBasket ? " not " : " "}be a basket.`);
        },
        onError: (e) => {
            const [, message] = parseError(e);
            addError(message ?? `Failed to change bot id ${bot?.id} to be a basket.`);
        }
    });

    const [adminUpdateBotVisibility] = useMutation(ADMIN_UPDATE_BOT_VISIBILITY, {
        onCompleted: (data) => {
            setSuccess(`Successfully updated bot id ${data?.adminUpdateBotVisibility?.bot?.id} visibility.`);
        },
        onError: (e) => {
            const [, message] = parseError(e);
            addError(message ?? `Failed to set bot id ${bot?.id} visiblility.`);
        }
    })

    const confirm = useConfirmationModal();

    const onAdminUpdateBotIsBasket = useCallback(async () => {
        if (!bot?.id) {
            return;
        }

        await adminUpdateBotIsBasket({ variables: {
            in: {
                id: bot.id,
                isBasket: true
            }
        }});
    }, [bot, adminUpdateBotIsBasket]);

    const onAdminUpdateBotIsBasketClick = useCallback(() => {
        if (!bot?.id) {
            return;
        }

        confirm(
            () => onAdminUpdateBotIsBasket(),
            `Do you want to set bot id ${bot.id} to be a basket?`,
            `Set bot id ${bot.id} to be a basket`
        )
    }, [confirm, bot, onAdminUpdateBotIsBasket]);

    const onAdminUpdateBotVisibility = useCallback(async () => {
        if (!bot?.id || !visibility) {
            return;
        }

        await adminUpdateBotVisibility({ variables: {
            in: {
                id: bot.id,
                visibility,
            }
        }});
    }, [bot, visibility, adminUpdateBotVisibility]);

    const onAdminUpdateBotVisibilityClick = useCallback(() => {
        if (!bot?.id || !visibility) {
            return;
        }

        confirm(
            () => onAdminUpdateBotVisibility(),
            `Do you want to set bot id ${bot.id} to be ${visibility}?`,
            `Set bot id ${bot.id} to be ${visibility}`
        )
    }, [confirm, bot, visibility, onAdminUpdateBotVisibility]);

    return (
        <div className='admin-dashboard__invoices'>
            <div className='admin-dashboard__invoices__header'>
                <form onSubmit={onSubmit}>
                    <TextField
                        variant='outlined'
                        className='admin-dashboard__invoices__header--input'
                        placeholder='Enter bot id'
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        className='admin-dashboard__invoices__header--submit-button'
                    >Submit</Button>
                </form>
            </div>
            {bot?.id && (
                <div className='admin-dashboard__invoices__header'>
                    <Button
                        variant='contained'
                        className='admin-dashboard__invoices__header--submit-button'
                        onClick={onAdminUpdateBotIsBasketClick}
                    >{bot.isBasket ? "Set Is Not Basket" :  "Set Is Basket"}</Button>
                    <div className='admin-dashboard__invoices__header--type'>
                        <Button
                            onClick={onAdminUpdateBotVisibilityClick}
                            variant='contained'
                            className='tab submit-button'
                        >Set Visibility</Button>
                        <div
                            className={classnames('tab', { 'active': visibility === 'VISIBLE' })}
                            onClick={() => setVisibility('VISIBLE')}
                            data-for="bot-visibility-visible" data-tip=''
                        >Visible</div>
                        <ReactTooltip id="bot-visibility-visible" effect='solid' className='reactTooltip white'>
                            Set bot to be visible and can be automatically set to hidden if its low performing
                        </ReactTooltip>
                        <ReactTooltip id="bot-visibility-visible-by-admin" effect='solid' className='reactTooltip white'>
                            Set bot to be always visible and cannot be automatically set to hidden if its low performing
                        </ReactTooltip>
                        <div
                            className={classnames('tab', { 'active': visibility === 'VISIBLE_BY_ADMIN' })}
                            onClick={() => setVisibility('VISIBLE_BY_ADMIN')}
                            data-for="bot-visibility-visible-by-admin" data-tip=''
                        >Visible By Admin</div>
                        <ReactTooltip id="bot-visibility-hidden" effect='solid' className='reactTooltip white'>
                            Set bot to be hidden and can be automatically set to visible if it becomes high performing
                        </ReactTooltip>
                        <div
                            className={classnames('tab', { 'active': visibility === 'HIDDEN' })}
                            onClick={() => setVisibility('HIDDEN')}
                            data-for="bot-visibility-hidden" data-tip=''
                        >Hidden</div>
                    </div>
                </div>
            )}
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Bot Id</th>
                                <th>Bot name</th>
                                <th>Creator Id</th>
                                <th>Creator name</th>
                                <th>Exchange</th>
                                <th>Currency pair</th>
                                <th>Available allocation</th>
                                <th>Max allocation</th>
                                <th>Max allocation per subscriber</th>
                                <th>Min allocation</th>
                                <th>Monthly fee</th>
                                <th>Allocation fee %</th>
                                <th>Success fee %</th>
                                <th>Active subscribers count</th>
                                <th>Access control</th>
                                <th>Visibility</th>
                                <th>Run start date</th>
                                <th>Rebalancing</th>
                                <th>MDD</th>
                                <th>Average monthly profit</th>
                                <th>Consistency score</th>
                                <th># trades</th>
                                <th>Profitable trades</th>
                                <th>Profitable months</th>
                                <th>Profit/loss %</th>
                                <th>Average win month</th>
                                <th>Average lose month</th>
                                <th>Buy&hold return</th>
                                <th>Profit factor</th>
                                <th>Risk score</th>
                                <th>Sharpe ratio</th>
                                <th>Sortino ratio</th>
                            </tr>
                        </thead>
                        <tbody>
                        {bot && (
                            <tr className='invoice-row'>
                                <td>{bot.id}</td>
                                <th>{bot.name}</th>
                                <th>{bot.owner?.id}</th>
                                <th>{bot.owner?.companyName ?? bot.owner?.nickname}</th>
                                <th>{bot.exchange}</th>
                                <th>{bot.currencyPair}</th>
                                <th>{trimZeroes(bot.syndicationSettings?.availableAllocation) ?? "0"} {bot.currencyPairDetails?.settleCurrency}</th>
                                <th>{trimZeroes(bot.syndicationSettings?.maxAllocation) ?? "0"} {bot.currencyPairDetails?.settleCurrency}</th>
                                <th>{trimZeroes(bot.syndicationSettings?.maxSubscriberAllocation ?? bot.syndicationSettings?.maxAllocation) ?? "0"} {bot.currencyPairDetails?.settleCurrency}</th>
                                <th>{trimZeroes(bot.syndicationSettings?.minAllocation) ?? "0"} {bot.currencyPairDetails?.settleCurrency}</th>
                                <th>${trimZeroes(bot.syndicationSettings?.monthlyFeeUsd) ?? "0"}</th>
                                <th>{trimZeroes(bot.syndicationSettings?.allocationFeePerc) ?? "0"}%</th>
                                <th>{trimZeroes(bot.syndicationSettings?.successFeePerc) ?? "0"}%</th>
                                <th>{bot.syndicationSettings?.activeSubscribersCount}</th>
                                <th>{bot.syndicationSettings?.accessControl}</th>
                                <th>{bot.syndicationSettings?.visibility}</th>
                                <th>{bot.startedAt ? new Date(bot.startedAt).toLocaleString() : "--"}</th>
                                <th>{bot.autoRebalance ? "On" : "Off"}</th>
                                <th>{percentageValue(bot.measurements?.maxDrawdown) ?? "--"}</th>
                                <th>{percentageValue(bot.measurements?.avgMonthlyProfit) ?? "--"}</th>
                                <th>{bot.measurements?.consistencyScore ?? "--"}</th>
                                <th>{bot.measurements?.numberOfTrades ?? 0}</th>
                                <th>{percentageValue(bot.measurements?.percentProfitableTrades) ?? "--"}</th>
                                <th>{percentageValue(bot.measurements?.percProfitableMonths) ?? "--"}</th>
                                <th>{percentageValue(bot.measurements?.profitability) ?? "--"}</th>
                                <th>{percentageValue(bot.measurements?.avgWinMonth) ?? "--"}</th>
                                <th>{percentageValue(bot.measurements?.avgLoseMonth) ?? "--"}</th>
                                <th>{percentageValue(bot.measurements?.buyHoldReturn) ?? "--"}</th>
                                <th>{trimToTwoDecimals(bot.measurements?.profitFactor) ?? "--"}</th>
                                <th>{percentageValue(bot.measurements?.riskScore) ?? "--"}</th>
                                <th>{trimToTwoDecimals(bot.measurements?.sharpeRatio) ?? "--"}</th>
                                <th>{trimToTwoDecimals(bot.measurements?.sortinoRatio) ?? "--"}</th>
                            </tr>
                        )}
                        {!botId && <tr><td>Enter a bot Id to get its details</td></tr>}
                        {loading && <LoadingRow columnCount={11} />}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
}

export default AdminDashboardBot;
