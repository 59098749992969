import React, { useCallback, useContext, useMemo } from "react";
import { ExecutionType, ID } from "../../graphql/schema";
import { AlertContext } from "../../contexts/AlertContext";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { titleCase } from "../../helpers/strings";
import { parseError } from "../../helpers/errorUtils";
import { Button } from "@material-ui/core";
import './admindashboard.scss';

interface Props {
    id: ID;
    type: ExecutionType;
    endedAt?: string;
    refetch: any;
}

const FailedExecutionRow = ({ id, type, endedAt, refetch }: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;
    
    const confirm = useConfirmationModal();

    const restartExecution = useCallback((validate: "true" | "false") => {
        try {
            fetch(`${process.env.REACT_APP_API_URL}/admin/restartExecution?id=${id}&validate=${validate}`, {
                "headers": {
                    "accept": "*/*",
                    "accept-language": "en-US,en;q=0.9",
                    "content-type": "application/json",
                    "sec-ch-ua": "\" Not A;Brand\";v=\"99\", \"Chromium\";v=\"90\", \"Google Chrome\";v=\"90\"",
                    "sec-ch-ua-mobile": "?0",
                    "sec-fetch-dest": "empty",
                    "sec-fetch-mode": "cors",
                    "sec-fetch-site": "same-site"
                },
                "referrer": window.location.origin,
                "referrerPolicy": "strict-origin-when-cross-origin",
                "method": "POST",
                "mode": "cors",
                "credentials": "include"
                })
                .then(res => res.json())
                .then(res => {
                    if (res === 'OK') {
                        refetch();
                        setSuccess(`Successfully restarted execution ${id}`);
                    }
                })
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? `Unable to restart execution ${id}. Try again.`);
        }
    }, [id, refetch, setSuccess, addError]);

    const onRestartExecutionClick = useCallback((validate: "true" | "false") => {
        confirm(() => restartExecution(validate), `Do you want to restart execution ${id}?`, 'Restart Execution');
    }, [confirm, restartExecution, id]);

    const reloadExecution = useCallback(() => {
        try {
            fetch(`${process.env.REACT_APP_API_URL}/admin/reloadExecution?id=${id}&validate=true`, {
                "headers": {
                    "accept": "*/*",
                    "accept-language": "en-US,en;q=0.9",
                    "content-type": "application/json",
                    "sec-ch-ua": "\" Not A;Brand\";v=\"99\", \"Chromium\";v=\"90\", \"Google Chrome\";v=\"90\"",
                    "sec-ch-ua-mobile": "?0",
                    "sec-fetch-dest": "empty",
                    "sec-fetch-mode": "cors",
                    "sec-fetch-site": "same-site"
                },
                "referrer": window.location.origin,
                "referrerPolicy": "strict-origin-when-cross-origin",
                "method": "POST",
                "mode": "cors",
                "credentials": "include"
                })
                .then(res => res.json())
                .then(res => {
                    if (res === 'OK') {
                        refetch();
                        setSuccess(`Successfully reloaded execution ${id}`);
                    }
                })
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? `Unable to reload execution ${id}. Try again.`);
        }
    }, [id, refetch, setSuccess, addError]);

    const onReloadExecutionClick = useCallback(() => {
        confirm(reloadExecution, `Do you want to reload execution ${id}?`, 'Reload Execution');
    }, [confirm, reloadExecution, id]);

    const date = useMemo(() => endedAt ?
        new Date(endedAt).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' }) : '--'
    , [endedAt]);

    return (
        <tr className='invoice-row'>
            <td>{id}</td>
            <td>{titleCase(type)}</td>
            <td>{date}</td>
            <td>
                <Button
                    variant='contained'
                    className='admin-dashboard__invoices__header--submit-button'
                    onClick={() => onRestartExecutionClick("true")}
                >Restart</Button>
            </td>
            <td>
                <Button
                    variant='contained'
                    className='admin-dashboard__invoices__header--submit-button'
                    onClick={() => onRestartExecutionClick("false")}
                >Restart (no allocation Check)</Button>
            </td>
            <td>
                <Button
                    variant='contained'
                    className='admin-dashboard__invoices__header--submit-button'
                    onClick={onReloadExecutionClick}
                >Reload</Button>
            </td>
        </tr>
    );
}

export default FailedExecutionRow;