import React, { useMemo } from "react";
import { ID, PublicSyndication } from "../../graphql/schema";
import { BillingSubscription, Invoice, InvoicePayment, Product } from "../../graphql/schema/payments";
import { useBoolean } from "../../helpers/hooks";
import { formatMoney } from "../../helpers/numbers";
import { titleCase } from "../../helpers/strings";
import InvoiceModal from "./InvoiceModal";
import './admindashboard.scss';

interface Props {
    invoice: Invoice;
    refetch: any;
}

const InvoiceRow = ({ invoice, refetch }: Props) => {
    const [isModalOpen, openModal, closeModal] = useBoolean(false);

    const { subscriptionId, payment, refundablePayments, syndication, paymentProvider, refundedAmount } = useMemo(() => {
        let subscriptionId: ID | undefined;
        let pendingPayment: InvoicePayment | undefined;
        let acceptedPayment: InvoicePayment | undefined;
        let refundablePayments: InvoicePayment[] = [];
        let payment: InvoicePayment | undefined;
        let syndication: PublicSyndication | undefined;
        let paymentProvider: string | undefined;
        let refundedAmount: number = 0;

        for (let item of invoice.items) {
            for (let association of item.associations) {
                if ((association as Product)?.entitlements) {
                    syndication = (association as Product)?.entitlements[0]?.syndication;
                } else {
                    subscriptionId = association.id;
                    paymentProvider = (association as BillingSubscription).paymentProvider;
                }
            }
        }

        for (let p of invoice.payments) {
            if (p.status === 'VOIDED' && !payment) {
                payment = p;
            }
            if (p.status === 'ACCEPTED' && !acceptedPayment) {
                acceptedPayment = p;
            }
            if (p.status === 'PENDING' && !pendingPayment) {
                pendingPayment = p;
            }

            // also add check for refundability here
            if (p.status === 'COMPLETED' || p.status === 'PARTIALLY_REFUNDED') {
                refundablePayments.push(p);
            }

            if (p.status === 'FULLY_REFUNDED' || p.status === 'PARTIALLY_REFUNDED') {
                for (const refund of p.refunds) {
                    refundedAmount += Number(refund.amount);
                }
            }
        }
        
        return { subscriptionId, payment: pendingPayment ?? acceptedPayment ?? payment ?? invoice.payments[0], refundablePayments, syndication, paymentProvider, refundedAmount };
    }, [invoice]);

    const source = syndication?.owner?.companyName ??
            (!syndication?.owner?.firstName && !syndication?.owner?.lastName
                ? syndication?.owner?.nickname
                : (syndication?.owner?.firstName ?? '') + ' ' + (syndication?.owner?.lastName ?? '')
            );

    const { status, total, createdAt, items, balance, pending, periodStart, periodEnd, mayCreatePayment } = invoice;

    const invoiceStatus = status === 'OPEN' ? 'Outstanding' : titleCase(status);

    const invoiceDate = new Date(createdAt).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' });

    const startTime = periodStart
        ? new Date(periodStart!).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
        : undefined;

    const endTime = periodEnd
        ? new Date(periodEnd!).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
        : undefined;

    return (<>
        <tr className='invoice-row' onClick={openModal}>
            <td>{invoice.id}</td>
            <td>{invoiceStatus}</td>
            <td>{invoiceDate}</td>
            <td>{subscriptionId}</td>
            <td>{payment?.id ?? '--'}</td>
            <td>{titleCase(payment?.status ?? '--')}</td>
            <td>{payment?.statusReason ?? '--'}</td>
            <td>{paymentProvider ?? '--'}</td>
            <td>{syndication?.name ?? '--'}</td>
            <td>{source ?? '--'}</td>
            <td>Id: {invoice.user?.id}, E-mail: {invoice.user?.email}</td>
            <td>{(startTime || endTime) ? `${startTime ?? '--'} - ${endTime ?? '--'}` : '--'}</td>
            <td>${invoiceStatus === 'Forgiven' ? '0' : formatMoney(total)}</td>
            <td>${formatMoney(pending)}</td>
            <td>${formatMoney(refundedAmount)}</td>
        </tr>
        <InvoiceModal
            invoice={invoice}
            subscriptionId={subscriptionId}
            payment={payment}
            refundablePayments={refundablePayments}
            syndication={syndication}
            source={source}
            invoiceStatus={invoiceStatus}
            invoiceDate={invoiceDate}
            startTime={startTime}
            endTime={endTime}
            total={total}
            pending={pending}
            balance={balance}
            refundedAmount={refundedAmount}
            mayCreatePayment={mayCreatePayment}
            items={items}
            paymentProvider={paymentProvider}
            isOpen={isModalOpen}
            close={closeModal}
            refetch={refetch}
        />
    </>);
}

export default InvoiceRow;