import React, { useCallback } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { useBoolean } from "../../helpers/hooks";
import { titleCase } from "../../helpers/strings";
import createImg from '../../images/new-script.svg';
import './admindashboardnavigation.scss';
import CreateInvoiceModal from "./CreateInvoiceModal";
import CreateInvoiceItemModal from "./CreateInvoiceItemModal";
import OverrideSuccessFeeModal from "./OverrideSuccessFeeModal";
import StopExecutionsModal from "./stopexecutionsmodal/StopExecutionsModal";

const CREATE_OPTIONS = ['CREATE_INVOICE', 'CREATE_INVOICE_ITEM', 'OVERRIDE_SUCCESS_FEE_PEAK_VALUE', 'STOP_EXECUTIONS'];

const NavigationCreateButton = () => {
    const [isDropdownOpen, open, close] = useBoolean(false);
    const [isCreateInvoiceModalOpen, openCreateInvoiceModal, closeCreateInvoiceModal] = useBoolean(false);
    const [isCreateInvoiceItemModalOpen, openCreateInvoiceItemModal, closeCreateInvoiceItemModal] = useBoolean(false);
    const [isOverrideSuccessFeeOpen, openOverrideSuccessFeeModal, closeOverrideSuccessFeeModal] = useBoolean(false);
    const [isStopExecutionsModalOpen, openStopExecutionsModal, closeStopExecutionsModal] = useBoolean(false);

    const onToggleDropdown = useCallback(() => {
        if (isDropdownOpen) {
            close();
        } else {
            open();
        }
    }, [isDropdownOpen, open, close]);
    
    return (<>
        <div
            className='admin-dashboard__navigation--tab create-button'
            onClick={onToggleDropdown}
        >
            <img src={createImg} alt='Create' />
            {isDropdownOpen &&
            <ClickAwayListener onClickAway={close}>
                <div className='dropdown-menu'>
                    {CREATE_OPTIONS.map((option, i) => {
                        if (option === 'CREATE_INVOICE') {
                            return (
                                <div
                                    key={i}
                                    className='dropdown-menu--option'
                                    onClick={openCreateInvoiceModal}
                                >{titleCase(option)}</div>
                            );
                        } else if (option === 'CREATE_INVOICE_ITEM') {
                            return (
                                <div
                                    key={i}
                                    className='dropdown-menu--option'
                                    onClick={openCreateInvoiceItemModal}
                                >{titleCase(option)}</div>
                            );
                        } else if (option === 'OVERRIDE_SUCCESS_FEE_PEAK_VALUE') {
                            return (
                                <div
                                    key={i}
                                    className='dropdown-menu--option'
                                    onClick={openOverrideSuccessFeeModal}
                                >{titleCase(option)}</div>
                            );
                        } else if (option === 'STOP_EXECUTIONS') {
                            return (
                                <div
                                    key={i}
                                    className='dropdown-menu--option'
                                    onClick={openStopExecutionsModal}
                                >{titleCase(option)}</div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </ClickAwayListener>
            }
        </div>
        <CreateInvoiceModal isOpen={isCreateInvoiceModalOpen} close={closeCreateInvoiceModal} />
        <CreateInvoiceItemModal isOpen={isCreateInvoiceItemModalOpen} close={closeCreateInvoiceItemModal} />
        <OverrideSuccessFeeModal isOpen={isOverrideSuccessFeeOpen} close={closeOverrideSuccessFeeModal} />
        <StopExecutionsModal isOpen={isStopExecutionsModalOpen} close={closeStopExecutionsModal} />
    </>);
}

export default NavigationCreateButton;
