import React, { useCallback, useMemo, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
    CognitoLoginState,
    getCurrentAuthenticatedUser,
    getPreferredMFA,
    signOut
} from "../../../helpers/cognitoUtils";
import Lock from "../../../core/lock/Lock";
import { CognitoComponentProps } from "../CognitoUserManager";

export const CognitoUserPage: React.FunctionComponent<CognitoComponentProps> = (
    {onSetCognitoLoginResponseCode}
) => {

    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState<string|undefined>('');
    const [mfa, setMFA] = useState('');

    useMemo(() => {
        getCurrentAuthenticatedUser(false).then((u: CognitoUser|undefined) => {
            u?.getUserAttributes((err, attr) => {
                setUsername(u?.getUsername()?? '');
                setUserEmail(attr?.find(it => it.getName() === 'email')?.getValue());
            });

            getPreferredMFA(u).then((user_mfa) => {
                setMFA(user_mfa);
            });
        })},[]);

    const resetMFA= useCallback(() =>

            onSetCognitoLoginResponseCode(CognitoLoginState.MFA_NEEDS_SETUP)

        ,[onSetCognitoLoginResponseCode]);

    return (
        <div className='login-page__wrapper'>
            <div className='login-page__wrapper__sub'>
                <div className="login-page__wrapper__title">Cognito Test Page</div>
                <Lock/>
            </div>
            <div>
                <label>Username: {username}</label>
                <label>Email: {userEmail}</label>
                <label>Preferred MFA: {mfa}</label>
                <button
                    className='button button--primary'
                    onClick={resetMFA}
                >{mfa === 'NOMFA' ? 'Setup MFA' : 'Reset MFA'}</button>
                <button
                    className='button button--primary'
                    onClick={
                        () =>
                            signOut().then(() => {
                                onSetCognitoLoginResponseCode(undefined)
                            }).catch(() =>
                                onSetCognitoLoginResponseCode(
                                    CognitoLoginState.GENERAL_FAILURE)
                            )
                    }
                >Sign Out</button>
                <button
                    className='button button--primary'
                    onClick={
                        () =>
                            onSetCognitoLoginResponseCode(
                                CognitoLoginState.CHANGE_PASSWORD)
                    }
                >Change Password</button>
                <button
                    className='button button--primary'
                    onClick={
                        () =>
                            onSetCognitoLoginResponseCode(
                                CognitoLoginState.FORGOT_PASSWORD)
                    }
                >Forgot Password</button>
            </div>
        </div>
    );
}