import React from 'react';

import Spinner from '../../core/spinner/Spinner';
import './splash.scss';

const SplashPage: React.FunctionComponent<{}> = () => (
    <div className='splash-page'>
        <div className='splash-page__wrapper'>
            <Spinner />
        </div>
    </div>
);

export default SplashPage;
