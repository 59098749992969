import React, { useCallback, useContext, useState } from "react";
import classnames from 'classnames';
import { useMutation } from "@apollo/client";
import { REVIEW_DIRECT_PAYMENT } from "../../graphql/mutations/payments";
import { AlertContext } from "../../contexts/AlertContext";
import Modal from "../../core/modal/Modal";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { DirectPaymentRequest, DirectPaymentReviewDecision } from "../../graphql/schema/payments";
import { parseError } from "../../helpers/errorUtils";
import { titleCase } from "../../helpers/strings";
import { Button, TextField } from "@material-ui/core";
import './settingsinvoice.scss';

interface Props {
    directPaymentRequest: DirectPaymentRequest;
    timeReceived: string;
    isOpen: boolean;
    close: () => void;
    refetch: any;
}

const DirectPaymentRequestModal = ({ directPaymentRequest, timeReceived, isOpen, close, refetch }: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [reviewDirectPayment] = useMutation(REVIEW_DIRECT_PAYMENT);

    const confirm = useConfirmationModal();

    const [decision, setDecision] = useState<DirectPaymentReviewDecision | undefined>(undefined);
    const [reviewerComment, setComment] = useState<string>('');
    const [account, setAccount] = useState<string>('');
    const [txId, setTxId] = useState<string>('');
    const [amountReceived, setAmountReceived] = useState<string>('');

    const onReviewDirectPayment = useCallback(async () => {
        try {
            const review = await reviewDirectPayment({ variables: {
                paymentId: directPaymentRequest.id,
                review: {
                    decision,
                    reviewerComment,
                    txInfo: {
                        account,
                        accountType: 'CRYPTOCURRENCY_WALLET',
                        txId,
                        amountReceived: amountReceived + ' USD',
                        timeReceived: new Date().toISOString()
                    }
                }
            }});

            if (review?.data?.reviewDirectPayment) {
                setSuccess('Reviewed this direct payment request.');
                refetch();
            } else {
                addError('Unable to review this direct payment request. Try again.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to review this direct payment request. Try again.');
        }
    }, [reviewDirectPayment, directPaymentRequest, decision, reviewerComment, account, txId, amountReceived, refetch, setSuccess, addError]);

    const onReviewDirectPaymentClick = useCallback(() => {
        confirm(
            onReviewDirectPayment,
            'Finish reviewing this direct payment request?',
            'Review Direct Payment Request'
        )
    }, [confirm, onReviewDirectPayment]);

    const onChangeComment = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        if (value.length <= 1000) {
            setComment(value);
        }
    }, []);

    return (
        <Modal isOpen={isOpen} onRequestClose={close}>
            <div className='settings__invoice--container'>
                <div className='settings__invoice'>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Request Status</div>
                                <div className={`settings__invoice__details__row--body status__${directPaymentRequest.status.toLowerCase()}`}>Request {titleCase(directPaymentRequest.status)}</div>
                                <div className='settings__invoice__details__row--footer'></div>
                            </div>
                            <div className='settings__invoice__details__row--right'>
                                <div className='settings__invoice__details__row--header'>Requested By</div>
                                <div className='settings__invoice__details__row--body'>Id: {directPaymentRequest.user.id}, E-mail: {directPaymentRequest.user.email}</div>
                            </div>
                        </div>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Time Received</div>
                                <div className='settings__invoice__details__row--body'>{timeReceived}</div>
                            </div>
                            <div className='settings__invoice__details__row--right'>
                                <div className='settings__invoice__details__row--header'>Request Decision</div>
                                <div className='settings__invoice__details__row--body'>{titleCase(directPaymentRequest.review?.decision ?? '--')}</div>
                            </div>
                        </div>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Account</div>
                                <div className='settings__invoice__details__row--body'>{directPaymentRequest.review?.txInfo?.account ??'--'}</div>
                                <div className='settings__invoice__details__row--footer'></div>
                            </div>
                            <div className='settings__invoice__details__row--right'>
                                <div className='settings__invoice__details__row--header'>Account Type</div>
                                <div className='settings__invoice__details__row--body'>{titleCase(directPaymentRequest.review?.txInfo?.accountType ?? '--')}</div>
                            </div>
                        </div>
                    </div>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Request Number:</div>
                            <div className='settings__invoice__details__row--body'>{directPaymentRequest.id}</div>
                        </div>
                    </div>
                    {directPaymentRequest.review?.txInfo?.txId &&
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Transaction Number:</div>
                            <div className='settings__invoice__details__row--body'>{directPaymentRequest.review?.txInfo?.txId ?? '--'}</div>
                        </div>
                    </div>
                    }
                    <div className='settings__invoice__fees'>
                        <div className='settings__invoice__fees__item total'>
                            <div className='settings__invoice__fees__item--title'>Request Amount:</div>
                            <div className='settings__invoice__fees__item--total'>{directPaymentRequest.requestAmount}</div>
                        </div>
                        <div className='settings__invoice__fees__item total'>
                            <div className='settings__invoice__fees__item--title'>Received Amount:</div>
                            <div className='settings__invoice__fees__item--total'>{directPaymentRequest.review?.txInfo?.amountReceived ?? '--'}</div>
                        </div>
                        {!directPaymentRequest.review?.txInfo && <>
                        <div className='settings__invoice--inputs'>
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Account'
                                value={account}
                                onChange={(e) => setAccount(e.target.value)}
                            />
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Transaction Id'
                                value={txId}
                                onChange={(e) => setTxId(e.target.value)}
                            />
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Amount Received'
                                value={amountReceived}
                                onChange={(e) => setAmountReceived(e.target.value)}
                            />
                        </div>
                        <TextField
                            variant='outlined'
                            multiline
                            rows={4}
                            className='settings__invoice--textarea'
                            placeholder='Comment on this review'
                            value={reviewerComment}
                            onChange={onChangeComment}
                        />
                        <div className='settings__general--buttons'>
                            <div className='admin-dashboard__invoices__header--type'>
                                <div
                                    className={classnames('tab', { 'active': decision === 'APPROVED' })}
                                    onClick={() => setDecision('APPROVED')}
                                >Approve</div>
                                <div
                                    className={classnames('tab', { 'active': decision === 'DENIED' })}
                                    onClick={() => setDecision('DENIED')}
                                >Deny</div>
                            </div>
                            <Button
                                onClick={onReviewDirectPaymentClick}
                                variant='contained'
                                className='submit-button'
                            >Review Request</Button>
                        </div>
                        </>}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default DirectPaymentRequestModal;