import gql from "graphql-tag";
import { USER_DATA } from ".";

export const PAYMENT_SOURCE_DATA = gql`
fragment PaymentSourceData on PaymentSource {
    id
    type
    status
    name
    meta
}
`;

export const PRODUCT_DATA = gql`
fragment ProductData on Product {
    id
    name
    price
    currency
    entitlements {
        id
        type
        referenceId
        ... on SyndicationEntitlement {
            syndication {
                id
                name
                exchange
                currencyPair
                currencyPairDetails {
                    id
                    exchange
                    pair
                    base
                    quote
                    settleCurrency
                    positionCurrency
                }
                syndicationSettings {
                    id
                    maxAllocation
                    minAllocation
                    description
                    monthlyFeeUsd
                    allocationFeePerc
                }
                measurements {
                    maxDrawdown
                    avgMonthlyProfit
                    percProfitableMonths
                    riskScore
                    subscriptionCount
                    subscriptionAllocation
                    profitability
                }
                startedAt
                owner {
                    firstName
                    lastName
                    companyName
                    nickname
                }
            }
        }
    }
}
`;

export const BILLING_SUBSCRIPTION_DATA = gql`
fragment BillingSubscriptionData on BillingSubscription {
    id
    user {
        id
        email
    }
    status
    statusDetails {
        creationFailure {
            reason
        }
        latestRenewalFailure {
            reason
        }

        mayUnsubscribe
        mayRevokeUnsubscription
        mayUseEntitlements
        hasPaymentPending

        hasCreationFailed
        hasLatestRenewalFailed
        willBeCancelledAtEndOfCycle
        userHasUnsubscribed
    }
    product {
        ...ProductData
    }
    entitlements {
        id
        type
        referenceId
        ... on SyndicationEntitlement {
            currentAllocation
        }
    }
    nextBillingTime
    paymentProvider
}
${PRODUCT_DATA}
`;

export const INVOICE_ITEM_DATA = gql`
fragment InvoiceItem on InvoiceItem {
    id
    itemType
    amount
    description
    associations {
        ... on Product {
            ...ProductData
        }
        ... on BillingSubscription {
            id
            startTime
            endTime
            paymentProvider
        }
    }
}
${PRODUCT_DATA}
`;

export const INVOICE_PAYMENT_DATA = gql`
fragment InvoicePayment on InvoicePayment {
    id
    paymentProvider
    status
    statusReason
    amount
    externalId
    metadata {
        ... on NashLinkPaymentMetadata {
            paymentUrl
        }

        ... on CheckoutPaymentMetadata {
            redirectLink3DS2
        }
    }
    refunds {
        id
        amount
        reason
    }
}
`;

export const INVOICE_DATA = gql`
fragment Invoice on Invoice {
    id
    user {
        id
        email
    }
    createdAt
    total
    totalPaid
    status
    currency
    payments {
        ...InvoicePayment
    }
    items {
        ...InvoiceItem
    }
    pending
    balance
    periodStart
    periodEnd
    mayCreatePayment
}
${INVOICE_ITEM_DATA}
${INVOICE_PAYMENT_DATA}
`;

export const DIRECT_PAYMENT_TX_INFO_DATA = gql`
fragment DirectPaymentTxInfo on DirectPaymentTxInfo {
    account
    accountType
    txId
    amountReceived
    timeReceived
}
`;

export const DIRECT_PAYMENT_REVIEW_DATA = gql`
fragment DirectPaymentReview on DirectPaymentReview {
    decision
    reviewerComment
    txInfo {
        ...DirectPaymentTxInfo
    }
}
${DIRECT_PAYMENT_TX_INFO_DATA}
`;

export const DIRECT_PAYMENT_REQUEST_DATA = gql`
fragment DirectPaymentRequest on DirectPaymentRequest {
    id
    status
    user {
        ...UserData
    }
    requestAmount
    review {
        ...DirectPaymentReview
    }
}
${USER_DATA}
${DIRECT_PAYMENT_REVIEW_DATA}
`;