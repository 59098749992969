import React from "react";
import Lock from "../../../core/lock/Lock";
import { Formik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import { changePassword, CognitoLoginState, signIn } from "../../../helpers/cognitoUtils";
import Spinner from "../../../core/spinner/Spinner";
import Form from "../../../core/forms/Form";
import LoginFormField from "../../../core/forms/fields/LoginFormField";
import { CognitoComponentProps } from "../CognitoUserManager";
import { PASSWORD_REGEX } from "../../../helpers/algorithmUtils";

export interface CognitoChangePasswordProps extends CognitoComponentProps {
    email: string,
    password: string | undefined
}

export const CognitoChangePassword: React.FunctionComponent<CognitoChangePasswordProps> = (
    { email, password, onSetCognitoLoginResponseCode }
) => {

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    oldPassword: password ?? '',
                    newPassword: ''
                }}
                validationSchema={yup.object().shape({
                    oldPassword: yup.string().required('Old Password is required.'),
                    newPassword: yup.string().required('New Password is required')
                                    .matches(PASSWORD_REGEX, 'Password must be 8-32 characters and include at least one letter and one number.')
                })}
                onSubmit={({ oldPassword, newPassword }, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({
                        oldPassword: true,
                        newPassword: true
                    });

                    changePassword(oldPassword, newPassword)
                        .then((user) =>
                            signIn(email, newPassword).then(signInResponse => {

                                onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS)

                            })
                        ).catch((err) => {
                        switch (err.code) {

                            case 'NotAuthorizedException':
                                actions.setFieldError('oldPassword', err.message);
                                break;

                            case 'InvalidPasswordException':
                                actions.setFieldError('newPassword', err.message);
                                break;

                            case 'TooManyRequestsException':
                                actions.setStatus({
                                    changePassword: "It seems we are having an issue right now. Please try again after a while."
                                });
                                break;

                            default:
                                /*
                                CodeDeliveryFailureException
                                InternalErrorException
                                InvalidParameterException
                                NotAuthorizedException
                                ResourceNotFoundException
                                 */
                                actions.setStatus({
                                    changePassword: "Something went wrong, please try again. If the error persists, please contact us."
                                });
                                break;

                        }

                        actions.setSubmitting(false);

                        }
                    );
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner />
                    );

                    return (
                        <Form onSubmit={submitForm}>
                            <div className='login-page__wrapper__sub'>
                                <div className="login-page__wrapper__title">Change Password</div>
                                <Lock/>
                            </div>
                            {status && status.changePassword && <span className='error'>{status.changePassword}</span>}
                            <LoginFormField label='Old Password' name='oldPassword' type='password' value={values.oldPassword}
                                            error={touched?.oldPassword ? errors?.oldPassword : undefined} />
                            <LoginFormField label='New Password' name='newPassword' type='password' value={values.newPassword}
                                            error={touched?.newPassword ? errors?.newPassword : undefined}
                                            description='Password must be 8-32 characters and include at least one letter and one number.' />
                            <button className={classnames('button button--primary button--far-gap')}>
                                Change Password
                            </button>
                        </Form>
                    );
                }}
            />
        </div>
    );
}