import React, { Dispatch, SetStateAction, useEffect, useContext } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Formik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import { History } from 'history';
import { clearAllCookies, CognitoLoginState, getCurrentAuthenticatedUser, signIn } from "../../../helpers/cognitoUtils";
import Spinner from "../../../core/spinner/Spinner";
import Lock from "../../../core/lock/Lock";
import Form from "../../../core/forms/Form";
import LoginFormField from "../../../core/forms/fields/LoginFormField";
import { CognitoComponentProps } from "../CognitoUserManager";
import { onboardUser, UserOnboardingResponse } from "../../../helpers/session";
import { UserContext } from '../../../contexts/UserContext';

export interface CognitoLoginProps extends CognitoComponentProps {
    initialStatus: string | undefined,
    onSetUser: Dispatch<SetStateAction<CognitoUser | undefined>>,
    initialEmail: string | undefined,
    onSetEmail: (email: string|undefined) => void,
    onSetPassword: (password: string|undefined) => void,
    history: History,
    usersPage?: boolean
    loginState: CognitoLoginState | undefined
}

export const CognitoLogin: React.FunctionComponent<CognitoLoginProps> = (
{ initialStatus, onSetUser, onSetCognitoLoginResponseCode, loginState,
  initialEmail, onSetEmail, onSetPassword, history, usersPage = false }
) => {
    const { isLoading, user } = useContext(UserContext)!;

    // If current user has valid tokens, bypass logging in and head directly to the main page
    useEffect(()=> {
        if (!isLoading && !usersPage) {
            getCurrentAuthenticatedUser(false).then(() => {
                    if (user) {
                        onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS);
                    } else {
                        clearAllCookies();
                        onSetCognitoLoginResponseCode(CognitoLoginState.GENERAL_FAILURE);
                    }
                }
            ).catch((err) => {
                console.warn(err);
                // Clear all stale cookies
                clearAllCookies();
            })
        }
    }, [onSetCognitoLoginResponseCode, isLoading, user, usersPage]);

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    email: initialEmail ?? '',
                    password: ''
                }}
                validationSchema={yup.object().shape({
                    email: yup.string().email('Email must be valid.').required('Email address is required.'),
                    password: yup.string().required('Password is required.')
                })}
                onSubmit={({ email, password }, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({
                        email: true,
                        password: true
                    });

                    initialStatus = undefined;

                    signIn(email, password)
                        .then(signInResponse =>{
                            switch (signInResponse.responseCode) {

                                case CognitoLoginState.INCORRECT_PASSWORD:
                                case CognitoLoginState.USER_NOT_FOUND:
                                    // Try to check if its an existing user
                                    onboardUser(email, password)
                                        .then(async (res) => {
                                            if (res.ok) {

                                                res.json().then(data => {

                                                    let tempPassword = (data as UserOnboardingResponse).tempPassword;

                                                    onSetEmail(email);
                                                    onSetPassword(tempPassword);
                                                    onSetCognitoLoginResponseCode(CognitoLoginState.MIGRATED_USER);
                                                });

                                            } else {
                                                actions.setSubmitting(false);
                                                actions.setErrors({
                                                    email: 'Email or password may be incorrect. Please try again.',
                                                    password: 'Email or password may be incorrect. Please try again.'
                                                });
                                            }
                                        });
                                    break;

                                case CognitoLoginState.GENERAL_FAILURE:
                                    actions.setSubmitting(false);
                                    actions.setErrors({
                                        email: 'Something went wrong! Please try again.'
                                    });
                                    break;

                                default:
                                    onSetEmail(email);
                                    onSetPassword(password);
                                    onSetUser(signInResponse.user);
                                    onSetCognitoLoginResponseCode(signInResponse.responseCode);
                            }
                        }).catch(() =>
                        onSetCognitoLoginResponseCode(CognitoLoginState.GENERAL_FAILURE)
                    );
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner />
                    );

                    return (
                            <Form onSubmit={submitForm}>
                                <div className='login-page__wrapper__sub'>
                                    <div className="login-page__wrapper__title">Log in</div>
                                    <Lock/>
                                </div>
                                {(initialStatus || (status && status.login)) && <span className='error'>{initialStatus??status.login}</span>}
                                {loginState === CognitoLoginState.GENERAL_FAILURE && <span className='error'>Unable to log-in. Try again later.</span>}
                                <LoginFormField autoFocus label='Email' name='email' value={values.email}
                                                error={touched?.email ? errors?.email : undefined} />
                                <LoginFormField label='Password' name='password' type='password' value={values.password}
                                                error={touched?.password ? errors?.password : undefined} />
                                <button className={classnames('button button--primary', { 'button--far-gap': touched?.password && errors?.password })}>Log In</button>
                            </Form>
                    );
                }}
            />
        </div>
    );
}