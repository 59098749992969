import * as yup from 'yup';
import Spinner from '../core/spinner/Spinner';
import { Formik } from 'formik';
import React from 'react';
import LoginFormField from '../core/forms/fields/LoginFormField';
import TextFormField from '../core/forms/fields/TextFormField';
import Form from "../core/forms/Form";
import { changePassword, signIn } from "../helpers/cognitoUtils";

interface ChangePasswordProps {
  username: string;
  currentPassword?: string;
  onSuccess: (username: string, password: string) => void;
  page?: string;
}

const ChangePassword = ({ username, currentPassword, onSuccess, page = 'login' }: ChangePasswordProps) => {

  return (
    <>
      {page === 'login' && <div className="login-page__wrapper__title">Update your password</div>}
      {page === 'home' && <section><h1>Update your password</h1></section>}
      <Formik
        initialValues={{
          currentPassword: currentPassword || '',
          newPassword: '',
          confirmNewPassword: ''
        }}
        validationSchema={yup.object().shape({
          newPassword: yup.string().required('Enter a new password'),
          currentPassword: yup.string().required('Enter the current password').min(6, 'Password did not conform with policy: Password not long enough'),
          confirmNewPassword: yup.string().required('Confirm the new password')
        })}
        onSubmit={({ currentPassword, newPassword, confirmNewPassword }, actions) => {
          if (newPassword !== confirmNewPassword) {
            actions.setStatus({ message: 'Passwords don\'t match' });
            actions.setSubmitting(false);
            return;
          }

          actions.setStatus(undefined);
            changePassword(currentPassword, newPassword)
                .then((user) => {
                    signIn(username, newPassword).then(signInResponse => {

                        onSuccess(username, newPassword)

                    })}
                ).catch((err) => {
                    switch (err.code) {

                        case 'NotAuthorizedException':
                            actions.setFieldError('currentPassword', err.message);
                            break;

                        case 'InvalidPasswordException':
                            actions.setFieldError('newPassword', err.message);
                            break;

                        case 'TooManyRequestsException':
                            actions.setStatus({
                                message: "It seems we are having an issue right now. Please try again after a while."
                            });
                            break;

                        default:
                            /*
                            CodeDeliveryFailureException
                            InternalErrorException
                            InvalidParameterException
                            NotAuthorizedException
                            ResourceNotFoundException
                             */
                            actions.setStatus({
                                message: "Something went wrong, please try again. If the error persists, please contact us."
                            });
                            break;

                    }

                    actions.setSubmitting(false);

                }
            );

        }}
        render={({ submitForm, values, status, isSubmitting }) => {
          if (isSubmitting) return (
            <Spinner />
          );

          return (
            <Form onSubmit={submitForm}>
              {status && status.message && <span className='error'>{status.message}</span>}
              {currentPassword
                  ? <input type='hidden' name='currentPassword' />
                  : <TextFormField label='Current Password' name='currentPassword' type='password' />
              }

              {page === 'home' && <TextFormField label='New Password' name='newPassword' type='password' />}
              {page === 'home' && <TextFormField label='Confirm New Password' name='confirmNewPassword' type='password'/>}
              {page === 'login' && <LoginFormField label='New Password' name='newPassword' type='password' value={values.newPassword} />}
              {page === 'login' && <LoginFormField label='Confirm New Password' name='confirmNewPassword' type='password' value={values.confirmNewPassword} />}
              <button type='submit' className='button button--primary'>Update</button>
            </Form>
          );
        }}
      />

    </>
  );
}

export default ChangePassword;