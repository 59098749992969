import React from "react";
import Lock from "../../../core/lock/Lock";
import { changePassword, CognitoLoginState, signIn, signOut } from "../../../helpers/cognitoUtils";
import { CognitoComponentProps } from "../CognitoUserManager";
import { Formik } from "formik";
import * as yup from "yup";
import Spinner from "../../../core/spinner/Spinner";
import Form from "../../../core/forms/Form";
import LoginFormField from "../../../core/forms/fields/LoginFormField";
import classnames from "classnames";
import { PASSWORD_REGEX } from "../../../helpers/algorithmUtils";

export interface MigratedUserStatusPageProps extends CognitoComponentProps {
    email: string,
    tempPassword: string
}

export const MigratedUserStatusPage: React.FunctionComponent<MigratedUserStatusPageProps> = (
    {email, tempPassword, onSetCognitoLoginResponseCode}
) => {

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    password: ''
                }}
                validationSchema={yup.object().shape({
                    password: yup.string().required('Password is required')
                                 .matches(PASSWORD_REGEX, 'Password must be 8-32 characters and include at least one letter and one number.')
                })}
                onSubmit={({password}, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({ password: true });

                    signIn(email, tempPassword)
                        .then(() => {
                            changePassword(tempPassword, password)
                                .then(() =>
                                    signIn(email, password).then(() => {

                                        onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS)

                                    })
                                ).catch((err) => {

                                    switch (err.code) {

                                        case 'InvalidPasswordException':
                                            actions.setFieldError('password', err.message);
                                            break;

                                        case 'TooManyRequestsException':
                                            actions.setStatus({
                                                changePassword: "It seems we are having an issue right now. Please try again after a while."
                                            });
                                            break;

                                        default:
                                            /*
                                            CodeDeliveryFailureException
                                            InternalErrorException
                                            InvalidParameterException
                                            NotAuthorizedException
                                            ResourceNotFoundException
                                             */
                                            actions.setStatus({
                                                changePassword: "Something went wrong, please try again. If the error persists, please contact us."
                                            });
                                            break;

                                    }

                                    signOut().finally(() => actions.setSubmitting(false))
                                }
                            );
                        });
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner/>
                    );

                    return (
                        <Form onSubmit={submitForm}>
                            <div className='login-page__wrapper__sub'>
                                <div className="login-page__wrapper__title">New Password Required</div>
                                <div>
                                    Your user account has been migrated. Please choose a new password.
                                </div>
                                <Lock/>
                            </div>
                            {status && status.changePassword && <span className='error'>{status.changePassword}</span>}
                            <LoginFormField label='NEW PASSWORD' name='password' type='password'
                                            value={values.password} error={touched?.password ? errors?.password : undefined} className='only-field'
                                            description='Password must be 8-32 characters and include at least one letter and one number.' />
                            <button className={classnames('button button--primary button--far-gap')}>
                                Change Password
                            </button>
                        </Form>
                    );
                }}
            />
        </div>
    );
}