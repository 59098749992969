import { CognitoUser } from "amazon-cognito-identity-js";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import { CognitoLoginState, completeNewPassword } from "../../../helpers/cognitoUtils";
import Spinner from "../../../core/spinner/Spinner";
import Lock from "../../../core/lock/Lock";
import Form from "../../../core/forms/Form";
import LoginFormField from "../../../core/forms/fields/LoginFormField";
import { CognitoComponentProps } from "../CognitoUserManager";
import { PASSWORD_REGEX } from "../../../helpers/algorithmUtils";

export interface CognitoNewPasswordProps extends CognitoComponentProps {
    user: CognitoUser|undefined
}

export const CognitoNewPassword: React.FunctionComponent<CognitoNewPasswordProps> = (
    {user, onSetCognitoLoginResponseCode}
) => {

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    password: ''
                }}
                validationSchema={yup.object().shape({
                    password: yup.string().required('Password is required')
                                 .matches(PASSWORD_REGEX, 'Password must be 8-32 characters and include at least one letter and one number.')
                })}
                onSubmit={({password}, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({ password: true });

                    completeNewPassword(user, password)
                        .then((user) =>
                            onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS)
                        ).catch((err) => {
                            switch (err.code) {

                                case 'InvalidPasswordException':
                                    actions.setFieldError('password', err.message);
                                    break;

                                case 'TooManyRequestsException':
                                    actions.setStatus({
                                        login: "It seems we are having an issue right now. Please try again after a while."
                                    });
                                    break;

                                default:
                                    /*
                                    CodeDeliveryFailureException
                                    InternalErrorException
                                    InvalidParameterException
                                    NotAuthorizedException
                                    ResourceNotFoundException
                                     */
                                    actions.setStatus({
                                        login: "Something went wrong, please try again. If the error persists, please contact us."
                                    });
                                    break;

                            }

                            actions.setSubmitting(false);

                        }
                    );
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner/>
                    );

                    return (
                        <Form onSubmit={submitForm}>
                            <div className='login-page__wrapper__sub'>
                                <div className="login-page__wrapper__title">New Password Required</div>
                                <Lock/>
                            </div>
                            {status && status.login && <span className='error'>{status.login}</span>}
                            <LoginFormField label='New Password' name='password' type='password'
                                            value={values.password} error={touched?.password ? errors?.password : undefined} className='only-field'
                                            description='Password must be 8-32 characters and include at least one letter and one number.' />
                            <button className={classnames('button button--primary button--far-gap')}>
                                    Change Password
                            </button>
                        </Form>
                    );
                }}
            />
        </div>
    );
}