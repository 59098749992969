import { createContext, useEffect, useState, useMemo } from 'react';
import { User } from '../graphql/schema';
import React from 'react';
import { GET_MY_DETAILS, GetMyDetailsData } from '../graphql/queries';
import { useQuery } from '@apollo/client';

interface IUserContext {
  user: User | undefined;
  isGlobalAdmin: boolean;
  isLoading: boolean;
  refetch: () => void;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const { data, loading, error, refetch } = useQuery<GetMyDetailsData>(GET_MY_DETAILS);

  useEffect(() => {
    if (data?.me) {
      const { me } = data!;
      setUser(me);
      setIsGlobalAdmin(me?.tier === 'ADMIN');
      setIsLoading(false);
    } else if (loading) {
      setIsLoading(true);
    } else if (error || !data) {
      setUser(undefined);
      setIsGlobalAdmin(false);
      setIsLoading(false);
    }
  }, [data, loading, error]);

  const ctxValue = useMemo(() => ({
      user, isLoading, isGlobalAdmin, refetch
  }), [user, isLoading, isGlobalAdmin, refetch]);

  return <UserContext.Provider value={ctxValue}>{children}</UserContext.Provider>;
}

