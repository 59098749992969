import React from "react";
import classNames from "classnames";

interface Props {
    children: React.ReactNode;
    onSubmit?: () => void;
    className?: string;
}

/**
 * Adds preventDefault() on submission of the form, this fixes login from Safari and probably other issues
 * Also sets proper class (Tho we only have on class for all forms now)
 */
const Form = ({ children, onSubmit, className }: Props) => (
    <form
        className={classNames('form form--dark', className)}
        onSubmit={(e) => {e.preventDefault(); onSubmit && onSubmit();}}
    >
        {children}
    </form>
);

export default Form;