import React from "react";
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    FetchResult,
    InMemoryCache,
    Observable,
    split,
    TypePolicy
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { getCurrentAuthenticatedUser } from "../helpers/cognitoUtils";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { LogEventFn } from "./AmplitudeContext";

let _onError: LogEventFn | undefined;
export const registerErrorHandler = (onError: LogEventFn) => _onError = onError;

const redirectToLoginIfNecessary = () => {
    if (window.location.pathname !== '/login') {
        _onError && _onError("RedirectToLogin");
        window.location.href = '/login';
    }
}

const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(err => {
                if (
                    err.extensions &&
                    err.extensions.error_code === 'UNAUTHORIZED'
                ) {
                    redirectToLoginIfNecessary();
                } else if (_onError) {
                    _onError("GraphQLError", {
                        operation: operation?.operationName,
                        errorMessage: err.message,
                        errorCode: 'extensions' in err ? err.extensions.error_code : undefined
                    });
                }
            });
        }

        if (networkError) {
            if (
                'statusCode' in networkError &&
                networkError.statusCode === 401
            ) {
                redirectToLoginIfNecessary();
            } else if (_onError) {
                _onError("NetworkError", {
                    operation: operation?.operationName,
                    errorBody: 'bodyText' in networkError ? networkError.bodyText : undefined,
                    errorMessage: networkError.name + networkError.message,
                    statusCode: 'statusCode' in networkError ? networkError.statusCode : undefined
                });
            }
        }
    }),
    split(({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind !== 'OperationDefinition' ||
                definition.operation !== 'subscription'
            );
        },
        new ApolloLink((operation, forward) => {
            return new Observable<FetchResult>(observer => {
                getCurrentAuthenticatedUser(false).finally(() => {
                    const subscription = forward!(operation).subscribe({
                        next: (result) => {
                            observer.next(result);
                        },
                        error: error => {
                            observer.error(error);
                        },
                        complete: observer.complete.bind(observer),
                    });
                    return () => {
                        if (subscription) subscription.unsubscribe();
                    };
                })
            });
        }),
    ),
    split(({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        new WebSocketLink({
            uri: `${process.env.REACT_APP_WS_URL}/subscriptions`,
            options: {
                reconnect: true,
            },
        }),
        new BatchHttpLink({
            uri: `${process.env.REACT_APP_API_URL}/graphql`,
            credentials: 'include',
        }),
    ),
]);

// Plot / group ids are not currently unique globally, only to a plot group / execution.
// Without this, Apollo will cache plot details from previously loaded charts and things will look real weird!
const CACHE_SKIP_TYPES: string[] = [
    'PlotInfo', 'PlotArrowInfo', 'PlotCharInfo', 'PlotShapeInfo', 'HLineInfo', 'FillInfo',
    'ScriptPlotDefinition', 'ScriptPlotGroup', 'ScriptPlotPalette'
];

// Helper function to merge new items into a list with connection based pagination
const paginationMerge = (existing: any, incoming: any) => {
    if (
        !existing?.edges || (existing?.pageInfo && !existing.pageInfo.hasNextPage)) {
        return incoming;
    }
    if (!incoming?.edges || (
        existing?.edges?.length && incoming?.edges?.length &&
        existing?.edges[existing.edges.length - 1]?.cursor === incoming?.edges[incoming.edges.length - 1]?.cursor
    )) {
        return existing;
    }
    return {
        edges: existing.edges.concat(incoming.edges),
        pageInfo: incoming.pageInfo
    };
}

const typePolicies: Record<string, TypePolicy> = {
    // Explicitly overwrite nested measurements object to avoid warnings / unexpected behaviour
    Execution: {
        fields: {
            measurements: {
                merge: true
            }
        }
    },

    SharedExecution: {
        keyFields: ["shareToken"],
        fields: {
            measurements: {
                merge: true
            }
        }
    },

    User: {
        fields: {
            invoices: {
                keyArgs: false,
                merge(existing, incoming) {
                    return paginationMerge(existing, incoming);
                }
            },
            subscriptions: {
                keyArgs: false,
                merge(existing, incoming) {
                    return paginationMerge(existing, incoming);
                }
            }
        }
    },

    // Configure specific cache redirects to reuse data between eg. list and single views
    Query: {
        fields: {
            execution: (_, { args, toReference }) => toReference({ __typename: 'Execution', id: args?.id })
        }
    }
};

// Inject all the skipped types so they are not cached
for (let type of CACHE_SKIP_TYPES) {
    typePolicies[type] = {
        keyFields: false
    }
}

const client = new ApolloClient({
    link,
    cache: new InMemoryCache({ typePolicies }),
    resolvers: {
        Query: {
            blank: () => null // A little trick to redirect query hooks we don't want away from the network
        }
    }
});

const TunedApolloProvider = ({children}: {children: React.ReactNode}) => (
    <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
);

export default TunedApolloProvider;
