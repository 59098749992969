import React, { useRef, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import './forms/fields/scrollablemenu/scroll.scss';

interface PanelScrollProps {
    children: React.ReactNode;
    style: any;
    onScroll?: React.UIEventHandler<any>;
    onFetchMore?: (scrollWrapper: any) => void;
}

const nop = () => {};

const PanelScroll: React.FunctionComponent<PanelScrollProps> = ({ children, style, onScroll, onFetchMore }) => {
    const scrollbarRef = useRef<any>(null);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        // to be able to fetch more under certain conditions where it cannot fetch more and there is no scrollbar
        // (ex. if the user starts / refreshes on the multivariant page and goes to the simulation page)
        if (fetching && scrollbarRef?.current && !scrollbarRef.current?.viewScrollTop) {
            setFetching(false);
        }
        // to fetch more rows if there are any and the scrollbar is not enabled
        if (scrollbarRef?.current && onFetchMore && !scrollbarRef.current?.viewScrollTop) {
            onFetchMore(scrollbarRef.current);
            setFetching(true);
        }
    }, [scrollbarRef, onFetchMore, fetching, setFetching]);

    return (
        <Scrollbars
            autoHide
            renderThumbVertical={props => <div {...props} className='thumb-vertical' />}
            renderTrackVertical={props => <div {...props} className='track-vertical' />}
            renderThumbHorizontal={props => <div {...props} className='thumb-horizontal' />}
            renderTrackHorizontal={props => <div {...props} className='track-horizontal' />}
            ref={scrollbarRef}
            children={children}
            style={style}
            onScroll={onScroll ?? nop}
        />
    );
}

export default PanelScroll;
