import gql from "graphql-tag";
import {
    User,
    Execution,
    Credential,
    ScriptRevision,
    Script,
    Multivariant,
    ID,
    ExecutionFilter,
    ExecutionSort
} from "../schema";
import {
    SCRIPT_REVISION_DATA,
    SCRIPT_MAIN_DATA,
    SCRIPT_LIST_DATA,
    EXECUTION_CONTROL_DATA,
    EXECUTION_LIST_DATA,
    EXECUTION_SUBSCRIPTION_DATA,
    USER_DATA,
    CREDENTIAL_DATA,
    EXECUTION_DETAILS_DATA,
    SCRIPTREVISION_FULL_DATA,
    SCRIPTREVISION_DATE_DATA,
    CURRENCYPAIRDETAILS_DATA,
    ASSET_DATA,
    ORDERRULES_DATA,
    ExecutionControlDataFields,
    CredentialDataFields,
    ExecutionDetailsDataFields,
    ScriptRevisionDataFields,
    ScriptMainData,
    ScriptRevisionFullData,
    ScriptListData,
    MULTIVARIANT_LIST_DATA,
    MULTIVARIANT_EXECUTIONS_DATA,
    MULTIVARIANT_DETAILS_DATA,
    MULTIVARIANT_COMPOSER_DATA,
    TRADE_DATA,
    SHARED_EXECUTION_DATA,
    TRADES_MEASUREMENT_DATA,
    ScriptWithParameters, EXECUTION_LOG_DATA, EXECUTION_PREVIEW_DATA,
    PUBLIC_SYNDICATION_DATA,
    PUBLIC_SYNDICATION_DETAILS_DATA,
    PublicSyndicationListData, PUBLIC_SYNDICATION_LIST_DATA,
    ANALYSIS
} from "../fragments";
import {PartialMultivariant} from "../mutations";

// ME (USER)

export const GET_MY_DETAILS = gql`
query GetMyDetails {
    me {
        ...UserData
    }
}
${USER_DATA}
`;

export type GetMyDetailsData = {
    me?: User;
};

// EXCHANGES

export const GET_EXCHANGE_DETAILS = gql`
query GetExchangeDetails {
    exchanges {
        id
        currencyPairs {
            ...CurrencyPairDetailsData
            orderRules {
                ...OrderRulesData
            }
        }
        isMargin
    }
}
${CURRENCYPAIRDETAILS_DATA}
${ORDERRULES_DATA}
`;

// SCRIPTS

export const GET_SCRIPTS = gql`
query GetScripts {
    scripts {
        ...ScriptMainData
    }
}
${SCRIPT_MAIN_DATA}
`;

export type GetScriptsData = {
    scripts: ScriptMainData[];
};

export type GetScriptsWithParametersData = {
    scripts: ScriptWithParameters[];
}

export type ScriptWithParametersAndRevisions = ScriptWithParameters & {
    revisions?: ScriptRevisionFullData[]
}


export const GET_SCRIPTS_WITH_PARAMS = gql`
query GetScriptsWithParams {
    scripts {
        ...ScriptMainData
        latestValid {
            ...ScriptRevisionData
        }
    }
}
${SCRIPT_MAIN_DATA}
${SCRIPT_REVISION_DATA}
`

export type GetScriptData = {
    scripts: ScriptListData[];
};

export type GetPublicScriptData = {
    publicScripts: ScriptListData[];
};

export const GET_SCRIPTS_LIST_DATA = gql`
query GetScriptsListData($query: PageQuery!, $sort: ScriptSort, $filters: [ScriptFilter], $language: ScriptLanguage) {
    scripts(query: $query, sort: $sort, filters: $filters, language: $language) {
        ...ScriptListData
        latest {
            ...ScriptRevisionDateData
        }
    }
}
${SCRIPT_LIST_DATA}
${SCRIPTREVISION_DATE_DATA}
`

export const GET_SCRIPTS_LIST_COUNT = gql`
query GetScriptsListCount($filters: [ScriptFilter], $language: ScriptLanguage) {
    scriptsCount(filters: $filters, language: $language)
}
`;

export const GET_PUBLIC_SCRIPTS_LIST_DATA = gql`
query GetPublicScriptsListData($query: PageQuery!, $sort: ScriptSort, $filters: String, $language: ScriptLanguage) {
    publicScripts(query: $query, sort: $sort, filters: $filters, language: $language) {
        ...ScriptListData
        latest {
            ...ScriptRevisionDateData
        }
        ownerInfo {
            author
            nickname
        }
    }
}
${SCRIPT_LIST_DATA}
${SCRIPTREVISION_DATE_DATA}
`;

export const GET_PUBLIC_SCRIPTS_DATA = gql`
query GetPublicScriptsListData($query: PageQuery!, $sort: ScriptSort, $filters: String, $language: ScriptLanguage) {
    publicScripts(query: $query, sort: $sort, filters: $filters, language: $language) {
        id
        name
        language
        timesCopied
        ownerInfo {
            author
            nickname
        }
        latest {
            updatedAt
        }
    }
}
`;

export const GET_PUBLIC_SCRIPTS_LIST_COUNT = gql`
query GetPublicScriptsListCount($filters: String, $language: ScriptLanguage) {
    publicScriptsCount(filters: $filters, language: $language)
}
`;

export const GET_CREDENTIALS = gql`
query GetCredentials {
    credentials {
        ...CredentialData
    }
}
${CREDENTIAL_DATA}
`;

export type GetCredentialsData = {
    credentials: Pick<Credential, CredentialDataFields>[];
};


export const GET_CREDENTIALS_WITH_FUNDS = gql`
query GetCredentialsWithFunds {
    credentials {
        ...CredentialData
        assets {
            free {
                ...AssetData
            }
            total {
                ...AssetData
                usdValue
                btcValue
            }
            updatedAt
        }
    }
}
${CREDENTIAL_DATA}
${ASSET_DATA}
`

export const GET_SCRIPTS_AND_CREDENTIALS = gql`
query GetScriptsAndCredentials($exchange: Exchange!) {
    credentials(exchange: $exchange) {
        ...CredentialData
    }
    scripts {
        ...ScriptMainData
        latest {
            ...ScriptRevisionData
        }
    }
}
${CREDENTIAL_DATA}
${SCRIPT_MAIN_DATA}
${SCRIPT_REVISION_DATA}
`

export const GET_SCRIPT_DETAILS = gql`
query GetScriptDetails($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
        ...ScriptMainData
        isUsed
        latest {
            ...ScriptRevisionFullData
        }
        preview {
            id
        }
        revisions {
            ...ScriptRevisionFullData
        }
    }
}
${SCRIPT_MAIN_DATA}
${SCRIPTREVISION_FULL_DATA}
`;

export const GET_PUBLIC_SCRIPT_DETAILS = gql`
query GetPublicScriptDetails($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
        ...ScriptMainData
        isUsed
        latest {
            ...ScriptRevisionFullData
        }
        preview {
            id
        }
        revisions {
            ...ScriptRevisionFullData
        }
    }
}
${SCRIPT_MAIN_DATA}
${SCRIPTREVISION_FULL_DATA}
`;

export const GET_SCRIPT_NOTES = gql`
query GetScriptNotes($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
        ...ScriptMainData
        notes
    }
}
${SCRIPT_MAIN_DATA}
`;

export const GET_SCRIPT_REVISIONS = gql`
query GetScriptRevisions($scriptId: ID!) {
    script(id: $scriptId) {
        revisions {
            ...ScriptRevisionFullData
        }
    }
}
${SCRIPTREVISION_FULL_DATA}
`;

export const GET_SCRIPT_REVISIONS_DATES = gql`
query GetScriptRevisionsDates($scriptId: ID!) {
    script(id: $scriptId) {
        revisions {
            ...ScriptRevisionDateData
        }
    }
}
${SCRIPTREVISION_DATE_DATA}
`;

export type GetScriptDetailsData = {
    script?: (ScriptMainData & { latest: ScriptRevisionFullData, preview?: { id: ID }, revisions: ScriptRevisionFullData[] });
}

export const GET_LATEST_SCRIPT_REVISION_ID = gql`
query GetLatestScriptRevisionId($scriptId: ID!) {
    script(id: $scriptId) {
        id
        latest {
            id
            status
        }
    }
}`;

export const GET_LATEST_PUBLIC_SCRIPT_REVISION_ID = gql`
query GetLatestPublicScriptRevisionId($scriptId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
        id
        latest {
            id
            status
        }
    }
}`;

export type GetLatestScriptRevisionIdData = {
    script?: (Pick<Script, 'id'> & { latest: Pick<ScriptRevision, 'id' | 'status'> });
}

export type GetLatestScriptRevisionIdVars = {
    scriptId: string;
};

// SCRIPT REVISIONS

export const GET_SCRIPT_REVISION_DETAILS = gql`
query GetScriptRevisionDetails($scriptId: ID!, $scriptRevisionId: ID!, $isPublic: Boolean) {
    script(id: $scriptId, isPublic: $isPublic) {
        id
        revision(id: $scriptRevisionId) {
            ...ScriptRevisionData
        }
    }
}
${SCRIPT_REVISION_DATA}
`;

export type GetScriptRevisionDetailsData = {
    script: {
        id: string;
        revision: Pick<ScriptRevision, ScriptRevisionDataFields | 'plotDefinition' | 'errors' | 'warnings' | 'infos'>;
    }
};

export type GetScriptRevisionDetailsVars = {
    scriptId: string;
    scriptRevisionId: string;
    isPublic?: boolean;
};


// EXECUTIONS

export const VALIDATE_EXECUTION = gql`
query ValidateExecution($executionId: ID!) {
    execution(id: $executionId) {
        id
        type
    }
}
`;

export const EXECUTION_PREVIEW_SUBSCRIPTION = gql`
  subscription ExecutionPreviewSubscription($executionIds: [ID]!) {
    execution(ids: $executionIds) {
        ...ExecutionPreviewData
    }
  }
${EXECUTION_PREVIEW_DATA}
`;

export const EXECUTION_SUBSCRIPTION = gql`
  subscription ExecutionSubscription($executionIds: [ID]!) {
    execution(ids: $executionIds) {
        ...ExecutionSubscriptionData
    }
  }
${EXECUTION_SUBSCRIPTION_DATA}
`;

export const GET_EXECUTION_CONTROLS = gql`
query GetExecutionControls($executionId: ID!) {
    execution(id: $executionId) {
        ...ExecutionControlData
        scriptDetails {
            id
            revisionId
            name
        }
    }
}
${EXECUTION_CONTROL_DATA}
`;

// For individual rows in a list to update themselves
export const GET_EXECUTION_ROW = gql`
query GetExecutionRow($executionId: ID!) {
    execution(id: $executionId) {
        ...ExecutionListData
    }
}
${EXECUTION_LIST_DATA}
`;

export const GET_SHARED_EXECUTION_CONTROLS = gql`
query GetExecutionControls($shareToken: ID!) {
    sharedExecution(shareToken: $shareToken) {
        shareToken
        name
        type
        currencyPair
        exchange
        candleSize
        rangeStart
        rangeEnd
        startedAt
        endedAt
    }
}
`;

export type GetExecutionControlsData = {
    execution: Pick<Execution, ExecutionControlDataFields | 'scriptDetails'>;
};

export type GetExecutionControlsVars = {
    executionId: string;
};

export const GET_EXECUTION_DETAILS_FOR_COPY = gql`
query GetExecutionDetails($executionId: ID!) {
    execution(id: $executionId) {
        ...ExecutionDetailsData
        isPack
        autoRebalance
        credential {
            id
            assets {
                free {
                    ...AssetData
                }
            }
        }
    }
}
${EXECUTION_DETAILS_DATA}
${ASSET_DATA}
`;

export const GET_EXECUTION_DETAILS = gql`
query GetExecutionDetails($executionId: ID!) {
    execution(id: $executionId) {
        ...ExecutionDetailsData
    }
}
${EXECUTION_DETAILS_DATA}
`;

export type GetExecutionDetailsData = {
    execution?: Pick<Execution, ExecutionDetailsDataFields>;
};

export type GetMultivariantDetailsData = {
    multivariant: Multivariant;
}

export const GET_EXECUTION_TRADES = gql`
query GetExecutionTrades($executionId: ID!, $num: Int!, $sortDirection: SortDirection!, $from: ID) {
    execution(id: $executionId) {
        id
        exchange
        currencyPair
        measurements {
            ...TradesMeasurementData
        }
        trades(query: { numberOfResults: $num, fromId: $from }, sortDirection: $sortDirection) {
            ...TradeData
        }
        syndicationId
    }
}
${TRADE_DATA}
${TRADES_MEASUREMENT_DATA}
`;

export const GET_EXECUTION_MEASUREMENTS = gql`
query GetExecutionMeasurements($executionId: ID!) {
    execution(id: $executionId) {
        favorite
        id
        measurements {
            ...TradesMeasurementData
        }
    }
}
${TRADES_MEASUREMENT_DATA}
`;

export const GET_EXECUTION_LOG = gql`
query GetExecutionLog($executionId: ID!, $num: Int!, $sortDirection: SortDirection!, $from: ID) {
    execution(id: $executionId) {
        id
        logs(query: { numberOfResults: $num, fromId: $from }, sortDirection: $sortDirection) {
            ...ExecutionLogData
        }
    }
}
${EXECUTION_LOG_DATA}
`;

export const GET_SHARED_EXECUTION_TRADES = gql`
query GetSharedExecutionTrades($shareToken: ID!, $num: Int!, $sortDirection: SortDirection!, $from: ID) {
    sharedExecution(shareToken: $shareToken) {
        shareToken
        exchange
        currencyPair
        measurements {
            ...TradesMeasurementData
        }
        trades(query: { numberOfResults: $num, fromId: $from }, sortDirection: $sortDirection) {
            ...TradeData
        }
    }
}
${TRADE_DATA}
${TRADES_MEASUREMENT_DATA}
`;

export const TRADES_SUBSCRIPTION = gql`
  subscription TradesSubscription($executionId: ID!, $sortDirection: SortDirection!) {
    trade(executionId: $executionId, sortDirection: $sortDirection) {
        ...TradeData
    }
  }
${TRADE_DATA}
`;

export type GetExecutionTradesVars = {
    executionId: string;
    num: number;
    sortDirection: string;
    from?: string;
};

// STRATEGIES

export const GET_STRATEGIES_IDS = gql`
query GetStrategies($query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter]) {
    strategies(query: $query, sort: $sort, filters: $filters) {
        id
        type
        exchange
        currencyPair
        isPack
    }
}
`;

export const GET_STRATEGIES_COUNT = gql`
query GetStrategiesCount($filters: [ExecutionFilter]) {
    strategiesCount(filters: $filters) {
        count
    }
}
`;

export const GET_SHARED_EXECUTION = gql`
query GetSharedExecution($shareToken: ID!) {
    sharedExecution(shareToken: $shareToken) {
        ...SharedExecutionData
    }
}
${SHARED_EXECUTION_DATA}
`;

// SIMULATIONS

export const GET_SIMULATIONS_IDS = gql`
query GetSimulations($query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter]) {
    simulations(query: $query, sort: $sort, filters: $filters) {
        id
        isPack
        type
        exchange
        currencyPair
    }
}
`;

export const GET_SIMULATIONS_COUNT = gql`
query GetSimulationsCount($filters: [ExecutionFilter]) {
    simulationsCount(filters: $filters) {
        count
    }
}
`;

// MULTIVARIANTS
export const GET_MULTIVARIANTS = gql`
query GetMultivariants($query: PageQuery!, $sort: MultivariantSort, $filters: [MultivariantFilter]) {
    multivariants(query: $query, sort: $sort, filters: $filters) {
        ...MultivariantListData
    }
}
${MULTIVARIANT_LIST_DATA}
`;

export const GET_MULTIVARIANTS_COUNT = gql`
query GetMultivariantsCount($filters: [MultivariantFilter]) {
    multivariantsCount(filters: $filters) {
        count
    }
}
`;

export const MULTIVARIANTS_PER_PAGE = 20;

export const MULTIVARIANT_SUBSCRIPTION = gql`
  subscription MultivariantSubscription($multivariantIds: [ID]!) {
    multivariant(ids: $multivariantIds) {
        ...MultivariantListData
    }
  }
${MULTIVARIANT_LIST_DATA}
`;

export const SCRIPT_REVISION_SUBSCRIPTION = gql`
subscription ScriptRevisionSubscription($id: ID!) {
    scriptRevision(scriptRevisionId: $id) {
        ...ScriptRevisionFullData
    }
}
${SCRIPTREVISION_FULL_DATA}
`;

export const CANDLE_SUBSCRIPTION = gql`
  subscription CandleSubscription($exchange: Exchange! $pair: CurrencyPair!, $candleSize: Resolution!) {
    candle(exchange: $exchange, pair: $pair, candleSize: $candleSize) {
        open
        high
        low
        volume
        close
        startTime
        endTime
    }
  }
`;

export const EXECUTION_ORDER_SUBSCRIPTION = gql`
  subscription ExecutionOrderSubscription($executionId: ID!) {
    order(executionId: $executionId) {
        placedTime
        side
        placedAmount
    }
  }
`;

export const EXECUTION_PLOT_SUBSCRIPTION = gql`
  subscription ExecutionPlotSubscription($executionId: ID!) {
    plot(executionId: $executionId) {
        plotId
        time
        value
    }
  }
`;

export const EXECUTION_COLOR_SUBSCRIPTION = gql`
  subscription ExecutionColorSubscription($executionId: ID!) {
    color(executionId: $executionId) {
        plotId
        time
        value
    }
  }
`;


// Using 2 fragments so Apollo can manage its cache
export const GET_MULTIVARIANT_DETAILS = gql`
query GetMultivariantDetails($multivariantId: ID!) {
    multivariant(id: $multivariantId) {
        ...MultivariantListData
        ...MultivariantDetailsData
    }
}
${MULTIVARIANT_LIST_DATA}
${MULTIVARIANT_DETAILS_DATA}
`;

export const GET_MULTIVARIANT_COMPOSER_DATA = gql`
query GetMultivariantComposerData($multivariantId: ID!) {
    multivariant(id: $multivariantId) {
        ...MultivariantComposerData
    }
}
${MULTIVARIANT_COMPOSER_DATA}
`;

export const GET_MULTIVARIANT_EXECUTIONS = gql`
query GetMultivariantExecutions($multivariantId: ID!, $query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter]) {
    multivariant(id: $multivariantId) {
        ...MultivariantExecutionsData
    }
}
${MULTIVARIANT_EXECUTIONS_DATA}
`;

export type GetMultivariantData = {
    multivariants: PartialMultivariant[];
};

export const CREATE_STRATEGY_FORM = gql`
query CreateStrategyForm {
    credentials {
        ...CredentialData
        assets(syncWithExchange: true) {
            free {
                ...AssetData
            }
        }
    }
    scripts {
        ...ScriptMainData
        latestValid {
            ...ScriptRevisionData
        }
    }
}
${CREDENTIAL_DATA}
${ASSET_DATA}
${SCRIPT_MAIN_DATA}
${SCRIPT_REVISION_DATA}`;

export const CREATE_SIMULATION_FORM = gql`
query CreateSimulationForm {
    scripts {
        ...ScriptMainData
        latestValid {
            ...ScriptRevisionData
        }
    }
}
${SCRIPT_MAIN_DATA}
${SCRIPT_REVISION_DATA}`;

export const GET_FILTER_PRESETS = gql`
query GetFilterPresets {
    filterPresets {
        id
        name
        filters
    }
}
`;

export const GET_MY_TAGS = gql`
query getMyTags {
    tags {
        id
        name
        color
    }
}
`;

export const GET_EXECUTION_INFO_FOR_PACKS = gql`
query GetExecutionInfoForPacks($executionId: ID!) {
    execution(id: $executionId) {
        id
        name
        scriptDetails {
            id
            name
            version
        }
        measurements {
            profitability
            maxDrawdown
            avgMonthlyProfit
            avgWinMonth
            avgLoseMonth
        }
        startedAt
        endedAt
        rangeStart
        rangeEnd
        runNumber
    }
}
`;

export const GET_PACK_EXECUTIONS = gql`
query GetPackExecutions($packId: ID!, $query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter]) {
    packExecutions(packId: $packId,query: $query, sort: $sort, filters: $filters) {
        id
        type
        exchange
        currencyPair
        packPercentage
    }
}
`;

export const GET_EXECUTIONS = gql`
query GetExecutions($query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter], $stage: ExecutionStage) {
    executions(query: $query, sort: $sort, filters: $filters, stage: $stage) {
        id
        isPack
        type
        exchange
        currencyPair
        syndicationId
    }
}
`;

export const GET_EXECUTIONS_COUNT = gql`
query GetExecutionsCount($filters: [ExecutionFilter], $stage: ExecutionStage) {
    executionsCount(filters: $filters, stage: $stage) {
        count
    }
}
`;

export const GET_SYNDICATIONS = gql`
query GetSyndications($query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter]) {
    syndications(query: $query, sort: $sort, filters: $filters) {
        id
        isPack
        type
        exchange
        currencyPair
    }
}
`;

export const GET_SYNDICATIONS_COUNT = gql`
query GetSyndicationsCount($filters: [ExecutionFilter]) {
    syndicationsCount(filters: $filters) {
        count
    }
}
`;

export const GET_GETTING_STARTED = gql`
query GetGettingStarted {
    gettingStarted {
        openedEditor
        createdExperiment
        createdMultivariant
        promotedExperiment
        promotedCandidate
        addedCredential
        launchedLive
    }
}
`;

export const GET_PUBLIC_SYNDICATION = gql`
query GetPublicSyndication($id: ID!) {
    publicSyndication(id: $id) {
        ...PublicSyndicationData
    }
}
${PUBLIC_SYNDICATION_DATA}
`;

export const PRESIGNED_UPLOAD_URL = gql`
query PresignedUploadUrl($contentType: String, $contentLength: String) {
    presignedUploadUrl(contentType: $contentType, contentLength: $contentLength) {
        url
        key
    }
}
`;

export const GET_PUBLIC_SYNDICATION_DETAILS = gql`
query GetPublicSyndicationDetails($id: ID!) {
    publicSyndication(id: $id) {
        ...PublicSyndicationDetailsData
    }
}
${PUBLIC_SYNDICATION_DETAILS_DATA}
`;

export const GET_PUBLIC_SYNDICATION_ANALYSIS = gql`
query GetPublicSyndicationDetails($id: ID!, $rangeDays: Int, $hasRange: Boolean!) {
    publicSyndication(id: $id) {
        id
        analysis @skip(if: $hasRange) {
            ...AnalysisData
        }
        analysisWithRange(rangeDays: $rangeDays) @include(if: $hasRange) {
            ...AnalysisData
        }
    }
}
${ANALYSIS}
`;

export const GET_PUBLIC_USER = gql`
query GetPublicUser($nickname: String!, $num: Int!, $from: ID, $filters: [ExecutionFilter], $sort: ExecutionSort) {
    publicUser(nickname: $nickname) {
        id
        firstName
        lastName
        tradingSince
        companyName
        nickname
        country
        description
        twitterUrl
        discordUrl
        telegramUrl
        mediumUrl
        linkedinUrl
        personalBlogUrl
        personalWebsiteUrl
        exchanges
        syndications(query: { numberOfResults: 99 }, filters: $filters, sort: $sort) {
            ...PublicSyndicationListData
        }
        syndicationsCount(filters: $filters) {
            count
        }
        profilePhotoUrl
        scripts(query: { numberOfResults: $num, fromId: $from }) {
            id
            name
            language
            favorite
            notes
            isPublic
            latestVersionNumber
            timesCopied
            latest {
                ...ScriptRevisionDateData
            }
        }
        scriptsCount
    }
}
${PUBLIC_SYNDICATION_LIST_DATA}
${SCRIPTREVISION_DATE_DATA}
`;

export type GetPublicSyndicationData = {
    syndications: PublicSyndicationListData[];
};

export type GetPublicProfileVars = {
    nickname: string;
    num: number;
    filters?: ExecutionFilter[];
    sort?: ExecutionSort;
    from?: string;
};

export const GET_PUBLIC_SYNDICATIONS = gql`
query GetPublicSyndications($query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter], $exchanges: [Exchange]) {
    publicSyndications(query: $query, sort: $sort, filters: $filters, exchanges: $exchanges) {
        id
        name
        owner {
            nickname
        }
    }
}
`;

export const GET_PUBLIC_SYNDICATIONS_LIST = gql`
query GetPublicSyndications($query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter], $exchanges: [Exchange], $rangeDays: Int) {
    publicSyndications(query: $query, sort: $sort, filters: $filters, exchanges: $exchanges) {
        ...PublicSyndicationListData
        performance(rangeDays: $rangeDays)
        analysisWithRange(rangeDays: 30) {
            ...AnalysisData
        }
        owner {
            firstName
            lastName
            companyName
            nickname
        }
    }
}
${PUBLIC_SYNDICATION_LIST_DATA}
${ANALYSIS}
`;

export const GET_PUBLIC_SYNDICATIONS_COUNT = gql`
query GetPublicSyndicationsCount($filters: [ExecutionFilter], $exchanges: [Exchange]) {
    publicSyndicationsCount(filters: $filters, exchanges: $exchanges) {
        count
    }
}
`;

export const GET_PUBLIC_PROFILES = gql`
query GetPublicUsers($query: PageQuery!, $filters: [UserFilter]) {
    publicProfiles(query: $query, filters: $filters) {
        id
        nickname
    }
}
`;

export const GET_PUBLIC_USERS_LIST = gql`
query GetPublicUsersData($query: PageQuery!, $filters: [UserFilter], $executionFilters: [ExecutionFilter], $exchanges: [Exchange]) {
    publicProfiles(query: $query, filters: $filters, executionFilters: $executionFilters, exchanges: $exchanges) {
        id
        nickname
    }
}
`;

export const GET_PUBLIC_USERS_COUNT = gql`
query GetPublicUsersCount($filters: [UserFilter], $executionFilters: [ExecutionFilter], $exchanges: [Exchange]) {
    publicProfilesCount(filters: $filters, executionFilters: $executionFilters, exchanges: $exchanges)
}
`;

export const GET_PUBLIC_USER_DETAILS = gql`
query GetPublicUser($nickname: String!, $filters: [ExecutionFilter]) {
    publicUser(nickname: $nickname) {
        id
        firstName
        lastName
        tradingSince
        companyName
        nickname
        description
        exchanges
        profilePhotoUrl
        totalAllocated
        profitableSyndicationsCount
        unprofitableSyndicationsCount
        syndicationsCount(filters: $filters) {
            count
        }
        totalSyndicationsTrades
    }
}
`;

export const GET_SYNDICATION_SUBSCRIPTIONS = gql`
query GetSyndicationSubscriptions($syndicationId: ID!, $query: PageQuery!, $sort: ExecutionSort, $filters: [ExecutionFilter]) {
    execution(id: $syndicationId) {
        id
        name
        subscriptions(query: $query, sort: $sort, filters: $filters) {
            id
            shareToken
        }
    }
}
`;

export const GET_SYNDICATION_SUBSCRIPTIONS_COUNT = gql`
query GetSyndicationSubscriptionsCount($syndicationId: ID!, $filters: [ExecutionFilter]) {
    execution(id: $syndicationId) {
        id
        subscriptionsCount(filters: $filters) {
            count
        }
    }
}
`;

export const GET_SUBSCRIPTION = gql`
query GetSubscription($executionId: ID!, $syndicationId: ID!) {
    execution(id: $syndicationId) {
        id
        subscription(id: $executionId) {
            id
            ownerNickname
            rangeStart
            rangeEnd
            startedAt
            endedAt
            allocation
            type
            subscriptionStatus
            ...SharedExecutionData
        }
    }
}
${SHARED_EXECUTION_DATA}
`;

export const BACKTEST_PROGRESS = gql`
subscription BacktestProgress($id: ID!) {
    backtestProgress(id: $id)
}
`;

export const PORTFOLIO_DAILY_LOG = gql`
query PortfolioDailyLog($range: Int) {
    portfolioDailyLog(range: $range) {
        id
        logDate
        usdValue
        btcValue
        updatedAt
    }
}
`;

export const GET_COLUMNS_PRESETS = gql`
query GetColumnsPresets {
    columnsPresets {
        id
        name
        columns
    }
}
`;

export const INVOICE_SUBSCRIPTION = gql`
subscription InvoiceSubscription {
    invoiceEvents {
        invoiceId
        status
    }
}
`;

export const PRODUCT_SUBSCRIPTION_EVENTS = gql`
subscription ProductSubscriptionEvent {
    productSubscriptionEvents {
        subscriptionId
        status
    }
}
`;

export const ANNOUNCEMENT = gql`
subscription Announcement {
    announcement {
        id
        message
        type
        createdAt
        endTime
        isActive
    }
}
`;

export const GET_ANNOUNCEMENT = gql`
query GetAnnouncement {
    announcement {
        id
        message
        type
        createdAt
        endTime
        isActive
    }
}
`;

export const GET_EXECUTION_NOTES = gql`
query GetExecutionNotes($executionId: ID!) {
    execution(id: $executionId) {
        id
        notes
    }
}
`;

export const GET_STUCK_EXECUTIONS = gql`
query GetStuckExecutions {
    stuckExecutions {
        createdAt
        exchange
        currencyPair
        type
        count
        avgSecondsWithoutUpdate
    }
}
`;

export const GET_FAILED_EXECUTIONS = gql`
query GetFailedExecutions($first: Int, $after: String) {
    failedExecutions(first: $first, after: $after) {
        edges {
            cursor
            node {
                id
                type
                endedAt
            }
        }
        pageInfo {
            hasNextPage
        }
    }
}
`;

export const ADMIN_GET_USER = gql`
query AdminGetUser($userId: ID!) {
    adminGetUser(userId: $userId) {
        ...UserData
    }
}
${USER_DATA}
`;

export const ADMIN_GET_BOT = gql`
query AdminGetBot($id: ID!) {
    adminGetBot(id: $id) {
        id
        name
        owner {
            id
            firstName
            lastName
            companyName
            nickname
        }
        exchange
        currencyPair
        currencyPairDetails {
            id
            exchange
            pair
            base
            quote
            settleCurrency
            positionCurrency
        }
        syndicationSettings {
            id
            maxAllocation
            minAllocation
            monthlyFeeUsd
            allocationFeePerc
            successFeePerc
            maxSubscriberAllocation
            availableAllocation
            activeSubscribersCount
            accessControl
            visibility
        }
        measurements {
            subscriptionCount
            subscriptionAllocation
            positionAmount
            positionAbsoluteProfit
            availableFunds
            totalValue
            buyCommission
            sellCommission
            commissionValue
            absoluteProfit
            avgBarsInTrade
            numberOfTrades
            maxDrawdown
            percentProfitableTrades
            profitability
            profitFactor
            buyHoldRatio
            buyHoldReturn
            avgTradePrice
            avgPositionPrice
            lastTick
            positionProfitLoss
            avgMonthlyProfit
            avgWinMonth
            avgLoseMonth
            percProfitableMonths
            balance
            riskScore
            percBuyHoldRatio
            totalRealizedGain
            totalRealizedLoss
            sharpeRatio
            sortinoRatio
            consistencyScore
        }
        startedAt
        leverageInfo {
            maxLeverage
            minLeverage
            avgLeverage
        }
        subscriberCount
        totalValueUsd
        candleSize
        multiCoinCurrency
        autoRebalance
        subExecutionsCount
        isBasket
    }
}
`;

export const NULL_QUERY = gql`
query NullQuery {
    blank @client
}
`;
