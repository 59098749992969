import React from 'react';
import MainNavigation from '../components/mainnavigation/MainNavigation';
import { History } from 'history';

interface ErrorPageProps {
    heading?: string;
    message?: string;
    history: History;
}

const ErrorPage: React.FunctionComponent<ErrorPageProps> = ({ heading = 'Oops!', message = 'An error occurred.', history}) => (
    <div className='error-page'>
        <section>
            <MainNavigation currentPath={''} history={history} />
        </section>
        <h1>{ heading }</h1>
        <p>{ message }</p>
    </div>
);

export default ErrorPage;
