import React, { useContext, useState } from "react";
import classnames from "classnames";
import { useMutation } from "@apollo/client";
import { ADMIN_STOP_EXECUTIONS } from "../../../graphql/mutations";
import { ExecutionStatus, ID } from "../../../graphql/schema";
import { AlertContext } from "../../../contexts/AlertContext";
import Modal from "../../../core/modal/Modal";
import useConfirmationModal from "../../../core/modal/useConfirmationModal";
import { parseError } from "../../../helpers/errorUtils";
import { titleCase } from "../../../helpers/strings";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import '../settingsinvoice.scss';

type Props = Readonly<{
  isOpen: boolean;
  close: () => void;
}>;

function StopExecutionsModal({ isOpen, close }: Props) {
  const { addError, setSuccess } = useContext(AlertContext)!;

  const [adminStopExecutions] = useMutation(ADMIN_STOP_EXECUTIONS);

  const confirm = useConfirmationModal();
  
  const [ids, setIds] = useState<ID[]>([]);
  const [stopStatus, setStopStatus] = useState<ExecutionStatus>();

  const onCreateInvoiceItem = async (numberIds: ID[]) => {
    try {
      const stopData = await adminStopExecutions({ variables: {
        in: {
          ids: numberIds,
          stopStatus
        }
      }});

      if (stopData?.data?.adminStopExecutions?.executions) {
        setSuccess(`Attempting to stop ${stopData.data.adminStopExecutions.executions.length} executions`);
      } else {
        addError('Failed to stop executions. Try again.');
      }
    } catch (e) {
      const [, message] = parseError(e);
      addError(message ?? 'Failed to stop executions. Try again.');
    }
  };

  const onCreateInvoiceItemClick = () => {
    const numberIds = ids.map((id) => id.trim()).filter((id) => Number.isInteger(Number(id)));

    if (numberIds.length) {
      confirm(
        () => onCreateInvoiceItem(numberIds),
        `Do you want to stop ${numberIds.length} executions?`,
        `Stop ${numberIds.length} executions`
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <div className='settings__invoice--container'>
        <div className='settings__invoice'>
          <div className='settings__invoice__details'>
              <div className='settings__invoice__details__row'>
                <div className='settings__invoice__details__row--left'>
                  <div className='settings__invoice__details__row--header'>Stop Executions</div>
                  <div className='settings__invoice__details__row--body'>Execution ids are comma separated. eg. 123,126,501560</div>
                </div>
              </div>
            </div>
            <div className='settings__invoice__fees'>
              <div className='settings__invoice--inputs'>
                <TextField
                  variant='outlined'
                  className='settings__invoice--input'
                  label='Execution Ids'
                  value={ids}
                  onChange={(e) => setIds(e.target.value.split(","))}
                />
                <Autocomplete
                  disableClearable={true}
                  size='small'
                  options={["STOPPING_CLOSEMARKET", "STOPPING_NEXTCLOSE", "STOPPING"]}
                  value={stopStatus}
                  onChange={(_: any, value: ExecutionStatus | null) => value && setStopStatus(value)}
                  getOptionLabel={(type) => titleCase(type)}
                  className='admin-dashboard__invoices__header--input multi-input'
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Stop status'
                    />
                  )}
                />
              </div>
              <div className='settings__general--buttons'>
                <Button
                  onClick={onCreateInvoiceItemClick}
                  variant='contained'
                  className={classnames('submit-button', { 'disabled': !ids.length })}
                >Stop Executions</Button>
              </div>
            </div>
        </div>
      </div>
    </Modal>
  );
}

export default StopExecutionsModal;