
export function titleCase(str: string) {
    if (!str) return str;
    const words = str.toString().toLowerCase().replace(/_/g, ' ').split(' ');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
}

export function copyOfName(str: string) {
    try {
        if (str.startsWith('Copy of ')) {
            return str.replace('Copy of ', 'Copy 2 of ');
        }
        const copyNo = str.match(/Copy ([0-9]+) of /);
        if (copyNo && copyNo.length > 0) {
            const newCopyNo = Number(copyNo[1]) + 1;
            return str.replace(/Copy ([0-9]+) of /, `Copy ${newCopyNo} of `)
        }
    } catch { }
    return 'Copy of ' + str;
}

export function copyToClipboard(str: string) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};