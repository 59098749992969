import {useModal} from "react-modal-hook/dist";
import React, { useCallback, useRef } from "react";
import ConfirmationModal, {OnConfirmFn} from "./ConfirmationModal";

const useConfirmationModal = () => {
    const onConfirmRef = useRef(() => {});
    const textRef = useRef('');
    const primaryActionRef = useRef('');

    const [show, hide] = useModal(() => (
        <ConfirmationModal
            isOpen
            text={textRef.current}
            primaryAction={primaryActionRef.current}
            onConfirm={onConfirmRef.current}
            onClose={hide}
        />
    ), [textRef, onConfirmRef, primaryActionRef]);

    return useCallback((onConfirm: OnConfirmFn, text: string, primaryAction: string) => {
        onConfirmRef.current = onConfirm;
        textRef.current = text;
        primaryActionRef.current = primaryAction;
        show();
    }, [show]);
};

export default useConfirmationModal;
