import React, { useEffect } from "react";
import {  useQuery } from "@apollo/client";
import { GET_FAILED_EXECUTIONS } from "../../graphql/queries";
import { ExecutionConnectionEdge } from "../../graphql/schema";
import FailedExecutionRow from "./FailedExecutionRow";
import Panel from "../../core/panels/Panel";
import LoadingRow from "../../core/table/LoadingRow";
import { Button } from "@material-ui/core";
import './admindashboard.scss';

const AdminDashboardFailedExecutions = () => {
    const { data, loading, refetch } = useQuery(GET_FAILED_EXECUTIONS);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <div className='admin-dashboard__invoices'>
            <div className='admin-dashboard__invoices__header'>
                <Button
                    variant='contained'
                    className='admin-dashboard__invoices__header--submit-button'
                    onClick={() => refetch}
                >Refresh</Button>
            </div>
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Execution Id</th>
                                <th>Type</th>
                                <th>Ended At</th>
                                <th />
                                <th />
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                        {data?.failedExecutions?.edges?.length ? data.failedExecutions.edges.map((edge: ExecutionConnectionEdge, i: number) =>
                            <FailedExecutionRow key={i} id={edge.node.id} type={edge.node.type} endedAt={edge.node.endedAt} refetch={refetch} />
                        ) : <tr><td>Unable to find any failed executions from the past 24 hours.</td></tr>}
                        {loading && <LoadingRow columnCount={5} />}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
}

export default AdminDashboardFailedExecutions;