import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import './userstatusbanner.scss';
import maintenanceImg from '../../images/maintenance.svg';
import exclamationImg from '../../images/exclamationmark.svg';
import { AnnouncementContext } from '../../contexts/AnnouncementContext';
import { UserContext } from '../../contexts/UserContext';
import AccountSettingsContext from '../../contexts/AccountSettingsContext';

interface Props {
    outstandingInvoiceCount: number;
}

const OutstandingInvoicesBanner: React.FunctionComponent<Props> = React.memo(({ outstandingInvoiceCount }) => {
    const { setPage, setInnerPage, show } = useContext(AccountSettingsContext) ?? {};

    const onUpdateClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setPage?.('billing');
        setInnerPage?.('');
        show?.();
    }, [setPage, setInnerPage, show]);

    return (
        <div className='user-status-banner user-status-banner--error'>
            <img src={exclamationImg} alt='warning' />
            <span>
                You have {outstandingInvoiceCount} outstanding invoice{outstandingInvoiceCount > 1 && 's'} due.
                Please <button onClick={onUpdateClick} className='underlined'>arrange payment</button> to avoid service disruptions.
            </span>
        </div>
    );
});

const AnnouncementBanner: React.FunctionComponent = () => {
    const { announcement, isActive } = useContext(AnnouncementContext)!;
    const { user } = useContext(UserContext)!;

    if ((!announcement || !isActive) && (user?.outstandingInvoiceCount ?? 0) > 0) {
        return <OutstandingInvoicesBanner outstandingInvoiceCount={user?.outstandingInvoiceCount!} />;
    }

    if (!announcement || !isActive) return null;

    return (
        <div className={classnames('user-status-banner user-status-banner--announcement', {
            'maintenance': announcement.type === 'MAINTENANCE',
            'outage': announcement.type === 'OUTAGE'
        })}>
            {(announcement.type === 'MAINTENANCE' || announcement.type === 'OUTAGE') &&
                <img src={announcement.type === 'MAINTENANCE' ? maintenanceImg : exclamationImg} alt='Announcement Type' />
            }
            <span>
                {announcement.message}
                {' '}
                See <a href='https://discord.gg/he2vcE42Ky' target='_blank' rel='noopener noreferrer'>#Announcements</a> for more information.
            </span>
        </div>
    );
};

export default React.memo(AnnouncementBanner);