import React, { useCallback, useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { InvoiceLineItemAssociationTypeInput } from "../../graphql/schema/payments";
import { CREATE_INVOICE } from "../../graphql/mutations/payments";
import { ID } from "../../graphql/schema";
import { AlertContext } from "../../contexts/AlertContext";
import Modal from "../../core/modal/Modal";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { parseError } from "../../helpers/errorUtils";
import { titleCase } from "../../helpers/strings";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import './settingsinvoice.scss';

export const INVOICE_LINE_ITEM_ASSOCIATION_TYPES: InvoiceLineItemAssociationTypeInput[] = ['SUBSCRIPTION', 'PRODUCT', 'EXECUTION'];

interface Props {
    isOpen: boolean;
    close: () => void;
}

const CreateInvoiceModal = ({ isOpen, close }: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const [createInvoice] = useMutation(CREATE_INVOICE);

    const confirm = useConfirmationModal();
    
    const [userId, setUserId] = useState<ID | undefined>(undefined);
    const [description, setDescription] = useState<string>('');
    const [shortDescription, setShortDescription] = useState<string>('');
    const [feeDescription, setFeeDescription] = useState<string>('');
    const [feeFixedAmount, setFeeFixedAmount] = useState<string>('');
    const [associationType, setAssociationType] = useState<InvoiceLineItemAssociationTypeInput>('SUBSCRIPTION');
    const [associationId, setAssociationId] = useState<string>('');

    const clearInputs = useCallback(() => {
        setUserId(undefined);
        setDescription('');
        setShortDescription('');
        setFeeDescription('');
        setFeeFixedAmount('');
    }, []);

    const onCreateInvoice = useCallback(async () => {
        try {
            const createData = await createInvoice({ variables: {
                in: {
                    userId,
                    definition: {
                        currency: 'USD',
                        description,
                        shortDescription,
                        items: [{
                            type: 'FEE',
                            description: feeDescription,
                            amount: {
                                fixed: {
                                    amount: feeFixedAmount
                                }
                            },
                            associations: [{
                                type: associationType,
                                id: associationId
                            }]
                        }]
                    }
                }
            }});

            if (createData?.data?.createInvoice) {
                setSuccess('Generated an invoice.');
                clearInputs();
            } else {
                addError('Unable to generated an invoice. Try again.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to generated an invoice. Try again.');
        }
    }, [createInvoice, userId, description, shortDescription, feeFixedAmount, feeDescription, associationType, associationId, clearInputs, setSuccess, addError]);

    const onCreateInvoiceClick = useCallback(() => {
        confirm(
            onCreateInvoice,
            'Generate this new invoice?',
            'Generate Invoice'
        )
    }, [confirm, onCreateInvoice]);

    return (
        <Modal isOpen={isOpen} onRequestClose={close}>
            <div className='settings__invoice--container'>
                <div className='settings__invoice'>
                <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Create Invoice</div>
                            </div>
                        </div>
                    </div>
                    <div className='settings__invoice__fees'>
                        <div className='settings__invoice--inputs'>
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='User Id'
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                            />
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Fee Fixed Amount'
                                value={feeFixedAmount}
                                onChange={(e) => setFeeFixedAmount(e.target.value)}
                            />
                            <Autocomplete
                                disableClearable={true}
                                size='small'
                                options={INVOICE_LINE_ITEM_ASSOCIATION_TYPES}
                                value={associationType}
                                onChange={(_: any, value: InvoiceLineItemAssociationTypeInput | null) => value && setAssociationType(value)}
                                getOptionLabel={(type) => titleCase(type)}
                                className='admin-dashboard__invoices__header--input multi-input'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        label='Association Type'
                                    />
                                )}
                            />
                            <TextField
                                variant='outlined'
                                className='settings__invoice--input'
                                label='Association Id'
                                value={associationId}
                                onChange={(e) => setAssociationId(e.target.value)}
                            />
                        </div>
                        <TextField
                            variant='outlined'
                            multiline
                            rows={4}
                            className='settings__invoice--textarea'
                            placeholder='Set a description on this invoice'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <TextField
                            variant='outlined'
                            multiline
                            rows={4}
                            className='settings__invoice--textarea'
                            placeholder='Set a short description on this invoice'
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                        />
                        <TextField
                            variant='outlined'
                            multiline
                            rows={4}
                            className='settings__invoice--textarea'
                            placeholder='Set a description on the fee of this invoice'
                            value={feeDescription}
                            onChange={(e) => setFeeDescription(e.target.value)}
                        />
                        <div className='settings__general--buttons'>
                            <Button
                                onClick={onCreateInvoiceClick}
                                variant='contained'
                                className='submit-button'
                            >Create Invoice</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default CreateInvoiceModal;