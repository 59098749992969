import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { createContext } from "react";
import { UserContext } from "./UserContext";
import { isDevelopment, isProduction, isStaging } from "../helpers/environment";
import { registerErrorHandler } from "./TunedApolloProvider";
const amplitude = require('amplitude-js');

export type LogEventFn = (event: string, properties?: { [key: string]: string | number | boolean | undefined }) => void;

interface Context {
    logEvent: LogEventFn;
}

/**
 * TODO: Move these to env variables
 */
const API = {
    dev: 'ae5182bd80c6ed64271deeb6d69a8950',
    staging: 'b8f651cef24e5aa33629561a28bf9b49',
    prod: 'd9d12e40ce66a72b547f3ec4f5da46a7'
}

export const AmplitudeContext = createContext<Context | undefined>(undefined);

export const AmplitudeContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { user } = useContext(UserContext)!;

    const apikey = useMemo(() => {
        if (isDevelopment) return API.dev;
        else if (isStaging) return API.staging;
        else if (isProduction) return API.prod;
        else {
            console.warn("Unknown env for amplitude, falling back to dev");
            return API.dev;
        }
    }, []);

    // We use a method here rather than logging directly to amplitude, this gives us more flexibility if we decided
    // to move to a different strategy
    const logEvent = useCallback((event: string, properties?: { [key: string]: string | number | boolean | undefined }) => {
        if (isDevelopment) console.log("Amplitude Event", event, properties);
        else amplitude.getInstance().logEvent(event, properties); // Only log for non-development, (unless you want to test locally)
    }, []);

    const email = user?.email;

    useEffect(() => {
        amplitude.getInstance().init(apikey, email);
        amplitude.getInstance().setVersionName(process.env.REACT_APP_VERSION);
        if (email) {
            logEvent("PageLoad");
            registerErrorHandler(logEvent);
        }
    }, [email, apikey, logEvent]);


    return <AmplitudeContext.Provider value={{logEvent}}>{children}</AmplitudeContext.Provider>
}