import * as React from 'react';

import './arrow.scss';

interface IArrowProps {
  direction: 'up'|'down';
}

const Arrow: React.FunctionComponent<IArrowProps> = ({ direction }) => (
  <span className={`arrow arrow--${direction}`} />
)

export default Arrow;
