import React, { useCallback, useContext, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
import { useMutation } from "@apollo/client";
import {
    ADMIN_CHANGE_SUBSCRIPTION_PAYMENT_PROVIDER,
    ADMIN_CREATE_PAYMENT,
    ADMIN_DITCH_SUBSCRIPTION,
    APPROVE_INVOICE,
    FORGIVE_INVOICE,
    MANUALLY_COMPLETE_PAYMENT,
    MANUALLY_EXPIRE_PAYMENT,
    MANUALLY_VOID_PAYMENT,
    PAY_REMAINING_INVOICE_BALANCE,
    REFUND_PAYMENT,
    REMOVE_INVOICE_LINE_ITEM,
    SET_INVOICE_EXPIRATION_TIME
} from "../../graphql/mutations/payments";
import { Decimal, ID, PublicSyndication } from "../../graphql/schema";
import { AlertContext } from "../../contexts/AlertContext";
import Modal from "../../core/modal/Modal";
import useConfirmationModal from "../../core/modal/useConfirmationModal";
import { Invoice, InvoiceItem, InvoicePayment } from "../../graphql/schema/payments";
import { formatMoney, trimZeroes } from "../../helpers/numbers";
import { parseError } from "../../helpers/errorUtils";
import { titleCase } from "../../helpers/strings";
import removeImg from '../../images/remove.svg';
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import './settingsinvoice.scss';

interface Props {
    invoice: Invoice;
    subscriptionId?: ID;
    payment?: InvoicePayment;
    refundablePayments: InvoicePayment[];
    syndication?: PublicSyndication;
    source?: string;
    invoiceStatus: string;
    invoiceDate: string;
    startTime?: string;
    endTime?: string;
    total: Decimal;
    pending?: Decimal;
    balance?: Decimal;
    refundedAmount: number;
    mayCreatePayment?: boolean;
    items: InvoiceItem[];
    paymentProvider?: string;
    isOpen: boolean;
    close: () => void;
    refetch: any;
}

const InvoiceModal = ({
    invoice, subscriptionId, payment, refundablePayments, syndication, source, invoiceStatus, invoiceDate, startTime, endTime, total, pending, balance, refundedAmount,
    mayCreatePayment, items, paymentProvider, isOpen, close, refetch
}: Props) => {
    const { addError, setSuccess } = useContext(AlertContext)!;

    const confirm = useConfirmationModal();

    const [payRemainingInvoiceBalance] = useMutation(PAY_REMAINING_INVOICE_BALANCE);
    const [manuallyCompletePayment] = useMutation(MANUALLY_COMPLETE_PAYMENT);
    const [manuallyVoidPayment] = useMutation(MANUALLY_VOID_PAYMENT);
    const [manuallyExpirePayment] = useMutation(MANUALLY_EXPIRE_PAYMENT);
    const [forgiveInvoice] = useMutation(FORGIVE_INVOICE);
    const [adminChangeSubscriptionPaymentProvider] = useMutation(ADMIN_CHANGE_SUBSCRIPTION_PAYMENT_PROVIDER);
    const [adminDitchSubscription] = useMutation(ADMIN_DITCH_SUBSCRIPTION);
    const [approveInvoice] = useMutation(APPROVE_INVOICE);
    const [refundPayment] = useMutation(REFUND_PAYMENT);
    const [removeInvoiceLineItem] = useMutation(REMOVE_INVOICE_LINE_ITEM);
    const [setInvoiceExpirationTime] = useMutation(SET_INVOICE_EXPIRATION_TIME);
    const [adminCreatePayment] = useMutation(ADMIN_CREATE_PAYMENT);

    const [refundAmount, setRefundAmount] = useState<string>('');
    const [refundablePayment, setRefundablePayment] = useState<InvoicePayment | null>(refundablePayments.length ? refundablePayments[0] : null);
    const [expirationTime, setExpirationTime] = useState<string | undefined>(undefined);
    const [providerForPayment, setProviderForPayment] = useState<'CHECKOUT_COM' | 'NASHLINK' | 'DIRECT_PAYMENT' | undefined>(undefined);

    const onAdminCreatePayment = useCallback(async () => {
        try {
            if (!providerForPayment) {
                addError('Set a payment provider before creating a payment.');
                return;
            }

            const payData = await adminCreatePayment({ variables: {
                invoiceId: invoice.id,
                paymentProvider: providerForPayment
            }});

            if (
                payData.data?.adminCreatePayment.status === 'SUCCESS' ||
                payData.data?.adminCreatePayment.status === 'COMPLETED' ||
                payData.data?.adminCreatePayment.status === 'ACCEPTED' ||
                payData.data?.adminCreatePayment.status === 'INITIAL'
            ) {
                setSuccess('Successfully paid invoice.');
            } else if (
                payData.data?.adminCreatePayment.status === 'PENDING' &&
                payData.data?.adminCreatePayment.metadata?.redirectLink3DS2
            ) {
                addError(`Invoice requires the user to redirect to a link to approve payment. Url: ${payData.data.adminCreatePayment.metadata.redirectLink}`);
            } else if (
                payData.data?.adminCreatePayment.status === 'PENDING' &&
                payData.data?.adminCreatePayment.metadata?.paymentUrl
            ) {
                addError(`Invoice requires the user to redirect to a link to pay. Url: ${payData.data.adminCreatePayment.metadata.paymentUrl}`);
            } else if (['CANCELLED', 'EXPIRED', 'DENIED', 'FAILED'].includes(payData.data?.adminCreatePayment.status)) {
                addError('Unable to pay invoice. Try again.');
            }
            refetch();
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to create payment for invoice. Try again.');
        }
    }, [adminCreatePayment, providerForPayment, invoice, setSuccess, refetch, addError]);

    const onAdminCreatePaymentClick = useCallback(() => {
        if (!providerForPayment) {
            return;
        }

        confirm(onAdminCreatePayment, `Attempt to create a payment for this user's invoice with ${titleCase(providerForPayment)}?`, `Attempt Payment With ${titleCase(providerForPayment)}`);
    }, [providerForPayment, confirm, onAdminCreatePayment]);

    const onPayRemainingInvoiceBalance = useCallback(async () => {
        try {
            if (!paymentProvider) {
                addError('Unable to pay invoice. Unable to find payment provider.');
                return;
            }

            const payData = await payRemainingInvoiceBalance({ variables: {
                invoiceId: invoice.id,
                paymentProvider: paymentProvider
            }});

            if (
                payData.data?.payRemainingInvoiceBalance.payment.status === 'SUCCESS' ||
                payData.data?.payRemainingInvoiceBalance.payment.status === 'COMPLETED' ||
                payData.data?.payRemainingInvoiceBalance.payment.status === 'ACCEPTED' ||
                payData.data?.payRemainingInvoiceBalance.payment.status === 'INITIAL'
            ) {
                setSuccess('Successfully paid invoice.');
            } else if (
                payData.data?.payRemainingInvoiceBalance.payment.status === 'PENDING' &&
                payData.data?.payRemainingInvoiceBalance.payment.metadata?.redirectLink3DS2
            ) {
                addError(`Invoice requires the user to redirect to a link to approve payment. Url: ${payData.data.payRemainingInvoiceBalance.payment.metadata.redirectLink}`);
            } else if (
                payData.data?.payRemainingInvoiceBalance.payment.status === 'PENDING' &&
                payData.data?.payRemainingInvoiceBalance.payment.metadata?.paymentUrl
            ) {
                addError(`Invoice requires the user to redirect to a link to pay. Url: ${payData.data.payRemainingInvoiceBalance.payment.metadata.paymentUrl}`);
            } else if (['CANCELLED', 'EXPIRED', 'DENIED', 'FAILED'].includes(payData.data?.payRemainingInvoiceBalance.payment.status)) {
                addError('Unable to pay invoice. Try again.');
            }
            refetch();
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to pay invoice. Try again.');
        }
    }, [payRemainingInvoiceBalance, paymentProvider, invoice, setSuccess, refetch, addError]);

    const onPayRemainingInvoiceBalanceClick = useCallback(() => {
        if ((Number(balance) - Number(pending) === 0) || !mayCreatePayment || !paymentProvider) {
            return;
        }

        confirm(onPayRemainingInvoiceBalance, `Attempt to pay this user's invoice with ${titleCase(paymentProvider)}?`, `Attempt Payment With ${titleCase(paymentProvider)}`);
    }, [balance, pending, paymentProvider, mayCreatePayment, confirm, onPayRemainingInvoiceBalance]);

    const onManuallyCompletePayment = useCallback(async () => {
        try {
            const payData = await manuallyCompletePayment({ variables: {
                invoiceId: invoice.id,
                paymentId: payment?.id,
                reason: 'admin manually complete payment'
            }});

            if (payData.data?.manuallyCompletePayment) {
                setSuccess('Successfully completed payment');
                refetch();
            } else {
                addError('Unable to manually complete payment.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to manually complete payment.');
        }
    }, [manuallyCompletePayment, invoice, payment, setSuccess, refetch, addError]);

    const onManuallyCompletePaymentClick = useCallback(() => {
        if ((payment?.status !== 'PENDING' && payment?.status !== 'ACCEPTED') || !mayCreatePayment) {
            return;
        }
        
        confirm(onManuallyCompletePayment, 'Attempt to complete this user\'s invoice?', 'Manually Complete Payment');
    }, [payment, mayCreatePayment, confirm, onManuallyCompletePayment]);

    const onManuallyVoidPayment = useCallback(async () => {
        try {
            const payData = await manuallyVoidPayment({ variables: {
                invoiceId: invoice.id,
                paymentId: payment?.id,
                reason: 'admin manually void payment'
            }});

            if (payData.data?.manuallyVoidPayment) {
                setSuccess('Successfully voided payment.');
                refetch();
            } else {
                addError('Unable to manually void payment.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to manually void payment.');
        }
    }, [manuallyVoidPayment, invoice, payment, setSuccess, refetch, addError]);

    const onManuallyVoidPaymentClick = useCallback(() => {
        if ((payment?.status !== 'PENDING' && payment?.status !== 'ACCEPTED') || !mayCreatePayment) {
            return;
        }

        confirm(onManuallyVoidPayment, 'Attempt to void this user\'s invoice payment?', 'Manually Void Payment');
    }, [payment, mayCreatePayment, confirm, onManuallyVoidPayment]);

    const onManuallyExpirePayment = useCallback(async () => {
        try {
            const payData = await manuallyExpirePayment({ variables: {
                invoiceId: invoice.id,
                paymentId: payment?.id
            }});

            if (payData.data?.manuallyExpirePayment) {
                setSuccess('Successfully expired payment.');
                refetch();
            } else {
                addError('Unable to manually expire payment.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to manually expire payment.');
        }
    }, [manuallyExpirePayment, invoice, payment, setSuccess, refetch, addError]);

    const onManuallyExpirePaymentClick = useCallback(() => {
        if (payment?.status !== 'PENDING') {
            return;
        }

        confirm(onManuallyExpirePayment, 'Attempt to expire this user\'s invoice payment?', 'Manually Expire Payment');
    }, [payment, confirm, onManuallyExpirePayment]);

    const onForgiveInvoice = useCallback(async () => {
        try {
            const payData = await forgiveInvoice({ variables: {
                invoiceId: invoice.id,
                reason: 'admin manually forgive payment'
            }});

            if (payData.data?.forgiveInvoice) {
                setSuccess('Successfully forgave payment.');
                refetch()
            } else {
                addError('Unable to manually forgive payment.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to manually forgive payment.');
        }
    }, [forgiveInvoice, invoice, setSuccess, refetch, addError]);

    const onForgiveInvoiceClick = useCallback(() => {
        if (!mayCreatePayment) {
            return;
        }

        confirm(onForgiveInvoice, 'Attempt to forgive this user\'s invoice?', 'Forgive Invoice');
    }, [mayCreatePayment, confirm, onForgiveInvoice]);

    const onChangeSubscriptionPaymentProvider = useCallback(async (paymentProvider: string) => {
        try {
            const payData = await adminChangeSubscriptionPaymentProvider({ variables: {
                userId: invoice.user.id,
                subscriptionId,
                paymentProvider
            }});

            if (payData.data?.adminChangeSubscriptionPaymentProvider) {
                setSuccess('Successfully changed payment provider.');
                refetch();
            } else {
                addError('Unable to manually change payment provider.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to manually change payment provider.');
        }
    }, [adminChangeSubscriptionPaymentProvider, invoice, subscriptionId, refetch, setSuccess, addError]);

    const onChangeSubscriptionPaymentProviderClick = useCallback((paymentProvider: string) => {
        confirm(
            () => onChangeSubscriptionPaymentProvider(paymentProvider),
            `Change this user's subscription payment provider to ${titleCase(paymentProvider)}?`,
            `Change payment provider to ${titleCase(paymentProvider)}`
        );
    }, [confirm, onChangeSubscriptionPaymentProvider]);

    const onDitchSubscription = useCallback(async () => {
        try {
            const payData = await adminDitchSubscription({ variables: {
                userId: invoice.user.id,
                subscriptionId
            }});

            if (payData.data?.adminDitchSubscription) {
                setSuccess('Successfully ditched subscription.');
                refetch();
            } else {
                addError('Unable to ditch subscription.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to ditch subscription.');
        }
    }, [adminDitchSubscription, invoice, subscriptionId, setSuccess, refetch, addError]);

    const onDitchSubscriptionClick = useCallback(() => {
        confirm(onDitchSubscription, 'Ditch this user\'s subscription?', 'Ditch Subscription');
    }, [confirm, onDitchSubscription]);

    const onApproveInvoice = useCallback(async () => {
        try {
            const approveData = await approveInvoice({ variables: {
                invoiceId: invoice.id
            }});

            if (approveData.data?.approveInvoice) {
                setSuccess('Successfully approved invoice.');
                refetch();
            } else {
                addError('Unable to approved invoice.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to approved invoice.');
        }
    }, [approveInvoice, invoice, setSuccess, refetch, addError]);

    const onApproveInvoiceClick = useCallback(() => {
        if (invoice.status !== 'PENDING_MANUAL_APPROVAL') {
            return;
        }

        confirm(onApproveInvoice, 'Approve this user\'s invoice?', 'Approve Invoice')
    }, [invoice, confirm, onApproveInvoice]);

    const onRefundPayment = useCallback(async () => {
        try {
            const refundData = await refundPayment({ variables: {
                invoiceId: invoice.id,
                paymentId: refundablePayment?.id,
                reason: 'Admin manually refund completed or partially paid payment',
                amount: refundAmount + ' USD'
            }});

            if (refundData?.data?.refundPayment) {
                setSuccess(`Successfully refunded $${refundAmount} for invoice ${invoice.id} and payment ${refundablePayment?.id}`)
            } else {
                addError('Unable to refund invoice payment.');
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? 'Unable to refund invoice payment.');
        }
    }, [refundPayment, invoice, refundablePayment, refundAmount, setSuccess, addError]);

    const canRefundPayment = useMemo(() => {
        if (
            !refundablePayment?.id ||
            isNaN(Number(refundAmount)) ||
            isNaN(Number(refundablePayment?.amount)) ||
            Number(refundAmount) > Number(refundablePayment?.amount) ||
            Number(refundAmount) <= 0
        ) {
            return false;
        } else {
            return true;
        }
    }, [refundablePayment, refundAmount]);

    const onRefundPaymentClick = useCallback(() => {
        if (!canRefundPayment) {
            return;
        }

        confirm(onRefundPayment, `Refund $${refundAmount} for invoice ${invoice.id} and payment ${refundablePayment?.id}?`, 'Refund payment');
    }, [canRefundPayment, confirm, onRefundPayment, refundAmount, invoice, refundablePayment]);

    const onRemoveInvoiceLineItem = useCallback(async (item: InvoiceItem) => {
        try {
            const remove = await removeInvoiceLineItem({ variables: { invoiceId: invoice.id, itemId: item.id }});

            if (remove?.data?.removeInvoiceLineItem) {
                setSuccess(`Successfully removed ${item.description} for invoice ${invoice.id}.`);
                refetch();
            } else {
                addError(`Unable to remove item ${item.description} for invoice ${invoice.id}.`);
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? `Unable to remove ${item.description} for invoice ${invoice.id}.`);
        }
    }, [removeInvoiceLineItem, invoice, refetch, setSuccess, addError]);

    const onRemoveInvoiceLineItemClick = useCallback((item: InvoiceItem) => {
        confirm(
            () => onRemoveInvoiceLineItem(item),
            `Remove ${item.description} for invoice ${invoice.id}?`,
            'Remove invoice item'
        );
    }, [confirm, onRemoveInvoiceLineItem, invoice]);

    const onSetInvoiceExpirationTime = useCallback(async () => {
        try {
            const expirationData = await setInvoiceExpirationTime({ variables: { invoiceId: invoice.id, expirationTime: new Date(expirationTime!).toISOString() }});

            if (expirationData?.data?.setInvoiceExpirationTime) {
                setSuccess(`Updated expiration time to ${expirationTime} for invoice ${invoice.id}.`);
                refetch();
            } else {
                addError(`Unable to update expiration time to ${expirationTime} for invoice ${invoice.id}.`);
            }
        } catch (e) {
            const [, message] = parseError(e);
            addError(message ?? `Unable to update expiration time to ${expirationTime} for invoice ${invoice.id}.`);
        }
    }, [setInvoiceExpirationTime, invoice, expirationTime, refetch, setSuccess, addError]);

    const onSetInvoiceExpirationTimeClick = useCallback(() => {
        if ((invoice.status !== 'OPEN' && invoice.status !== 'OVERDUE') || !expirationTime) {
            return;
        }

        confirm(onSetInvoiceExpirationTime, `Set expiration time to ${expirationTime} for invoice ${invoice.id}?`, 'Set Expiration Time');
    }, [confirm, onSetInvoiceExpirationTime, expirationTime, invoice]);

    return (
        <Modal isOpen={isOpen} onRequestClose={close}>
            <div className='settings__invoice--container'>
                <div className='settings__invoice'>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Invoice Date</div>
                                <div className='settings__invoice__details__row--body'>{invoiceDate}</div>
                            </div>
                            <div className='settings__invoice__details__row--right'>
                                <div className='settings__invoice__details__row--header'>Billed From</div>
                                <div className='settings__invoice__details__row--body'>
                                    {source}
                                </div>
                            </div>
                        </div>
                        <div className='settings__invoice__details__row'>
                            <div className='settings__invoice__details__row--left'>
                                <div className='settings__invoice__details__row--header'>Invoice Status</div>
                                <div className={`settings__invoice__details__row--body status__${invoiceStatus.toLowerCase()}`}>{invoiceStatus}</div>
                                <div className='settings__invoice__details__row--footer'></div>
                            </div>
                            <div className='settings__invoice__details__row--right'>
                                <div className='settings__invoice__details__row--header'>Billed To</div>
                                <div className='settings__invoice__details__row--body'>Id: {invoice.user.id}, E-mail: {invoice.user.email}</div>
                            </div>
                        </div>
                    </div>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Invoice Number:</div>
                            <div className='settings__invoice__details__row--body'>{invoice.id}</div>
                        </div>
                    </div>
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Subscription Number:</div>
                            <div className='settings__invoice__details__row--body'>{subscriptionId}</div>
                        </div>
                    </div>
                    {payment?.id &&
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Payment Number:</div>
                            <div className='settings__invoice__details__row--body'>{payment?.id}</div>
                        </div>
                    </div>
                    }
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Syndication Name:</div>
                            <div className='settings__invoice__details__row--body'>
                                {syndication?.name}
                            </div>
                        </div>
                    </div>
                    {(startTime || endTime) &&
                    <div className='settings__invoice__details'>
                        <div className='settings__invoice__details__row full'>
                            <div className='settings__invoice__details__row--header'>Billing Period:</div>
                            <div className='settings__invoice__details__row--body'>
                                {startTime ?? '--'}
                                {' - '}
                                {endTime ?? '--'}
                            </div>
                        </div>
                    </div>
                    }
                    <div className='settings__invoice__fees'>
                        {items.map((item, i) =>
                        <div className='settings__invoice__fees__item' key={i}>
                            <div className='settings__invoice__fees__item--description'>{item.description}</div>
                            <button
                                onClick={() => onRemoveInvoiceLineItemClick(item)}
                                className='remove-button'
                                data-for={`invoice-${invoice.id}-item-${item.id}-remove`} data-tip=''
                            ><img src={removeImg} alt='Remove' /></button>
                            <div className='settings__invoice__fees__item--amount'>{item.itemType === 'REFUND' && '-'}${formatMoney(item.amount)}</div>
                            <ReactTooltip id={`invoice-${invoice.id}-item-${item.id}-remove`} effect='solid' className='reactTooltip white'>
                                Removes {item.description} of ${formatMoney(item.amount)} for invoice {invoice.id}.
                            </ReactTooltip>
                        </div>
                        )}
                        {refundedAmount > 0 &&
                        <div className='settings__invoice__fees__item'>
                            <div className='settings__invoice__fees__item--description'>Refund</div>
                            <div className='settings__invoice__fees__item--amount'>- ${formatMoney(refundedAmount)}</div>
                        </div>
                        }
                        {invoiceStatus === 'Forgiven' &&
                        <div className='settings__invoice__fees__item'>
                            <div className='settings__invoice__fees__item--description'>Forgiven</div>
                            <div className='settings__invoice__fees__item--amount'>- ${formatMoney(total)}</div>
                        </div>
                        }
                        <div className='settings__invoice__fees__item total'>
                            <div className='settings__invoice__fees__item--title'>Total:</div>
                            <div className='settings__invoice__fees__item--total'>${invoiceStatus === 'Forgiven' ? '0' : formatMoney(total)}</div>
                        </div>
                        {pending && Number(pending) > 0 &&
                        <div className='settings__invoice__fees__item total'>
                            <div className='settings__invoice__fees__item--title'>Pending:</div>
                            <div className='settings__invoice__fees__item--total'>${invoiceStatus === 'Forgiven' ? '0' : formatMoney(pending)}</div>
                        </div>
                        }
                        <div className='settings__general--buttons'>
                            <Button
                                onClick={onPayRemainingInvoiceBalanceClick}
                                variant='contained'
                                className={classnames('submit-button', { 'disabled': (Number(balance) - Number(pending) === 0) || !mayCreatePayment })}
                                data-for={`invoice-${invoice.id}-pay-disabled`} data-tip=''
                            >Pay Invoice</Button>
                            {((Number(balance) - Number(pending) === 0) || !mayCreatePayment) &&
                            <ReactTooltip id={`invoice-${invoice.id}-pay-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot pay invoice if there is a pending payment or making a payment is disabled.
                            </ReactTooltip>
                            }
                            
                            <div className='admin-dashboard__invoices__header--type'>
                                <Button
                                    onClick={onAdminCreatePaymentClick}
                                    variant='contained'
                                    className={classnames('tab submit-button', { 'disabled': !providerForPayment })}
                                    data-for={`invoice-${invoice.id}-admin-create-payment-disabled`} data-tip=''
                                >Create Payment</Button>
                                <div
                                    className={classnames('tab', { 'active': providerForPayment === 'CHECKOUT_COM' })}
                                    data-for={`invoice-${invoice.id}-create-with-checkout`} data-tip=''
                                    onClick={() => setProviderForPayment('CHECKOUT_COM')}
                                >Checkout</div>
                                <ReactTooltip id={`invoice-${invoice.id}-create-with-checkout`} effect='solid' className='reactTooltip white'>
                                    Use Checkout when clicking Create Payment
                                </ReactTooltip>
                                <ReactTooltip id={`invoice-${invoice.id}-create-with-nashlink`} effect='solid' className='reactTooltip white'>
                                    Use Nashlink when clicking Create Payment
                                </ReactTooltip>
                                <div
                                    className={classnames('tab', { 'active': providerForPayment === 'NASHLINK' })}
                                    data-for={`invoice-${invoice.id}-create-with-nashlink`} data-tip=''
                                    onClick={() => setProviderForPayment('NASHLINK')}
                                >Nashlink</div>
                                <ReactTooltip id={`invoice-${invoice.id}-create-with-directpayment`} effect='solid' className='reactTooltip white'>
                                    Use Direct Payment when clicking Create Payment
                                </ReactTooltip>
                                <div
                                    className={classnames('tab', { 'active': providerForPayment === 'DIRECT_PAYMENT' })}
                                    data-for={`invoice-${invoice.id}-create-with-directpayment`} data-tip=''
                                    onClick={() => setProviderForPayment('DIRECT_PAYMENT')}
                                >Direct Payment</div>
                            </div>
                            {!providerForPayment &&
                            <ReactTooltip id={`invoice-${invoice.id}-admin-create-payment-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot create payment if a payment provider is not selected.
                            </ReactTooltip>
                            }
                            <Button
                                onClick={onManuallyCompletePaymentClick}
                                variant='contained'
                                className={classnames('submit-button', { 'disabled': (payment?.status !== 'PENDING' && payment?.status !== 'ACCEPTED') || !mayCreatePayment })}
                                data-for={`invoice-${invoice.id}-complete-disabled`} data-tip=''
                            >Complete Payment</Button>
                            {((payment?.status !== 'PENDING' && payment?.status !== 'ACCEPTED') || !mayCreatePayment) &&
                            <ReactTooltip id={`invoice-${invoice.id}-complete-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot manually complete payment if there is no payment id or payment status is not pending or accepted.
                            </ReactTooltip>
                            }
                            <Button
                                onClick={onManuallyVoidPaymentClick}
                                variant='contained'
                                className={classnames('submit-button', { 'disabled': (payment?.status !== 'PENDING' && payment?.status !== 'ACCEPTED') || !mayCreatePayment })}
                                data-for={`invoice-${invoice.id}-void-disabled`} data-tip=''
                            >Void Payment</Button>
                            {((payment?.status !== 'PENDING' && payment?.status !== 'ACCEPTED') || !mayCreatePayment) &&
                            <ReactTooltip id={`invoice-${invoice.id}-void-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot void a payment if there is no payment id or payment status is not pending or accepted.
                            </ReactTooltip>
                            }
                            <Button
                                onClick={onManuallyExpirePaymentClick}
                                variant='contained'
                                className={classnames('submit-button', { 'disabled': payment?.status !== 'PENDING'})}
                                data-for={`invoice-${invoice.id}-expire-disabled`} data-tip=''
                            >Expire Payment</Button>
                            {((payment?.status !== 'PENDING' && payment?.status !== 'ACCEPTED') || !mayCreatePayment) &&
                            <ReactTooltip id={`invoice-${invoice.id}-expire-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot expire a payment if there is no payment id or payment status is not pending.
                            </ReactTooltip>
                            }
                            <Button
                                onClick={onForgiveInvoiceClick}
                                variant='contained'
                                className={classnames('submit-button', { 'disabled': !mayCreatePayment })}
                                data-for={`invoice-${invoice.id}-forgive-disabled`} data-tip=''
                            >Forgive Invoice</Button>
                            {!mayCreatePayment &&
                            <ReactTooltip id={`invoice-${invoice.id}-forgive-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot void a payment if making a payment is disabled.
                            </ReactTooltip>
                            }
                            <Button
                                onClick={onApproveInvoiceClick}
                                variant='contained'
                                className={classnames('submit-button', { 'disabled': invoice.status !== 'PENDING_MANUAL_APPROVAL' })}
                                data-for={`invoice-${invoice.id}-approve-disabled`} data-tip=''
                            >Approve Invoice</Button>
                            {invoice.status !== 'PENDING_MANUAL_APPROVAL' &&
                            <ReactTooltip id={`invoice-${invoice.id}-approve-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot approve an invoice if it's not pending manual approval.
                            </ReactTooltip>
                            }
                            <div className='admin-dashboard__invoices__header--type'>
                                <div
                                    className={classnames('tab', { 'active': paymentProvider === 'CHECKOUT_COM' })}
                                    data-for={`invoice-${invoice.id}-checkout`} data-tip=''
                                    onClick={() => onChangeSubscriptionPaymentProviderClick('CHECKOUT_COM')}
                                >Checkout</div>
                                <ReactTooltip id={`invoice-${invoice.id}-checkout`} effect='solid' className='reactTooltip white'>
                                    Change payment provider to Checkout
                                </ReactTooltip>
                                <ReactTooltip id={`invoice-${invoice.id}-nashlink`} effect='solid' className='reactTooltip white'>
                                    Change payment provider to Nashlink
                                </ReactTooltip>
                                <div
                                    className={classnames('tab', { 'active': paymentProvider === 'NASHLINK' })}
                                    data-for={`invoice-${invoice.id}-nashlink`} data-tip=''
                                    onClick={() => onChangeSubscriptionPaymentProviderClick('NASHLINK')}
                                >Nashlink</div>
                                <ReactTooltip id={`invoice-${invoice.id}-directpayment`} effect='solid' className='reactTooltip white'>
                                    Change payment provider to Direct Payment
                                </ReactTooltip>
                                <div
                                    className={classnames('tab', { 'active': paymentProvider === 'DIRECT_PAYMENT' })}
                                    data-for={`invoice-${invoice.id}-directpayment`} data-tip=''
                                    onClick={() => onChangeSubscriptionPaymentProviderClick('DIRECT_PAYMENT')}
                                >Direct Payment</div>
                            </div>
                            <div className='settings__invoice__date-input'>
                                <TextField
                                    variant='outlined'
                                    className='settings__invoice--input'
                                    label='Expiration Date'
                                    type='date'
                                    value={expirationTime}
                                    onChange={(e) => setExpirationTime(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: <Button
                                            onClick={onSetInvoiceExpirationTimeClick}
                                            variant='contained'
                                            className={classnames('submit-button', { 'disabled': (invoice.status !== 'OPEN' && invoice.status !== 'OVERDUE') })}
                                            data-for={`invoice-${invoice.id}-expiration-disabled`} data-tip=''
                                        >Set Expiration</Button>
                                    }}
                                />
                                <ReactTooltip id={`invoice-${invoice.id}-expiration-disabled`} effect='solid' className='reactTooltip white'>
                                    Cannot update an invoice's expiration time if it's not outstanding or overdue.
                                </ReactTooltip>
                            </div>
                            {!!refundablePayments.length &&
                            <div className='settings__invoice__refundable'>
                                <Autocomplete
                                    disableClearable={true}
                                    size='small'
                                    options={refundablePayments}
                                    value={refundablePayment}
                                    onChange={(_: any, value: InvoicePayment | null) => setRefundablePayment(value)}
                                    getOptionLabel={(payment) => payment.id}
                                    className='admin-dashboard__invoices__header--input multi-input'
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            label='Refundable Invoice Payment'
                                        />
                                    )}
                                />
                                <TextField
                                    variant='outlined'
                                    className='settings__invoice--input'
                                    label='Refund Payment'
                                    value={refundAmount}
                                    onChange={(e) => setRefundAmount(e.target.value)}
                                    InputProps={{
                                        startAdornment: <div>$</div>,
                                        endAdornment: <Button
                                            onClick={onRefundPaymentClick}
                                            variant='contained'
                                            className={classnames('submit-button', { 'disabled': !canRefundPayment })}
                                            data-for={`invoice-${invoice.id}-refund-disabled`} data-tip=''
                                        >Refund</Button>
                                    }}
                                />
                                {refundablePayment && <>
                                <div>
                                    Max refundable amount: ${trimZeroes(refundablePayment.amount)}
                                </div>
                                <div>
                                    Refundable Payment Status: {titleCase(refundablePayment.status)}
                                </div>
                                </>}
                            </div>
                            }
                            {!canRefundPayment &&
                            <ReactTooltip id={`invoice-${invoice.id}-refund-disabled`} effect='solid' className='reactTooltip white'>
                                Cannot refund an invoice payment if it's not completed or partial refunded and is not refundable.
                            </ReactTooltip>
                            }
                            <Button
                                onClick={onDitchSubscriptionClick}
                                variant='contained'
                                className='submit-button warning'
                            >Ditch Subscription</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default InvoiceModal;