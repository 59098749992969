import React, { ReactNode, useCallback, useMemo, useState } from "react";
import Lock from "../../../core/lock/Lock";
import { Formik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import { CognitoLoginState, forgotPassword, forgotPasswordSubmit, signIn } from "../../../helpers/cognitoUtils";
import Spinner from "../../../core/spinner/Spinner";
import Form from "../../../core/forms/Form";
import LoginFormField from "../../../core/forms/fields/LoginFormField";
import { CognitoComponentProps } from "../CognitoUserManager";
import goBackImg from "../../../images/go-back.svg";
import dropdownImg from '../../../images/bright-dropdown-arrow.svg';
import { PASSWORD_REGEX } from "../../../helpers/algorithmUtils";
import { LogEventFn } from '../../../contexts/AmplitudeContext';

interface CognitoForgotPasswordSendCodeProps extends CognitoComponentProps {
    onSubmitCodeSuccess: (email: string) => void,
    logEvent: LogEventFn
}

const CognitoForgotPasswordSendCode: React.FunctionComponent<CognitoForgotPasswordSendCodeProps> = (
    { onSubmitCodeSuccess, onSetCognitoLoginResponseCode, logEvent }
) => {

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={yup.object().shape({
                    email: yup.string().email('Email must be valid.').required('Email address is required.')
                })}
                onSubmit={({email}, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({ email: true });

                    forgotPassword(email)
                        .then(() => {
                            onSubmitCodeSuccess(email);
                            logEvent('PasswordResetInitiated');
                        }).catch((err) => {
                            actions.setSubmitting(false);
                            if (err.code === 'UserNotFoundException') {
                                actions.setStatus({
                                    login: 'Email not registered'
                                });
                            } else {
                                actions.setStatus({
                                    login: err.message
                                });
                            }
                        });
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner/>
                    );

                    return (
                        <Form onSubmit={submitForm}>
                            <div className='login-page__wrapper__sub'>
                                <div className="login-page__wrapper__title">Reset Password</div>
                                <Lock/>
                            </div>
                            {status && status.login && <span className='error'>{status.login}</span>}
                            <LoginFormField autoFocus label='Enter Email' name='email' value={values.email}
                                            error={touched?.email ? errors?.email : undefined} className='only-field' />
                            <button
                                className={classnames('button button--primary', {'button--far-gap': errors?.email})}>Send
                                Code
                            </button>
                            <div className='user-access'>
                                     <span
                                         onClick={() => onSetCognitoLoginResponseCode(CognitoLoginState.LOG_IN)}
                                         className='access-button back-button'>
                                         <img src={goBackImg} alt='Go Back' className='desktop-back-icon' />
                                         <img src={dropdownImg} alt='Go Back' className='mobile-back-icon' />
                                         Go back
                                      </span>
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    );
}


interface CognitoForgotPasswordEnterCodeProps extends CognitoComponentProps {
    userEmail: string,
    onBackCall: () => void,
    logEvent: LogEventFn
}

const CognitoForgotPasswordEnterCode: React.FunctionComponent<CognitoForgotPasswordEnterCodeProps> = (
    { userEmail, onBackCall, onSetCognitoLoginResponseCode, logEvent }
) => {

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    code: '',
                    newPassword: ''
                }}
                validationSchema={yup.object().shape({
                    code: yup.string().required('Code is a required field.'),
                    newPassword: yup.string().required('Password is a required field.')
                                 .matches(PASSWORD_REGEX, 'Password must be 8-32 characters and include at least one letter and one number.')
                })}
                onSubmit={({code, newPassword}, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({
                        code: true,
                        newPassword: true
                    })

                    forgotPasswordSubmit(userEmail, code.trim(), newPassword)
                        .then((user) => {
                                signIn(userEmail, newPassword).then(signInResponse => {
                                    logEvent('PasswordResetSuccess');
                                    onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS);
                                })
                            }
                        ).catch((err) => {

                            switch (err.code) {

                                case 'CodeDeliveryFailureException':
                                case 'CodeMismatchException':
                                case 'ExpiredCodeException':
                                    actions.setFieldError('code', err.message);
                                    break;

                                case 'InvalidPasswordException':
                                    actions.setFieldError('newPassword', err.message);
                                    break;

                                case 'UserNotFoundException':
                                case 'UserNotConfirmedException':
                                case 'NotAuthorizedException':
                                    actions.setStatus({
                                        forgotPasswordStatus: err.message
                                    });
                                    break;

                                case 'TooManyRequestsException':
                                    actions.setStatus({
                                        forgotPasswordStatus: "It seems we are having an issue right now. Please try again after a while."
                                    });
                                    break;

                                default:
                                    /*
                                    CodeDeliveryFailureException
                                    InternalErrorException
                                    InvalidParameterException
                                    NotAuthorizedException
                                    ResourceNotFoundException
                                     */
                                    actions.setStatus({
                                        forgotPasswordStatus: "Something went wrong, please try again. If the error persists, please contact us."
                                    });
                                    break;

                            }

                            logEvent('PasswordResetFailed');
                            actions.setSubmitting(false);

                        }
                    );
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner/>
                    );

                    return (
                        <Form onSubmit={submitForm}>
                            <div className='login-page__wrapper__sub'>
                                <div className="login-page__wrapper__title">Reset Password</div>
                                <Lock/>
                            </div>
                            {status && status.forgotPasswordStatus && <span className='error'>{status.forgotPasswordStatus}</span>}
                            <LoginFormField label='Enter Code' name='code' value={values.code}
                                            error={touched?.code ? errors?.code : undefined} />
                            <LoginFormField label='New Password' name='newPassword' type='password' autoComplete='new-password'
                                            value={values.newPassword} error={touched?.newPassword ? errors?.newPassword : undefined}
                                            description='Password must be 8-32 characters and include at least one letter and one number.' />
                            <button className={classnames('button button--primary button--far-gap')}>
                                Change Password
                            </button>
                            <div className='user-access'>
                                     <span
                                         onClick={onBackCall}
                                         className='access-button back-button'>
                                         <img src={goBackImg} alt='Go Back' className='desktop-back-icon' />
                                         <img src={dropdownImg} alt='Go Back' className='mobile-back-icon' />
                                         Go back
                                      </span>
                            </div>




                        </Form>
                    );
                }}
            />
        </div>
    );
}

export enum ForgotPasswordState {
    SEND_CODE,
    ENTER_CODE
}

export interface CognitoForgotPasswordProps extends CognitoComponentProps {
    email: string|undefined,
    logEvent: LogEventFn
}

export const CognitoForgotPassword: React.FunctionComponent<CognitoForgotPasswordProps> = (
    { onSetCognitoLoginResponseCode, email, logEvent }
) => {

    const [userEmail, setUserEmail] = useState(email ?? '');
    const [currentState, setCurrentState] = useState(email ?
        ForgotPasswordState.ENTER_CODE : ForgotPasswordState.SEND_CODE);

    const onSubmitCodeSuccess = useCallback((email: string) => {
        setUserEmail(email);
        setCurrentState(ForgotPasswordState.ENTER_CODE);
    }, []);

    const onBackCall = useCallback(() => {
        setCurrentState(ForgotPasswordState.SEND_CODE);
    }, []);

    const childComponent: ReactNode = useMemo(() => {
        switch (currentState) {
            case ForgotPasswordState.SEND_CODE:
                return <CognitoForgotPasswordSendCode
                    onSubmitCodeSuccess={onSubmitCodeSuccess}
                    onSetCognitoLoginResponseCode={onSetCognitoLoginResponseCode}
                    logEvent={logEvent}/>
            case ForgotPasswordState.ENTER_CODE:
                return <CognitoForgotPasswordEnterCode
                    userEmail={userEmail}
                    onBackCall={onBackCall}
                    onSetCognitoLoginResponseCode={onSetCognitoLoginResponseCode}
                    logEvent={logEvent}/>
        }
        return undefined;
    }, [currentState, onBackCall, onSetCognitoLoginResponseCode, onSubmitCodeSuccess, userEmail, logEvent]);

    return (
        <>
            {childComponent}
        </>
    );
}
