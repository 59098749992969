import { ApolloError } from "@apollo/client"

// Returns [error message, error code]
export const parseError = (e: any): [string, string] | [undefined, undefined] => {
    console.warn(e);
    const error = e as ApolloError;
    if (error && error.graphQLErrors) {
        const gqlError = error.graphQLErrors[0];
        if (gqlError && gqlError.extensions) {
            return [
                gqlError.extensions.error_code,
                gqlError.extensions.message
            ];
        }
    }

    return [undefined, undefined];
}