import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import App from './App';
import * as serviceWorker from './serviceWorker';

Modal.setAppElement('#root');

ReactDOM.render(<App />, document.getElementById('root'));

// The checkout.com Frames SDK is hosted on their CDN, not through NPM
// TODO: Once payment forms are functional (released in prod), add this include in index.html instead of here
const checkout = document.createElement('script');
checkout.setAttribute('src', 'https://cdn.checkout.com/js/framesv2.min.js');
document.body.appendChild(checkout);
// This will set `Frames` on the window

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
