import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_DIRECT_PAYMENT_REQUESTS } from "../../graphql/queries/payments";
import { DirectPaymentRequestConnectionEdge } from "../../graphql/schema/payments";
import Panel from "../../core/panels/Panel";
import LoadingRow from "../../core/table/LoadingRow";
import DirectPaymentRequestRow from "./DirectPaymentRequestRow";
import './admindashboard.scss';

const AdminDashboardDirectPayments = () => {
    const { data, loading, refetch } = useQuery(GET_DIRECT_PAYMENT_REQUESTS);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <div className='admin-dashboard__invoices'>
            <Panel position='left' className='admin-dashboard__invoices--panel'>
                <div className='admin-dashboard__invoices--table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Request Id</th>
                                <td>Request Status</td>
                                <td>Amount</td>
                                <td>Decision</td>
                                <td>Account</td>
                                <td>Account Type</td>
                                <td>Transaction Id</td>
                                <td>Amount Received</td>
                                <td>Time Received</td>
                                <td>User Id</td>
                                <td>User E-mail</td>
                            </tr>
                        </thead>
                        <tbody>
                        {data?.directPaymentRequests?.edges?.length ? data.directPaymentRequests.edges.map((request: DirectPaymentRequestConnectionEdge, i: number) =>
                            <DirectPaymentRequestRow key={i} directPaymentRequest={request.node} refetch={refetch} />
                        ) : <tr><td>Unable to find any direct payment requests</td></tr>}
                        {loading && <LoadingRow columnCount={11} />}
                        </tbody>
                    </table>
                </div>
            </Panel>
        </div>
    );
}

export default AdminDashboardDirectPayments;
