import gql from "graphql-tag";
import { USER_DATA } from "../fragments";
import { PAYMENT_SOURCE_DATA, INVOICE_DATA, BILLING_SUBSCRIPTION_DATA, DIRECT_PAYMENT_REQUEST_DATA } from "../fragments/payments";
import { PaymentSource, Invoice, UserSubscription, BillingSubscriptionConnection, InvoiceConnection, DirectPaymentRequestConnection } from "../schema/payments";

export const GET_PAYMENT_SOURCE = gql`
query PaymentSource {
    paymentSource(type: CREDIT_CARD) {
        ...PaymentSourceData
    }
}
${PAYMENT_SOURCE_DATA}
`;
export type GetPaymentSourceData = { paymentSource?: PaymentSource };

export const GET_INVOICES = gql`
query GetInvoices {
    invoices {
        ...Invoice
    }
}
${INVOICE_DATA}
`;
export type GetInvoicesData = { invoices: Invoice[] };

export const GET_USER_SUBSCRIPTION = gql`
subscription UserSubscription($subscriptionId: ID!)  {
    userSubscription(subscriptionId: $subscriptionId) {
        subscriptionId
        status
    }
}`;

export type GetUserSubscription = { userSubscription: UserSubscription };

export const GET_MY_INVOICES = gql`
query GetMyInvoices($first: Int, $after: String, $filter: InvoiceFilter) {
    me {
        id
        invoices(first: $first, after: $after, filter: $filter) {
            edges {
                cursor
                node {
                    ...Invoice
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
}
${INVOICE_DATA}
`;

export type GetMyInvoicesData = {
    me?: {
        invoices?: InvoiceConnection;
    }
};

export const GET_MY_SUBSCRIPTIONS = gql`
query GetMySubscriptions($first: Int, $after: String, $filter: BillingSubscriptionFilter) {
    me {
        id
        subscriptions(first: $first, after: $after, filter: $filter) {
            edges {
                cursor
                node {
                    ...BillingSubscriptionData
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
}
${BILLING_SUBSCRIPTION_DATA}
`;

export type GetMySubscriptionsData = {
    me?: {
        subscriptions?: BillingSubscriptionConnection;
    }
};

export const GET_ALL_INVOICES = gql`
query GetAllInvoices($includeStatus: [InvoiceStatus!], $excludeStatus: [InvoiceStatus!], $associatedWith: LineItemAssociationQuery, $query: AllInvoicesQuery) {
    allInvoices(includeStatus: $includeStatus, excludeStatus: $excludeStatus, associatedWith: $associatedWith, query: $query) {
        ...Invoice
    }
}
${INVOICE_DATA}
`;

export type GetAllSubscriptionsData = {
    allSubscriptions?: BillingSubscriptionConnection;
};

export const GET_ALL_SUBSCRIPTIONS = gql`
query GetAllSubscriptions($first: Int, $after: String, $query: AllSubscriptionsQuery) {
    allSubscriptions(first: $first, after: $after, query: $query) {
        edges {
            cursor
            node {
                ...BillingSubscriptionData
            }
        }
        pageInfo {
            hasNextPage
        }
    }
}
${BILLING_SUBSCRIPTION_DATA}
`;

export type GetDirectPaymentRequestsData = {
    directPaymentRequests?: DirectPaymentRequestConnection;
}

export const GET_DIRECT_PAYMENT_REQUESTS = gql`
query DirectPaymentRequests($first: Int, $after: String, $filter: DirectPaymentRequestFilter) {
    directPaymentRequests(first: $first, after: $after, filter: $filter) {
        edges {
            cursor
            node {
                ...DirectPaymentRequest
            }
        }
        pageInfo {
            hasNextPage
        }
    }
}
${DIRECT_PAYMENT_REQUEST_DATA}
`;

export const BILLING_PAYMENT_PROVIDER_ALLOW_LIST = gql`
query BillingPaymentProviderAllowList($first: Int, $after: String, $paymentProvider: PaymentProvider) {
    billingPaymentProviderAllowList(first: $first, after: $after, paymentProvider: $paymentProvider) {
        edges {
            cursor
            node {
                userId
                paymentProvider
                createdAt
                updatedAt
                user {
                    ...UserData
                }
            }
        }
        pageInfo {
            hasNextPage
        }
    }
}
${USER_DATA}
`;