import React, { useContext, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { DEACTIVATE_ANNOUNCEMENT } from '../../graphql/mutations';
import PrimaryNavigation from './PrimaryNavigation';
import { logout } from '../../helpers/session';
import { History } from 'history';
import Modal from '../../core/modal/Modal';
import imgCloseModal from "../../images/closemodal.svg";
import LinkButton from '../../core/forms/LinkButton';
import { useBoolean } from '../../helpers/hooks';
import { UserContext } from '../../contexts/UserContext';
import CreateAnnouncement from '../CreateAnnouncement';
import AdminStats from "../adminstats/AdminStats";
import { AmplitudeContext } from "../../contexts/AmplitudeContext";
import { AnnouncementContext } from '../../contexts/AnnouncementContext';
import { signOut } from "../../helpers/cognitoUtils";

interface MainNavigationProps {
    currentPath: string
    history: History
}

const MainNavigation = ({ history, currentPath }: MainNavigationProps) => {

    const [isAnnouncementModalOpen, openAnnouncementModal, closeAnnouncementModal] = useBoolean(false);
    const [isAdminStatsModalOpen, openAdminStatsModal, closeAdminStatsModal] = useBoolean(false);

    const { user, isGlobalAdmin } = useContext(UserContext)!;
    const { logEvent } = useContext(AmplitudeContext)!;
    const { isActive: isAnnouncementActive } = useContext(AnnouncementContext)!;

    const [deactivateAnnouncement] = useMutation(DEACTIVATE_ANNOUNCEMENT);

    const settings = useMemo(() => {
        const actions = isGlobalAdmin ? [
            {
                action: isAnnouncementActive ? deactivateAnnouncement : openAnnouncementModal,
                title: isAnnouncementActive ? 'Deactivate Announcement' : 'Announcement'
            },
            { action: openAdminStatsModal, title: 'Stats' }
        ] : [];

        return [
            ...actions, {
                action: () => {
                    (process.env.REACT_APP_USE_COGNITO?.toLowerCase() === 'true'
                        ? signOut()
                        : logout()).catch(()=>{}).then(async () => {
                    logEvent('LogOut');
                    window.location.href = '/login';
                }) },
                title: 'Log Out',
            }];
    }, [
        isGlobalAdmin, openAnnouncementModal, openAdminStatsModal,
        logEvent, deactivateAnnouncement, isAnnouncementActive
    ]);

    return (
        <>
            <PrimaryNavigation
                firstName={user ? user.firstName : 'Loading...'}
                settings={settings}
            />
            <Modal
              isOpen={isAnnouncementModalOpen}
              onRequestClose={closeAnnouncementModal}
              label={'Create Announcement'}
            >
                <LinkButton className='modal__close-btn' onClick={closeAnnouncementModal}>
                    <img src={imgCloseModal} alt='Close' />
                </LinkButton>
                <CreateAnnouncement onSuccess={closeAnnouncementModal} />
            </Modal>
            <Modal
                isOpen={isAdminStatsModalOpen}
                onRequestClose={closeAdminStatsModal}
                label={'System Stats'}
            >
                <LinkButton className='modal__close-btn' onClick={closeAdminStatsModal}>
                    <img src={imgCloseModal} alt='Close' />
                </LinkButton>
                <AdminStats />
            </Modal>
        </>
    );
};

export default MainNavigation;
