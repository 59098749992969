import { CognitoUser } from "amazon-cognito-identity-js";
import React, { useMemo, useState } from "react";
import { CognitoLoginState, MFAType, setPreferredMFA, setupTOTP, verifyTotpToken } from "../../../helpers/cognitoUtils";
import { Formik } from "formik";
import * as yup from "yup";
import classnames from "classnames";
import Spinner from "../../../core/spinner/Spinner";
import Lock from "../../../core/lock/Lock";
import Form from "../../../core/forms/Form";
import QRCode from "qrcode.react";
import LoginFormField from "../../../core/forms/fields/LoginFormField";
import { CognitoComponentProps } from "../CognitoUserManager";

export interface CognitoSetupMFAProps extends CognitoComponentProps {
    user: CognitoUser | undefined
}
export const CognitoSetupMFA: React.FunctionComponent<CognitoSetupMFAProps> = (
    {user, onSetCognitoLoginResponseCode}
) => {

    const [qrcode, setQRCode] = useState('');

    useMemo(()=> {
        setupTOTP(user).then((code) => {
            user?.getUserAttributes((err, attr) => {
                const email = attr?.find(it => it.getName() === 'email')?.getValue();
                setQRCode(`otpauth://totp/Tuned:${email}?secret=${code}&issuer=Tuned`);
            });
        });
    }, [user]);

    return (
        <div className='login-page__wrapper'>
            <Formik
                initialValues={{
                    code: ''
                }}
                validationSchema={yup.object().shape({
                    code: yup.string().required('Code is a required field.')
                })}
                onSubmit={({ code }, actions) => {
                    actions.setSubmitting(true);
                    actions.setStatus(undefined);
                    actions.setTouched({ code: true });

                    verifyTotpToken(user, code.trim())
                        .then(() => {
                            setPreferredMFA(user, MFAType.TOTP)
                                .then((result) => {
                                    onSetCognitoLoginResponseCode(CognitoLoginState.SUCCESS);
                                })
                                .catch((err) => {
                                        onSetCognitoLoginResponseCode(CognitoLoginState.MFA_NEEDS_SETUP);
                                    }
                                )
                        })
                        .catch((err) => {
                                actions.setSubmitting(false);
                                actions.setStatus({
                                    login: 'Invalid code'
                                });
                            }
                        );
                }}
                render={({ submitForm, values, status, isSubmitting, errors, touched }) => {
                    if (isSubmitting) return (
                        <Spinner />
                    );

                    return (
                            <Form onSubmit={submitForm}>
                                <div className='login-page__wrapper__sub'>
                                    <div className="login-page__wrapper__title">Enter the six digit two-factor authentication code sent to your device</div>
                                    <Lock/>
                                </div>
                                {status && status.login && <span className='error'>{status.login}</span>}
                                {qrcode === '' ? undefined : <div className='qr-code'><QRCode value={qrcode}/></div>}
                                <LoginFormField label='Authorization Code' name='code' value={values.code}
                                                error={touched?.code ? errors?.code : undefined} className='only-field' />
                                <button className={classnames('button button--primary', { 'button--far-gap': errors?.code })}>Setup</button>
                            </Form>
                    );
                }}
            />
        </div>
    );
}