import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import HomePage from './pages/homepage/HomePage';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/loginpage/LoginPage';
import SplashPage from './pages/splashpage/SplashPage';
import CognitoUserManager from './pages/cognitoUserManagement/CognitoUserManager';

import './styles/base.scss';
import './core/forms/form.scss';
import './core/modal/modal.scss';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { UserContext, UserContextProvider } from './contexts/UserContext';
import TunedApolloProvider from "./contexts/TunedApolloProvider";
import {ModalProvider} from "react-modal-hook/dist";
import { NavigationContextProvider } from "./contexts/NavigationContext";
import { ExchangeContextProvider } from "./contexts/ExchangeContext";
import { AccountSettingsContextProvider } from "./contexts/AccountSettingsContext";
import { AlertContextProvider } from "./contexts/AlertContext";
import { AnnouncementContextProvider } from './contexts/AnnouncementContext';

import Amplify from 'aws-amplify';
import { isDevelopment } from './helpers/environment';
import { AmplitudeContextProvider } from "./contexts/AmplitudeContext";
import { clearAllCookies } from './helpers/cognitoUtils';

if(process.env.REACT_APP_USE_COGNITO?.toLowerCase() === 'true') {
    Amplify.configure({
        Auth: {

            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_COGNITO_REGION,

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_USER_POOL_ID,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

            cookieStorage: {
                domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
                path: process.env.REACT_APP_COOKIE_STORAGE_PATH,
                secure: process.env.REACT_APP_COOKIE_STORAGE_SECURE?.toLowerCase() === 'true'
            }

        }
    });
}

const Preloader: React.FunctionComponent<{}> = ({ children }) => {
    const { isLoading, user, isGlobalAdmin } = useContext(UserContext)!;
    const [checkoutLoading, setCheckoutLoading] = useState<boolean>(true);
    const [pineModuleLoading, setPineModuleLoading] = useState<boolean>(true);

    useEffect(() => {
        // Add a 10-sec timeout so we don't hang forever if it fails
        const errorTimeout = setTimeout(() => {
            if (isDevelopment) throw Error("Unable to load pine module");
        }, 10000);

        function checkPineLoaded() {
            if (pineModuleLoading && (window as any).analyze_pine_script !== undefined) {
                clearTimeout(errorTimeout);
                setPineModuleLoading(false);
            } else {
                setTimeout(checkPineLoaded, 100);
            }
        }

        checkPineLoaded();
    }, /*eslint-disable react-hooks/exhaustive-deps*/ []); // We explicitly want this to run once

    // Ensure the Checkout.com SDK has loaded before completing the preloader
    useEffect(() => {
        // Add a 10-sec timeout so we don't hang forever if it fails
        const errorTimeout = setTimeout(() => {
            if (isDevelopment) throw Error("Unable to load third-party dependencies");
        }, 10000);

        function checkSDKsLoaded() {
            if (checkoutLoading && (window as any).Frames !== undefined) {
                console.log("Checkout loaded!");
                clearTimeout(errorTimeout);
                setCheckoutLoading(false);
            } else {
                setTimeout(checkSDKsLoaded, 100);
            }
        }

        // TODO: Remove this condition when payments is ready for prod
        checkSDKsLoaded();
    }, /*eslint-disable react-hooks/exhaustive-deps*/ []); // We explicitly want this to run once

    return (
        <Router>
            <Route path='/' render={() => {
                if (user && user?.tier !== 'ADMIN') {
                    clearAllCookies();
                    window.location.href = '/login';
                }
                
                if (isLoading || (!isGlobalAdmin && user)) return <SplashPage />;

                if (window.location.pathname === '/login') {
                    return children;
                }

                if (!user || user?.tier !== 'ADMIN') {
                    window.location.href = '/login';

                    return <SplashPage />;
                }

                return children;
            }} />
        </Router>
    );
};

const BaseProviders = ({ children }: { children: React.ReactNode }) => (
    <TunedApolloProvider>
        <ModalProvider>
            <UserContextProvider>
                <AmplitudeContextProvider>
                    <NavigationContextProvider>
                        <ExchangeContextProvider>
                            <AccountSettingsContextProvider>
                                <AlertContextProvider>
                                    <AnnouncementContextProvider>
                                        { children }
                                    </AnnouncementContextProvider>
                                </AlertContextProvider>
                            </AccountSettingsContextProvider>
                        </ExchangeContextProvider>
                    </NavigationContextProvider>
                </AmplitudeContextProvider>
            </UserContextProvider>
        </ModalProvider>
    </TunedApolloProvider>
);

const App = () => (
    <BaseProviders>
        <Preloader>
            <Switch>
                <Route path='/resetpassword' component={ResetPasswordPage} />
                <Route path='/login' exact component={
                process.env.REACT_APP_USE_COGNITO?.toLowerCase() === 'true'? CognitoUserManager : LoginPage
                } />
                <Route path='/register' component={
                process.env.REACT_APP_USE_COGNITO?.toLowerCase() === 'true'? CognitoUserManager : LoginPage
                } />
                <Route path='/(invoices|subscriptions|directpaymentrequests|directpaymentsallowlist|failedexecutions|stuckexecutions|user|bot)' component={HomePage} />
                <Route path='/404' render={props => <ErrorPage heading='Not Found' message='Please check your URL and try again.' history={props.history} />} />
                <Redirect from='/' exact to='/invoices' />
                <Redirect to='/404' />
            </Switch>
        </Preloader>
    </BaseProviders>
);

export default App;
