import LinkButton from "../forms/LinkButton";
import Modal from "./Modal";
import React, {useState} from "react";
import {useBoolean} from "../../helpers/hooks";
import Spinner from "../spinner/Spinner";
import { parseError } from "../../helpers/errorUtils";

export type OnConfirmFn = () => Promise<any> | void;

interface Props {
    onConfirm?: OnConfirmFn;
    text: string;
    primaryAction: string;
    isOpen: boolean;
    onClose: () => void;
}

const ConfirmationModal = ({onConfirm, text, primaryAction, isOpen, onClose}: Props) => {
    const [isLoading, startLoading, stopLoading] = useBoolean(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const modalBody = isLoading
        ? <Spinner/>
        : <>
            <span className='error'>{error}</span>
            <span className='label'>{text}</span>
            <button disabled={isLoading} className='button button--primary'>
                {primaryAction}
                {isLoading && <Spinner/>}
            </button>
            <LinkButton className='button button--secondary' onClick={onClose}>Cancel</LinkButton>
          </>;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
        >
            <form
                className='form form--dark'
                onSubmit={(e) => {
                    e.preventDefault();
                    startLoading();
                    const confirmed = onConfirm && onConfirm();
                    if (confirmed) {
                        confirmed.then(
                            () => { onClose(); stopLoading(); setError(undefined)},
                            (err: Error) => {
                                stopLoading();
                                const [, message] = parseError(err)
                                setError(message || err.message);
                            }
                        );
                    } else {
                        stopLoading();
                        onClose();
                        setError(undefined);
                    }
                }}
            >
                {modalBody}
            </form>
        </Modal>
    );

};

export default ConfirmationModal;