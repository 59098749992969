import React from 'react';

interface Props {
    columnCount: number;
}

const LoadingRow = ({ columnCount }: Props) => {

    const columns = [];
    for (let i = 0; i < columnCount; i++)
        columns.push(<td key={i}>Loading</td>);

    return <tr className='fetching'>{columns}</tr>
};

export default LoadingRow;